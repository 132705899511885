import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AppliedBack } from "../../../../components/icon";
import {
  createJob,
  fetchAllJobsId,
  fetchJobsMe,
  updateJobById,
} from "../../../../store/jobsApi/jobsThunks";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputMask from "react-input-mask";
import { ButtonSpinner } from "../../../../components/buttonSpinner/ButtonSpinner";
import { uzbekistanRegions } from "../../../../constants/Region";
import { useTranslation } from "react-i18next";
import { workTypeStrList } from "../../../../constants/WorkType";
import { educationStrList } from "../../../../constants/EducationList";
import { experienceStrList } from "../../../../constants/ExperienceList";

export const StandartForm = () => {
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.jobs);
  const { id } = useParams();
  const [formData, setFormData] = useState();
  const { jobs } = useSelector((state) => state.jobs);
  const [jobStatus, setJobStatus] = useState(true);
  const [benefitsModalOpen, setBenefitsModalOpen] = useState(false);
  const [salaryModalOpen, setSalaryModalOpen] = useState(false);
  const [languagesModalOpen, setLanguagesModalOpen] = useState(false);
  const [selectedBenefits, setSelectedBenefits] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedSalary, setSelectedSalary] = useState("Kelishiladi");

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchAllJobsId(id));
    if (id) {
      setSelectedLanguages(jobs?.languagesRequired || []);
      setSelectedSalary(jobs?.salaryRange || []);
      setSelectedBenefits(jobs?.benefits || []);
      setFormData({
        jobTitle: jobs?.jobTitle || "",
        description: jobs?.description || "",
        phoneNumber: `+998${jobs?.phoneNumber || ""}`,
        location: jobs?.location || "",
        callOnly: jobs?.callOnly || false,
        workingtype: jobs?.workingtype || "",
        educationLevel: jobs?.educationLevel || "",
        experience: jobs?.experience || "",
        requirements: jobs?.requirements || "",
      });
    } else {
      setFormData({
        jobTitle: "",
        description: "",
        phoneNumber: "",
        location: "",
        callOnly: false,
        workingtype: "",
        educationLevel: "",
        experience: "",
        requirements: "",
      });
    }
  }, [dispatch, id]);

  const [workingHours, setWorkingHours] = useState({
    start: new Date(),
    end: new Date(),
  });

  const benefits = [
    "Medical / Health Insurance",
    "Profit-Sharing bonus",
    "Performance Bonus",
    "Paid Sick Leave",
    "Paid Vacation Leave",
    "Transportation Allowances",
  ];
  const languages = [
    "Uzbek",
    "Russian",
    "English(US)",
    "Turkish",
    "Kazakh",
    "Tadjik",
    "French",
    "Spanish",
    "German",
    "Indian",
    "Arabic",
    "Korean",
    "Japanese",
    "Chinese",
    "others",
  ];
  const salaryRangesUz = [
    "any_salary",
    "salary_negotiable",
    "less_than_1_million",
    "1_to_3_million",
    "3_to_5_million",
    "5_to_7_million",
    "7_to_10_million",
    "10_to_15_million",
    "15_to_25_million",
    "more_than_25_million",
  ];

  const handleStartChange = (date) => {
    setWorkingHours((prev) => ({ ...prev, start: date }));
  };

  const handleEndChange = (date) => {
    setWorkingHours((prev) => ({ ...prev, end: date }));
  };

  const handleBenefitChange = (benefit) => {
    setSelectedBenefits((prev) =>
      prev.includes(benefit)
        ? prev.filter((b) => b !== benefit)
        : [...prev, benefit]
    );
  };

  const handleLanguageChange = (language) => {
    setSelectedLanguages((prev) =>
      prev.includes(language)
        ? prev.filter((l) => l !== language)
        : [...prev, language]
    );
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const rawPhoneNumber = formData.phoneNumber.replace(/\D/g, "").slice(3, 12);

    if (rawPhoneNumber === "") {
      toast.error("Raqamni kiriting!");
      return;
    }

    const newJob = {
      ...formData,
      phoneNumber: rawPhoneNumber,
      jobStatus: jobStatus ? "Open" : "Closed",
      benefits: selectedBenefits,
      workingHours: `${workingHours.start.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })}-${workingHours.end.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })}`,
      salaryRange: selectedSalary,
      languagesRequired: selectedLanguages,
    };

    if (id) {
      dispatch(updateJobById({ id, updatedJob: newJob }))
        .then(async (response) => {
          if (response) {
            toast.success("Muvaffaqqiyatli Yangilandi!");
            dispatch(fetchJobsMe({ page: 1, pageSize: 10 }));
            navigate("/announ");
          }
        })
        .catch((error) => {
          console.error("Error creating job: ", error);
        });
    } else {
      dispatch(createJob(newJob))
        .then((response) => {
          console.log(response);
          if (response?.meta?.requestStatus === "fulfilled") {
            toast.success("Muvaffaqqiyatli qo'shildi!");
            dispatch(fetchJobsMe({ page: 1, pageSize: 10 }));
            navigate("/announ");
          } else {
            toast.error("Nomalum xatolik yuz berdi!");
          }
        })
        .catch((error) => {
          console.error("Error creating job: ", error);
        });
    }
  };

  const { t } = useTranslation();
  return (
    <div className="mx-auto bg-white p-8 shadow-md rounded-md mb-20">
      <Link
        to={"/announ"}
        className="flex gap-4 items-center font-bold text-xl"
      >
        <AppliedBack />
        Standart e'lon
      </Link>{" "}
      <div className="mt-10">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-3 gap-5">
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Lavozimi
              </label>
              <input
                type="text"
                name="jobTitle"
                value={formData?.jobTitle}
                onChange={handleChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Telefon raqami
              </label>
              <InputMask
                mask="+\9\9\8 (99) 999-99-99"
                value={formData?.phoneNumber}
                onChange={handleChange}
              >
                {(inputProps) => (
                  <input
                    {...inputProps}
                    type="tel"
                    name="phoneNumber"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    required
                  />
                )}
              </InputMask>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Manzili
              </label>
              <select
                name="location"
                value={formData?.location}
                onChange={handleChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                required
              >
                <option disabled value="">
                  Tanlang
                </option>
                {uzbekistanRegions?.map((region) => (
                  <option key={region} value={region}>
                    {t(`${region}`)}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Ish holati
              </label>
              <button
                type="button"
                className={`mt-1 block w-full text-left pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md ${
                  jobStatus ? "bg-green-600" : "bg-gray-200"
                }`}
                onClick={() => setJobStatus(!jobStatus)}
              >
                {jobStatus ? "Ochiq" : "Yopiq"}
              </button>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Ish turi
              </label>
              <select
                required
                name="workingtype"
                value={formData?.workingtype}
                onChange={handleChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option disabled value="">
                  Tanlang
                </option>
                {workTypeStrList?.map((type) => (
                  <option key={type} value={type}>
                    {t(`${type}`)}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Talim darajasi
              </label>
              <select
                required
                name="educationLevel"
                value={formData?.educationLevel}
                onChange={handleChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option disabled value="">
                  Tanlang
                </option>
                {educationStrList?.map((edu) => (
                  <option key={edu} value={edu}>
                    {t(`${edu}`)}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Ish tajribasi
              </label>
              <select
                required
                name="experience"
                value={formData?.experience}
                onChange={handleChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option disabled value="">
                  Tanlang
                </option>
                {experienceStrList?.map((experience) => (
                  <option key={experience} value={experience}>
                    {t(`${experience}`)}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Ish soatlari
              </label>
              <div className="flex space-x-2">
                <DatePicker
                  required
                  selected={workingHours?.start}
                  onChange={handleStartChange}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Start"
                  dateFormat="h:mm aa"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                />
                <DatePicker
                  required
                  selected={workingHours?.end}
                  onChange={handleEndChange}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="End"
                  dateFormat="h:mm aa"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Maoshni tanlang
              </label>
              <button
                type="button"
                onClick={() => setSalaryModalOpen(true)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                {t(`${selectedSalary}`)}
              </button>
            </div>
            {salaryModalOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="bg-white w-[90%] p-4 rounded-lg z-10">
                  <div className="space-y-2 mt-15">
                    {salaryRangesUz?.map((option) => (
                      <button
                        key={option}
                        type="button"
                        onClick={() => {
                          setSelectedSalary(option);
                          setSalaryModalOpen(false);
                        }}
                        className="block w-full text-left pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      >
                        {t(`${option}`)}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            )}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Talab qilinadigan tillar
              </label>
              <button
                type="button"
                onClick={() => setLanguagesModalOpen(true)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                {selectedLanguages.length > 0
                  ? selectedLanguages.join(", ")
                  : "Til tanlang"}
              </button>
            </div>
            {languagesModalOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="bg-white p-4 rounded-lg z-10">
                  <div className="space-y-2 grid grid-cols-2 gap-2 mt-15">
                    {languages?.map((language) => (
                      <label
                        key={language}
                        className="flex items-center gap-3 text-left pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      >
                        <input
                          required
                          type="checkbox"
                          value={language}
                          checked={selectedLanguages?.includes(language)}
                          onChange={() => handleLanguageChange(language)}
                        />
                        <p>{t(`${language}`)}</p>
                      </label>
                    ))}
                  </div>
                  <button
                    type="button"
                    onClick={() => setLanguagesModalOpen(false)}
                    className="mt-4 w-full px-4 py-2 bg-indigo-500 text-white rounded-md"
                  >
                    Yopish
                  </button>
                </div>
              </div>
            )}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Afzalliklar
              </label>
              <button
                type="button"
                onClick={() => setBenefitsModalOpen(true)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                {selectedBenefits?.length > 0
                  ? selectedBenefits.join(", ")
                  : "Afzalliklar tanlang"}
              </button>
            </div>
            {benefitsModalOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="bg-white p-4 rounded-lg z-10">
                  <div className="space-y-2">
                    {benefits?.map((benefit) => (
                      <label
                        key={benefit}
                        className="flex items-center gap-3  text-left pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      >
                        <input
                          required
                          type="checkbox"
                          value={benefit}
                          checked={selectedBenefits?.includes(benefit)}
                          onChange={() => handleBenefitChange(benefit)}
                        />
                        <p> {t(`${benefit}`)}</p>
                      </label>
                    ))}
                  </div>
                  <button
                    type="button"
                    onClick={() => setBenefitsModalOpen(false)}
                    className="mt-4 w-full px-4 py-2 bg-indigo-500 text-white rounded-md"
                  >
                    Yopish
                  </button>
                </div>
              </div>
            )}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Talablar
              </label>
              <textarea
                required
                name="requirements"
                value={formData?.requirements}
                onChange={handleChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Ish ta'rifi
              </label>
              <textarea
                name="description"
                value={formData?.description}
                onChange={handleChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                required
              />
            </div>
          </div>
          <div className="mt-6">
            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {status === "loading" ? <ButtonSpinner /> : "Joylash"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
