import { createSlice } from "@reduxjs/toolkit";
import {
  fetchWorkExperience,
  addWorkExperience,
  updateWorkExperience,
  deleteWorkExperience,
} from "./workExperienceThunks"; // Adjust the path according to your structure

const initialState = {
  workExperience: [],
  status: "idle",
  error: null,
};

const workExperienceSlice = createSlice({
  name: "workExperience",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkExperience.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchWorkExperience.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.workExperience = action.payload.data; // Adjust based on actual response structure
      })
      .addCase(fetchWorkExperience.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addWorkExperience.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addWorkExperience.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload.data && action.payload.data.workExperience) {
          state.workExperience.push(action.payload.data.workExperience); // Adjust based on actual response structure
        } else {
          state.error = "Failed to add work experience";
        }
      })
      .addCase(addWorkExperience.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateWorkExperience.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateWorkExperience.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.workExperience.findIndex(
          (exp) => exp.id === action.payload.data.id
        );
        if (index !== -1) {
          state.workExperience[index] = action.payload.data.workExperience; // Adjust based on actual response structure
        }
      })
      .addCase(updateWorkExperience.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteWorkExperience.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteWorkExperience.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.workExperience = state.workExperience.filter(
          (exp) => exp.id !== action.payload.data.id
        ); // Adjust based on actual response structure
      })
      .addCase(deleteWorkExperience.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default workExperienceSlice.reducer;
