import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

// Fetch work experience
export const fetchWorkExperience = createAsyncThunk(
  "workExperience/fetchWorkExperience",
  async () => {
    const response = await axios.get("/users/resume/experience");
    console.log("fetchWorkExperience", response.data);
    return response.data; // Assuming the response contains { data: { workExperience: [...] } }
  }
);
// jobTitle: '555 Software Developer2222',
//   company: 'Tech Solutions Inc.',
//   startDate: '2020-01-01',
//   endDate: '2022-12-31',
//   current: false,
//   description: 'Developed and maintained web applications using JavaScript and Python.',
//   employmentType: 'Full-time',
//   location: 'New York, NY',
// Add work experience
export const addWorkExperience = createAsyncThunk(
  "workExperience/addWorkExperience",
  async (experience) => {
    console.log("addWorkExperience", experience);
    const response = await axios.post("/users/resume/experience", experience);
    return response.data; // Assuming the response contains the added work experience
  }
);

// Update work experience by id
export const updateWorkExperience = createAsyncThunk(
  "workExperience/updateWorkExperience",
  async ({ id, experience }) => {
    const response = await axios.put(`/users/resume/experience/${id}`, {
      jobTitle: experience.jobTitle,
      company: experience.company,
      startDate: experience.startDate,
      endDate: experience.endDate,
      current: experience.current,
      description: experience.description,
      employmentType: experience.employmentType,
      location: experience.location,
    });
    return response.data; // Assuming the response contains the updated work experience
  }
);

// Delete work experience by id
export const deleteWorkExperience = createAsyncThunk(
  "workExperience/deleteWorkExperience",
  async (id) => {
    const response = await axios.delete(`/users/resume/experience/${id}`);
    return response.data; // Assuming the response contains the updated work experience list
  }
);
