/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppliedBack, QuickJobsIcon } from "../../../components/icon";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentUser } from "../../../store/usersApi/usersThunks";
import {
  deleteCompanyEmployer,
  updateCompanyIntro,
} from "../../../store/companyApi/companyThunk";
import toast from "react-hot-toast";
import logo from "../../../assets/img/logo1.jpg";
import { fetchQuickJobsMe } from "../../../store/quickJobsApi/quickJobsThunks";
import { fetchJobsMe } from "../../../store/jobsApi/jobsThunks";
import { QuickJobCard } from "../../../components/cards/QuickJobCard";
import { LastJobsCard } from "../../../components/cards/LastJobsCard";
import { useTranslation } from "react-i18next";

export const Company = () => {
  const { quickJobsMe, currentPage, status } = useSelector(
    (state) => state.quickJobs
  );
  const { myJobs } = useSelector((state) => state.jobs);
  const [activeTab, setActiveTab] = useState("profile");
  const [isEditing, setIsEditing] = useState(false);
  const [newDescription, setNewDescription] = useState("");
  const [company, setCompany] = useState();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.users);
  const myId = currentUser?.data._id;
  const isAdmin = company?.workers?.filter((item) => item.userId === myId)[0]
    .isAdmin;

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser?.data.company?.description) {
      setNewDescription(currentUser.data.company.description);
    }
    setCompany(currentUser?.data.company);
  }, [currentUser]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    dispatch(
      updateCompanyIntro({
        description: newDescription,
        id: currentUser.data.company._id,
      })
    ).then((result) => {
      if (result?.payload.result === "success") {
        dispatch(fetchCurrentUser());
        toast.success("Ma'lumot yangilandi");
      }
    });
    setIsEditing(false);
  };

  useEffect(() => {
    dispatch(fetchQuickJobsMe({ page: currentPage, pageSize: 10 }));
    dispatch(fetchJobsMe({ page: currentPage, pageSize: 10 }));
  }, [dispatch, currentPage]);

  const handleDelete = (id) => {
    const employeesId = id.target.id;
    const companyId = currentUser.data.company._id;
    dispatch(
      deleteCompanyEmployer({ userId: employeesId, id: companyId })
    ).then((result) => {
      if (result?.meta.requestStatus === "fulfilled") {
        dispatch(fetchCurrentUser());

        toast.success("Assistent muvaffaqqiyatli o'chirildi");
      }
    });
  };

  const isEmpty = quickJobsMe.length === 0 && myJobs?.length === 0;
  const { t } = useTranslation();

  return (
    <div className="mb-10 hero-banner">
      <div className="flex justify-between mb-5">
        <Link
          to={"/profile"}
          className="flex gap-4 items-center font-bold text-xl"
        >
          <AppliedBack />
        </Link>
        {isAdmin ? (
          <Link
            to={"/profile/hrs"}
            href="#"
            className="text-[#3E9AAE] font-semibold cursor-pointer"
          >
            {t("Add_HR")}
          </Link>
        ) : (
          ""
        )}
      </div>

      <div>
        <div className="flex gap-4 items-center">
          <div>
            <div className="rounded-2xl w-16 h-16 overflow-hidden border-[1px] border-[solid] border-[var(--Greyscale-200,#EEE)]">
              <img src={company?.logo || logo} alt="img" />
            </div>
          </div>
          <div>
            <h3 className="text-[#000] text-[20px] font-semibold tracking-[0.2px]">
              {company?.name}
            </h3>
            <p className="text-[#878787] text-base font-semibold tracking-[0.2px]">
              {company?.size || 0} {t("Employees")}
            </p>
          </div>
        </div>
        <div className="flex justify-between">
          <p className="text-[#CBCBCB] text-base mt-2 font-semibold tracking-[0.2px]">
            {t(`${company?.location}`)}
          </p>
          {isAdmin ? (
            <Link
              to={"/profile/company/edit"}
              className="rounded-xl text-[#FFF] text-center text-base px-3 py-1 font-normal bg-[#2A5771]"
            >
              {t("Edit")}
            </Link>
          ) : (
            <button className="rounded-xl text-[#FFF] text-center text-base px-3 py-1 font-normal bg-[#2A5771]">
              {t("Follow")}
            </button>
          )}
        </div>

        <div>
          <div className="text-base font-medium text-center mt-7 text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
            <ul className="flex justify-between">
              <li className="me-2">
                <a
                  href="#"
                  onClick={() => handleTabClick("profile")}
                  className={`inline-block p-4 border-b-2 text-base rounded-t-lg tab-link ${
                    activeTab === "profile"
                      ? "tab-link-active"
                      : "tab-link-inactive"
                  }`}
                >
                  {t("About us")}
                </a>
              </li>
              <li className="me-2">
                <a
                  href="#"
                  onClick={() => handleTabClick("dashboard")}
                  className={`inline-block p-4 border-b-2 text-base  rounded-t-lg tab-link ${
                    activeTab === "dashboard"
                      ? "tab-link-active"
                      : "tab-link-inactive"
                  }`}
                >
                  {t("Offers")}
                </a>
              </li>
              <li className="me-2">
                <a
                  href="#"
                  onClick={() => handleTabClick("settings")}
                  className={`inline-block p-4 border-b-2 text-base rounded-t-lg tab-link ${
                    activeTab === "settings"
                      ? "tab-link-active"
                      : "tab-link-inactive"
                  }`}
                >
                  {t("HRs")}
                </a>
              </li>
            </ul>
          </div>

          {/* Content for each tab */}
          <div className="tab-content mt-4">
            {activeTab === "profile" && (
              <div>
                <ul className="flex flex-col gap-1">
                  <li className="flex items-center gap-2">
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 0C2.7 0 0 2.7 0 6C0 9.3 2.7 12 6 12C9.3 12 12 9.3 12 6C12 2.7 9.3 0 6 0ZM3.42 8.1L3 7.32L5.4 5.94V3H6.3V6.48L3.42 8.1Z"
                        fill="black"
                      />
                    </svg>

                    <p className="m-0 text-[#000] text-base font-medium">
                      {company?.working_time}
                    </p>
                  </li>
                  <li className="flex items-center gap-2">
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.8 0H7.2C7.51826 0 7.82348 0.133082 8.04853 0.36997C8.27357 0.606858 8.4 0.928148 8.4 1.26316V2.52632H10.8C11.1183 2.52632 11.4235 2.6594 11.6485 2.89629C11.8736 3.13317 12 3.45446 12 3.78947V10.7368C12 11.0719 11.8736 11.3931 11.6485 11.63C11.4235 11.8669 11.1183 12 10.8 12H1.2C0.534 12 0 11.4316 0 10.7368V3.78947C0 3.08842 0.534 2.52632 1.2 2.52632H3.6V1.26316C3.6 0.562105 4.134 0 4.8 0ZM7.2 2.52632V1.26316H4.8V2.52632H7.2Z"
                        fill="black"
                      />
                    </svg>

                    <p className="m-0 text-[#000] text-base font-medium">
                      {company?.working_days}
                    </p>
                  </li>
                  <li className="flex items-center gap-2">
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.2743 12L10.8571 11.5829H1.08V10.44H9.71429L6.73143 7.45714L6.79429 7.58286H2.79429L3.93714 5.29714H4.57143L4 4.72571H3.93714C3.30857 4.72571 2.79429 4.21143 2.79429 3.58286V3.52L0 0.725714L0.731429 0L12 11.2743L11.2743 12ZM6.79429 3.58286C7.21714 3.58286 7.58286 3.35429 7.77714 3.01143H10.2229V8.04L11.3657 9.18286V3.01143C11.3657 2.37714 10.8571 1.86857 10.2229 1.86857H7.77714C7.58286 1.53143 7.21714 1.29714 6.79429 1.29714C6.79429 0.668571 6.28 0.154286 5.65143 0.154286H3.93714C3.50286 0.154286 3.13143 0.4 2.93714 0.754286L6.50857 4.32571C6.68571 4.12571 6.79429 3.86857 6.79429 3.58286Z"
                        fill="black"
                      />
                    </svg>

                    <p className="m-0 text-[#000] text-base font-medium">
                      {t(`${company?.overtime}`)}
                    </p>
                  </li>
                </ul>
                <div>
                  <div className="flex items-center justify-between mt-5">
                    <p className="text-[#000] m-0 text-[19px] font-semibold tracking-[0.2px] ">
                      {t("Company_Introduction")}
                    </p>
                    {isEditing ? (
                      <button onClick={handleSaveClick}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#000"
                          strokeWidth="2.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
                          <polyline points="17 21 17 13 7 13 7 21"></polyline>
                          <polyline points="7 3 7 8 15 8"></polyline>
                        </svg>
                      </button>
                    ) : (
                      <button onClick={handleEditClick}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="22"
                          height="22"
                          viewBox="0 0 30 30"
                        >
                          <path d="M 22.828125 3 C 22.316375 3 21.804562 3.1954375 21.414062 3.5859375 L 19 6 L 24 11 L 26.414062 8.5859375 C 27.195062 7.8049375 27.195062 6.5388125 26.414062 5.7578125 L 24.242188 3.5859375 C 23.851688 3.1954375 23.339875 3 22.828125 3 z M 17 8 L 5.2597656 19.740234 C 5.2597656 19.740234 6.1775313 19.658 6.5195312 20 C 6.8615312 20.342 6.58 22.58 7 23 C 7.42 23.42 9.6438906 23.124359 9.9628906 23.443359 C 10.281891 23.762359 10.259766 24.740234 10.259766 24.740234 L 22 13 L 17 8 z M 4 23 L 3.0566406 25.671875 A 1 1 0 0 0 3 26 A 1 1 0 0 0 4 27 A 1 1 0 0 0 4.328125 26.943359 A 1 1 0 0 0 4.3378906 26.939453 L 4.3632812 26.931641 A 1 1 0 0 0 4.3691406 26.927734 L 7 26 L 5.5 24.5 L 4 23 z"></path>
                        </svg>
                      </button>
                    )}
                  </div>

                  <div className="mt-2">
                    {isEditing ? (
                      <textarea
                        value={newDescription}
                        onChange={(e) => setNewDescription(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                      />
                    ) : (
                      <p className="m-0 text-[#000] text-base font-medium">
                        {company?.description}
                      </p>
                    )}
                  </div>
                </div>
                <ul className="flex flex-col gap-1">
                  <p className="text-[#000] mb-2 text-[19px] font-semibold tracking-[0.2px] mt-5">
                    {t("Business_Information")}
                  </p>
                  <li className="flex items-center gap-2">
                    <p className="m-0 text-[#000] text-base font-medium">
                      {t("Company name")}:
                    </p>
                    <p className="m-0 text-[#000] text-base font-medium">
                      {company?.name}
                    </p>
                  </li>
                  <li className="flex items-center gap-2">
                    <p className="m-0 text-[#000] text-base font-medium">
                      {t("Legal_Representative")}:
                    </p>
                    <p className="m-0 text-[#000] text-base font-medium">
                      {company?.info.legal_representative}
                    </p>
                  </li>
                  <li className="flex items-center gap-2">
                    <p className="m-0 text-[#000] text-base font-medium">
                      {t("Registration_Capital")}:
                    </p>
                    <p className="m-0 text-[#000] text-base font-medium">
                      {company?.info.registration_capital}
                    </p>
                  </li>
                  <li className="flex items-center gap-2">
                    <p className="m-0 text-[#000] text-base font-medium">
                      {t("Date_of_Establishment")}:
                    </p>
                    <p className="m-0 text-[#000] text-base font-medium">
                      {company?.info.date_of_establishment}
                    </p>
                  </li>
                </ul>
              </div>
            )}
            {activeTab === "dashboard" && (
              <div>
                {isEmpty ? (
                  <p className="flex justify-center font-bold mt-10">
                    {t("Not_Available")}
                  </p>
                ) : (
                  <div>
                    <div className="mt-10">
                      <QuickJobCard status={status} quickJobs={quickJobsMe} />
                    </div>

                    <div className="mt-10">
                      <LastJobsCard jobs={myJobs} status={status} />
                    </div>
                  </div>
                )}
              </div>
            )}
            {activeTab === "settings" && (
              <div>
                <ul className="flex flex-col gap-5">
                  {company?.workers?.map((item) => (
                    <li key={item.userId}>
                      <div className="flex justify-between items-center gap-3">
                        <div className="flex items-center gap-3">
                          <img
                            className="w-10 h-10 rounded-full"
                            alt={item.fullName}
                            src={item.avatar}
                          />
                          <p className="m-0 text-[#000] text-base font-semibold">
                            {item.fullName} -{" "}
                            {item.isAdmin
                              ? t("HR_Company")
                              : t("HR_Assistant")}
                          </p>
                        </div>

                        {isAdmin ? (
                          !item.isAdmin ? (
                            <button
                              id={item.userId}
                              onClick={(id) => handleDelete(id)}
                              className="m-0 bg-red-700 px-4 py-2 rounded-full text-white text-base font-medium"
                            >
                              O'chirish
                            </button>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
