import { createSlice } from "@reduxjs/toolkit";
import {
  getTelegramChannels,
  leaveChannel,
  removeTelegramId,
  updateChannel,
  addOrUpdateTelegramData,
  deleteTelegramData,
  uploadTelegramImages,
  deleteTelegramImages,
  deleteSingleTelegramImage,
  changeSelectedImage,
  changeSelectedPost,
} from "./telegramThunks"; // Adjust the path according to your structure

const initialState = {
  telegramApi: [],
  status: "idle",
  uploadStatus: "idle",
  error: null,
};

const telegramSlice = createSlice({
  name: "telegramApi",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTelegramChannels.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTelegramChannels.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.telegramApi = action.payload;
      })
      .addCase(getTelegramChannels.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(removeTelegramId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeTelegramId.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.telegramApi = action.payload;
      })
      .addCase(removeTelegramId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(leaveChannel.pending, (state) => {
        state.status = "loading";
      })
      .addCase(leaveChannel.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.telegramApi = action.payload;
      })
      .addCase(leaveChannel.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateChannel.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateChannel.fulfilled, (state, action) => {
        const index = state.telegramApi.findIndex((channel) => channel.id === action.payload.id);
        if (index !== -1) {
          state.telegramApi[index] = action.payload;
        }
        state.status = "succeeded";
      })
      .addCase(updateChannel.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addOrUpdateTelegramData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addOrUpdateTelegramData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.telegramApi = action.payload;
      })
      .addCase(addOrUpdateTelegramData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteTelegramData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteTelegramData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.telegramApi = action.payload;
      })
      .addCase(deleteTelegramData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(uploadTelegramImages.pending, (state) => {
        state.uploadStatus = "loading";
      })
      .addCase(uploadTelegramImages.fulfilled, (state, action) => {
        state.uploadStatus = "succeeded";
        state.telegramApi = action.payload;
      })
      .addCase(uploadTelegramImages.rejected, (state, action) => {
        state.uploadStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteTelegramImages.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteTelegramImages.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.telegramApi = action.payload;
      })
      .addCase(deleteTelegramImages.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteSingleTelegramImage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteSingleTelegramImage.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.telegramApi = action.payload;
      })
      .addCase(deleteSingleTelegramImage.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(changeSelectedImage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(changeSelectedImage.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.telegramApi = action.payload;
      })
      .addCase(changeSelectedImage.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(changeSelectedPost.pending, (state) => {
        state.status = "loading";
      })
      .addCase(changeSelectedPost.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.telegramApi = action.payload;
      })
      .addCase(changeSelectedPost.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default telegramSlice.reducer;
