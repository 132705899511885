// hooks/useStatistics.js
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchJobSeekers,
  fetchEmployers,
  fetchJobs,
  fetchCompany,
  fetchApplicants,
} from "../store/staticsData/statisticThunk"; // Adjust the path according to your structure

const useStatistics = () => {
  const dispatch = useDispatch();
  const { jobSeekers, employers, jobs, status, error, company, applicants } = useSelector(
    (state) => state.statistics
  );

  useEffect(() => {
    dispatch(fetchJobSeekers());
    dispatch(fetchEmployers());
    dispatch(fetchJobs());
    dispatch(fetchCompany())
    dispatch(fetchApplicants())
  }, [dispatch]);

  return {
    jobSeekers,
    employers,
    jobs,
    status,
    company,
    error,
    applicants
  };
};

export default useStatistics;
