// store/dataSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  // fetchUsers,
  fetchJobSeekers,
  fetchEmployers,
  fetchJobs,
  fetchCompany,
  fetchApplicants,
  // fetchQuickJobs,
  // fetchCompanies,
} from "./statisticThunk"; // Adjust the path according to your structure

const initialState = {
  // users: [],
  jobSeekers: [],
  employers: [],
  jobs: [],
  company: [],
  applicants: [],
  // companies: [],
  status: "idle",
  error: null,
};

const statisticSlice = createSlice({
  name: "data",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompany.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCompany.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.company = action.payload; // Ensure this matches the API response
        state.totalPages = action.payload.totalPages; // Ensure this matches the API response
        state.currentPage = action.payload.currentPage; // Ensure this matches the API response
      })
      .addCase(fetchCompany.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Job Seekers
      .addCase(fetchJobSeekers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchJobSeekers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.jobSeekers = action.payload;
      })
      .addCase(fetchJobSeekers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Employers
      .addCase(fetchEmployers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchEmployers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.employers = action.payload;
      })
      .addCase(fetchEmployers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
       // Applicats
       .addCase(fetchApplicants.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchApplicants.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.applicants = action.payload;
      })
      .addCase(fetchApplicants.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Jobs
      .addCase(fetchJobs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.jobs = action.payload;
      })
      .addCase(fetchJobs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default statisticSlice.reducer;
