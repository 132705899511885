import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentUser } from "../../../store/usersApi/usersThunks";
import { BASE_URL_DOMAIN } from "../../../api/api";
import { io } from "socket.io-client";
import {
  leaveChannel,
  removeTelegramId,
  updateChannel,
  addOrUpdateTelegramData,
  deleteTelegramData,
  uploadTelegramImages,
  deleteSingleTelegramImage,
  changeSelectedImage,
  changeSelectedPost,
  getTelegramChannels,
} from "../../../store/telegramApi/telegramThunks";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import {
  AppliedBack,
  FileUpload,
  TelegramIcon,
} from "../../../components/icon";
import InputMask from "react-input-mask";
import isImage from "../../../assets/img/rasmli.png";
import noImage from "../../../assets/img/rasmsiz.png";
import { ButtonSpinner } from "../../../components/buttonSpinner/ButtonSpinner";

const TelegramManager = () => {
  const dispatch = useDispatch();
  const [channels, setChannels] = useState(null);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(0);
  const [selectedPost, setSelectedPost] = useState(0);
  const { currentUser, status, changeRole } = useSelector(
    (state) => state.users
  );
  const { telegramApi, uploadStatus } = useSelector(
    (state) => state.telegramApi
  );
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState("");

  const [formData, setFormData] = useState({
    postNumber: "",
    contactNumber: "+998",
    companyName: "",
    telegram: "",
    link: "",
    additionalInfo: "",
  });

  useEffect(() => {
    dispatch(fetchCurrentUser());
    dispatch(getTelegramChannels());
  }, [changeRole]);

  console.log("telegramApi", uploadStatus);
  const user = currentUser?.data;

  useEffect(() => {
    setChannels(telegramApi?.data || []);
    setImages(user?.telegram.post.images || []);
    setSelectedImage(user?.telegram.post.selectedImage || 0);
    setSelectedPost(user?.telegram.post.selectedPost || 0);

    setFormData({
      postNumber: user?.telegram.postNumber || "",
      contactNumber: `+998${user?.telegram.contactNumber}` || "+998",
      companyName: user?.telegram.companyName || "",
      telegram: user?.telegram.telegram || "",
      link: user?.telegram.link || "",
      additionalInfo: user?.telegram.additionalInfo || "",
    });
  }, [user, telegramApi]);

  useEffect(() => {
    const socket = io(BASE_URL_DOMAIN, { transports: ["websocket"] });

    socket.on("telegramChannelAdded", (chatDetails) => {
      setChannels((prevChannels) => [...prevChannels, chatDetails]);
    });

    socket.on("telegramChannelRemoved", (chatId) => {
      setChannels((prevChannels) =>
        prevChannels.filter((channel) => channel.id !== chatId.toString())
      );
    });

    socket.on("telegramIdAdded", () => {
      dispatch(fetchCurrentUser());

    });

    socket.on("telegramIdRemoved", () => {
      dispatch(fetchCurrentUser());
    });

    return () => {
      socket.disconnect();
    };
  }, [dispatch]);

  const handleRemoveTelegramId = () => {
    dispatch(removeTelegramId())
      .then((res) => {
        if (res.payload?.result) {
          toast.success("Telegram ID removed");
          dispatch(fetchCurrentUser());
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  const handleLeaveChannel = (chatId) => {
    dispatch(leaveChannel(chatId))
      .then((res) => {
        if (res.payload?.result) {
          toast.success("Channel left");
          dispatch(fetchCurrentUser());
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  const handleUpdateChannel = (id, channel) => {
    dispatch(updateChannel({ id, channel }))
      .then((res) => {
        if (res.payload) {
          toast.success("Channel updated");
          dispatch(fetchCurrentUser());
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  const [uploadImages, setUploadImages] = useState(null);

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      setUploadImages(files);
      setFileName(files[0].name); // Fayl nomini saqlash
    }
  };

  const handleImageUpload = () => {
    if (!uploadImages) {
      toast.error("Please select images to upload");
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < uploadImages.length; i++) {
      formData.append("postImages", uploadImages[i]);
    }

    dispatch(uploadTelegramImages(formData))
      .then((res) => {
        if (res.payload) {
          toast.success("Images uploaded");
          dispatch(fetchCurrentUser());
          setUploadImages(null);
          setFileName("");
          fileInputRef.current.value = null;
        }
      })
      .catch((err) => {
        console.error("Error:", err);
        toast.error("Image upload failed");
      });
  };

  const handleDeleteImage = (image) => {
    dispatch(deleteSingleTelegramImage(image))
      .then((res) => {
        if (res.payload) {
          toast.success("Image deleted");
          dispatch(fetchCurrentUser());
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  const ChangeSelectedImage = (index) => {
    dispatch(changeSelectedImage(index))
      .then((res) => {
        if (res.payload) {
          toast.success("Image selected");
          dispatch(fetchCurrentUser());
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  const ChangeSelectedPost = (index) => {
    dispatch(changeSelectedPost(index))
      .then((res) => {
        if (res.payload) {
          toast.success("Post selected");
          dispatch(fetchCurrentUser());
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  const phoneNumber = user?.phoneNumber?.slice(4);

  return (
    <div className="space-y-6 mx-auto mb-10">
      <div className="flex items-center justify-between mb-5">
        <Link
          to={"/profile"}
          className="flex gap-4 items-center font-bold text-xl"
        >
          <AppliedBack />
          Telegram kanallar
        </Link>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-lg">
        {/* <h2 className="text-2xl font-bold text-gray-800 mb-6">
          Telegram Ma'lumotlari
        </h2>
        <div className="gap-4">
          <h3 className="text-xl font-bold text-gray-700 mb-2">
            Post Ma'lumotlari
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="mb-4">
              <label className="flex items-center gap-1 mb-1 text-gray-700">
                <span>📄</span> Post raqami:
              </label>
              <input
                type="number"
                name="postNumber"
                value={formData.postNumber}
                onChange={handleInputChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              />
            </div>
            <div className="mb-4">
              <label className=" flex gap-1 mb-1 items-center text-gray-700">
                <span>☎️</span> Kontakt raqami:
              </label>
              <InputMask
                mask="+998 (99) 999 9999"
                maskChar={null}
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handleInputChange}
                placeholder="+998 (__) ___ ____"
                alwaysShowMask
                className="border border-gray-300 rounded-md shadow-sm block w-full p-2.5 custom-focus"
              />
            </div>
            <div className="mb-4">
              <label className="flex gap-1 mb-1 text-gray-700">
                {" "}
                <span>🏢 </span> Kompaniya nomi:
              </label>
              <input
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={handleInputChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              />
            </div>
            <div className="mb-4">
              <label className="flex items-center gap-1 mb-1 text-gray-700">
                {" "}
                <TelegramIcon /> Telegram:
              </label>
              <input
                type="text"
                name="telegram"
                value={formData.telegram}
                onChange={handleInputChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              />
            </div>
            <div className="mb-4">
              <label className="flex items-center gap-1 mb-1 text-gray-700">
                <svg
                  class="w-6 h-6 text-gray-700 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"
                  />
                </svg>
                Havola:
              </label>
              <input
                type="text"
                name="link"
                value={formData.link}
                onChange={handleInputChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              />
            </div>
            <div className="mb-4">
              <label className="flex items-center gap-1 mb-1 text-gray-700">
                <span>
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </span>{" "}
                Qo'shimcha ma'lumot:
              </label>
              <textarea
                name="additionalInfo"
                value={formData.additionalInfo}
                onChange={handleInputChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              />
            </div>
          </div>
          <div className="flex mt-4 gap-3 flex-wrap">
            <button
              className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
              onClick={handleSubmit}
            >
              Ma'lumotlarni qo'shish yoki yangilash
            </button>
            <button
              className="bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600 transition duration-300"
              onClick={handleRemoveTelegramData}
            >
              Ma'lumotlarini o'chirish
            </button>
          </div>
        </div> */}

        <div className="mt-5">
          <h3 className="text-xl font-bold text-gray-700 mb-2">Rasm Yuklash</h3>

          {/* <div className="flex flex-wrap gap-3 items-center">
            <input
              type="file"
              onChange={(e) => setUploadImages(e.target.files)}
              className="border border-gray-300 p-0 rounded-md"
              ref={fileInputRef}
            />
           
          </div> */}

          <div className="flex flex-wrap gap-3 items-center">
            <div className="rounded-md border block  my-5 border-indigo-500 bg-gray-50 p-1 shadow-md w-50">
              <label
                htmlFor="upload"
                className="flex items-center gap-2 cursor-pointer"
              >
                <FileUpload />
                {/* <span className="text-gray-600 font-medium">{loading ? "Yuklanmoqda" : "Rasm yuklash"}</span> */}
                <span className="text-gray-600 font-medium"> Rasm yuklash</span>
              </label>
              <input
                id="upload"
                type="file"
                className="hidden"
                onChange={handleFileChange}
                ref={fileInputRef}
              />
            </div>
            <button
              disabled={uploadStatus === "loading" ? true : false}
              className="bg-green-500 text-white py-3 px-4 rounded-md hover:bg-green-600 transition duration-300"
              onClick={handleImageUpload}
            >
              {" "}
              {uploadStatus === 'loading' ? <ButtonSpinner /> : "Salqash"}

            </button>
          </div>

          <span className="text-gray-600 font-medium"> {fileName || ""}</span>
        </div>
      </div>

      {selectedPost === 0 ? (
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-bold text-gray-800 mb-4">Rasmlar</h3>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {images?.map((image, index) => (
              <div
                key={index}
                className={`border rounded-lg ${selectedImage === index &&
                  "bg-white rounded-lg border border-solid shadow-md border-blue-950"
                  }`}
              >
                <div className="relative">
                  <img
                    src={image}
                    alt={image}
                    className="w-full relative h-40 object-contain rounded-md"
                  />
                  <button
                    onClick={() => handleDeleteImage(image)}
                    className="absolute top-2 right-2  bg-red-500 text-white rounded-full p-1"
                  >
                    <svg
                      class="w-4 h-4 text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18 17.94 6M18 18 6.06 6"
                      />
                    </svg>
                  </button>
                </div>
                <button
                  onClick={() => ChangeSelectedImage(index)}
                  className="w-3/4 block mx-auto mb-2 mt-2 bottom-2 text-center bg-blue-500 text-white rounded-full p-1"
                >
                  Tanlash
                </button>
              </div>
            ))}
          </div>
        </div>
      ) : null}

      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h3 className="text-2xl font-bold text-gray-800 mb-4">Postlar</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {["Rasmli", "Rasmsiz"].map((text, index) => (
            <div
              key={index}
              className={`relative p-6 rounded-lg shadow-lg border-2 ${selectedPost === index ? "border-blue-900" : "border-gray-300"
                } flex items-center justify-between`}
            >
              <div className="flex items-center">
                <div className={`w-12 h-12  overflow-hidden mr-4`}>
                  {text === "Rasmli" ? (
                    <img
                      className="w-full  object-contain"
                      src={isImage}
                      alt="Rasmli"
                    />
                  ) : (
                    <img
                      className="w-full object-contain"
                      src={noImage}
                      alt="Rasmli"
                    />
                  )}
                </div>
                <h4 className="text-lg font-semibold text-gray-700">{text}</h4>
              </div>
              <button
                onClick={() => ChangeSelectedPost(index)}
                className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 transition-colors duration-200"
              >
                Tanlash
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-gradient-to-r from-green-500 to-blue-500 p-6 rounded-lg shadow-lg text-white">
        {!user?.telegram?.id ? (
          <a
            href={`https://t.me/group1416Bot?start=addTelegram_${phoneNumber}`}
            // href={`https://t.me/testuchunbot123_bot?start=addTelegram_${phoneNumber}`}
            target="_blank"
            rel="noopener noreferrer"
            className="px-4 py-2 bg-blue-600 rounded-full hover:bg-blue-700 transition duration-300"
          >
            Telegram qo'shish
          </a>
        ) : (
          <div>
            <p className="mb-4">Telegram ID: {user?.telegram.id}</p>
            <button
              onClick={handleRemoveTelegramId}
              className="px-4 py-2 bg-red-600 rounded-full hover:bg-red-700 transition duration-300"
            >
              O'chirish
            </button>
          </div>
        )}
      </div>

      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-6">Kanallar</h2>
        {channels?.length ? (
          channels?.map((channel) => (
            <div
              key={channel.id}
              className="flex items-center justify-between mb-4 p-4 bg-gray-100 rounded-lg shadow"
            >
              <div className="flex items-center space-x-4">
                <label className="flex items-center">
                  <input
                    onChange={() =>
                      handleUpdateChannel(channel.id, {
                        ...channel,
                        available: !channel.available,
                      })
                    }
                    type="checkbox"
                    checked={channel.available}
                    className="form-checkbox h-5 w-5 text-green-500"
                  />
                  <span className="ml-2 text-lg">{channel.name}</span>
                </label>
              </div>
              <div className="flex items-center space-x-4">
                <a
                  href={channel.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  {channel.link}
                </a>
                <button
                  onClick={() => handleLeaveChannel(channel.id)}
                  className="px-4 py-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition duration-300"
                >
                  Chiqish
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>Kanallar mavjud emas</p>
        )}
      </div>
    </div>
  );
};

export default TelegramManager;
