import { createSlice } from "@reduxjs/toolkit";
import {
  fetchLanguages,
  addLanguage,
  updateLanguage,
  deleteLanguage,
} from "./languagesThunks"; // Adjust the path according to your structure

const initialState = {
  languages: [],
  status: "idle",
  error: null,
};

const languagesSlice = createSlice({
  name: "languages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLanguages.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLanguages.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.languages = action.payload.data; // Adjust based on actual response structure
      })
      .addCase(fetchLanguages.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addLanguage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addLanguage.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload.data && action.payload.data.languages) {
          state.languages.push(action.payload.data.languages); // Adjust based on actual response structure
        } else {
          state.error = "Failed to add language";
        }
      })
      .addCase(addLanguage.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateLanguage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateLanguage.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.languages.findIndex(
          (language) => language.id === action.payload.data.id
        );
        if (index !== -1) {
          state.languages[index] = action.payload.data.languages; // Adjust based on actual response structure
        }
      })
      .addCase(updateLanguage.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteLanguage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteLanguage.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.languages = state.languages.filter(
          (language) => language.id !== action.payload.data.id
        ); // Adjust based on actual response structure
      })
      .addCase(deleteLanguage.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default languagesSlice.reducer;
