import { createSlice } from "@reduxjs/toolkit";
import {
  fetchProfessions,
  addProfessions,
  updateProfessions,
  deleteProfessions,
} from "./professionsThunks"; // Adjust the path according to your structure

const initialState = {
  professions: [],
  status: "idle",
  error: null,
};

const professionsSlice = createSlice({
  name: "professions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfessions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProfessions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.professions = action.payload.data; // Adjust based on actual response structure
      })
      .addCase(fetchProfessions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addProfessions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addProfessions.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload.data && action.payload.data.professions) {
          state.professions.push(...action.payload.data.professions); // Adjust based on actual response structure
        } else {
          state.error = "Failed to add professions";
        }
      })
      .addCase(addProfessions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateProfessions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateProfessions.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.professions.findIndex(
          (profession) => profession.id === action.payload.data.id
        );
        if (index !== -1) {
          state.professions[index] = action.payload.data.professions; // Adjust based on actual response structure
        }
      })
      .addCase(updateProfessions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteProfessions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteProfessions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.professions = state.professions.filter(
          (profession) => profession.id !== action.payload.data.id
        ); // Adjust based on actual response structure
      })
      .addCase(deleteProfessions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default professionsSlice.reducer;
