import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { authReducer } from "./auth/authSlice";
import statisticReducer from "./staticsData/statisticSlice";
import jobSeekersSlice from "./jobSeekersApi/jobSeekersSlice";
import employersSlice from "./employersApi/employersSlice";
import jobsSlice from "./jobsApi/jobsSlice";
import quickJobsSlice from "./quickJobsApi/quickJobsSlice";
import officeApiSlice from "./officeApi/officeApiSlice";
import avatarSlice from "./avatarApi/avatarSlice";
import workExperienceSlice from "./workExperienceApi/workExperienceSlice";
import educationSlice from "./educationApi/educationSlice";
import projectsSlice from "./projectsApi/projectsSlice";
import openApiSlice from "./openApi/openApiSlice";
import awardsSlice from "./awardsApi/awardsSlice";
import certificatesSlice from "./certificatesApi/certificatesSlice";
import contactsSlice from "./contactsApi/contactsSlice";
import summarySlice from "./summaryApi/summarySlice";
import languagesSlice from "./languagesApi/languagesSlice";
import skillsSlice from "./skillsApi/skillsSlice";
import professionsSlice from "./professionsApi/professionsSlice";
import cvFilesSlice from "./cvFilesApi/cvFilesSlice";
import gallerySlice from "./galleryApi/gallerySlice";
import companySlice from "./companyApi/compantSlice";
import tournamentSlice from "./tournamentApi/tournamentSlice";
import usersReducer from "./usersApi/usersSlice";
import apiSlice from "../services/api";
import telegramSlice from "./telegramApi/telegramSlice";

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["auth", "jobs", "quickJobs", "openApi"],
};

const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);

export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    statistics: statisticReducer,
    users: usersReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    openApi: openApiSlice,
    jobSeekers: jobSeekersSlice,
    employers: employersSlice,
    jobs: jobsSlice,
    quickJobs: quickJobsSlice,
    office: officeApiSlice,
    avatar: avatarSlice,
    workExperience: workExperienceSlice,
    education: educationSlice,
    projects: projectsSlice,
    awards: awardsSlice,
    certificates: certificatesSlice,
    contacts: contactsSlice,
    summary: summarySlice,
    languages: languagesSlice,
    skills: skillsSlice,
    professions: professionsSlice,
    cvFiles: cvFilesSlice,
    gallery: gallerySlice,
    company: companySlice,
    tournamentApi: tournamentSlice,
    telegramApi: telegramSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "persist/PERSIST",
          "persist/REHYDRATE",
          "persist/FLUSH",
          "persist/PAUSE",
          "persist/PURGE",
          "persist/REGISTER",
        ],
      },
    }).concat(apiSlice.middleware),
  devTools: true,
});

export const persistor = persistStore(store);
