import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";

import { Link } from "react-router-dom";
import { QuickJobsIcon } from "../icon";
import { FreeMode, Pagination } from "swiper/modules";
import quickJobsImg from "../../assets/img/quickjobs.png";
import { useTranslation } from "react-i18next";

export const QuickJobCard = ({ loading, quickJobs }) => {
  const maskPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
    const maskedPart = "****";
    return phoneNumber.slice(0, -4) + maskedPart;
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = date.getUTCDate();
    const year = date.getUTCFullYear();

    const months = [
      "Yanvar",
      "Fevral",
      "Mart",
      "Aprel",
      "May",
      "Iyun",
      "Iyul",
      "Avgust",
      "Sentabr",
      "Oktabr",
      "Noyabr",
      "Dekabr",
    ];

    const month = months[date.getUTCMonth()];

    const formattedDay = day < 10 ? `0${day}` : day;

    return `${formattedDay} ${month} ${year}`;
  }

  const { t } = useTranslation();

  return (
    <div className="quickjobs">
      <Swiper
        slidesPerView={1.1}
        spaceBetween={20}
        slideToClickedSlide={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          1440: {
            slidesPerView: 3.2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 2.2,
            spaceBetween: 20,
          },
          992: {
            slidesPerView: 2.2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2.2,
            spaceBetween: 20,
          },
          650: {
            slidesPerView: 2.2,
            spaceBetween: 20,
          },
          550: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
          400: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
        }}
        modules={[FreeMode, Pagination]}
        className="mySwiper"
      >
        {
          quickJobs?.map((job) => (
            <SwiperSlide  key={job?._id}>
            <Link
              to={`/quickjobs/${job?._id}`}
              className="rounded-2xl bg-white shadow-md h-40 justify-between flex gap-2"
            >
              <div className="p-4 justify-between flex gap-2">
                <div className="xl:w-60 lg:w-52 w-52 relative flex flex-col justify-between">
                  <div><div className="flex justify-between">
                    <h3 className="text-[#242132] text-[14px] text-start not-italic font-semibold leading-[normal]">
                      {job?.title?.substring(0, 20)}...
                    </h3>
                    <p className="m-0 text-[#888] text-[12px] not-italic font-medium leading-[161.023%]">
                      +998{maskPhoneNumber(job?.phoneNumber)}
                    </p>
                  </div>
                  <p className="mt-2 text-[#888] text-[12px] font-medium text-start leading-[161.023%]">
                    {job?.description.substring(0, 50)}...
                  </p></div>
                  <div className="mt-2 flex justify-between items-center">
                    <p className="text-[#504d4d] text-[12px] font-medium">
                      {formatDate(job?.createdAt)}
                    </p>
                    <p className="text-[#504d4d] text-[12px] font-medium text-end">
                      {job?.location}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="w-20 h-full">
                  <img
                    className="h-full"
                    src={quickJobsImg}
                    alt="quickjobs"
                  />
                </div>
              </div>
            </Link>
          </SwiperSlide>
          ))
        }
      </Swiper>
    </div>
  );
};
