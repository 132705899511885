// store/thunks.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

export const fetchUsers = createAsyncThunk("statistic/fetchUsers", async () => {
  const response = await axios.get("/users/allUsers");
  // console.log("fetch :", response.data);
  return response.data;
});

export const fetchJobSeekers = createAsyncThunk(
  "statistic/fetchJobSeekers",
  async () => {
    const response = await axios.get("/statistics/jobseekers");
    return response.data;
  }
);

export const fetchEmployers = createAsyncThunk(
  "statistic/fetchEmployers",
  async () => {
    const response = await axios.get("/statistics/employers");
    return response.data;
  }
);

export const fetchApplicants = createAsyncThunk(
  "statistic/applicants",
  async () => {
    const response = await axios.get("/statistics/applicants");
    return response.data;
  }
);


export const fetchJobs = createAsyncThunk("statistic/fetchJobs", async () => {
  const response = await axios.get("statistics/jobs");
  return response.data;
});

export const fetchCompany = createAsyncThunk(
  "statistic/companies",
  async () => {
    const response = await axios.get("/statistics/companies");
    // console.log("fetch jobs response:", response.data);
    return response.data?.data; // Adjust based on actual response structure
  }
);
