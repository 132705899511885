import React from "react";
import { Category } from "./category/Category";
import LastJobs from "./lastJobs/LastJobs";
import QuickJobs from "./quickJobs/QuickJobs";
import Recomended from "./recomended/Recomended";

export const Home = () => {
  return (
    <div>
      <Category />
      <QuickJobs />
      <LastJobs />
      <Recomended />
    </div>
  );
};
