// store/dataSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  createOffice,
  fetchOffice,
  fetchOfficeId,
  fetchOfficeMy,
} from "./officeApiThunk"; // Adjust the path according to your structure

const initialState = {
  office: [],
  officeId: [],
  myOffice:[],
  currentPage: 1,
  totalPages: 1,
  status: "idle",
  error: null,
};

const officeApiSlice = createSlice({
  name: "offieApi",
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchOffice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOffice.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.office = action.payload.office; // Ensure this matches the API response
        state.totalPages = action.payload.totalPages; // Ensure this matches the API response
        state.currentPage = action.payload.currentPage; // Ensure this matches the API response
      })
      .addCase(fetchOffice.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(fetchOfficeMy.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOfficeMy.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.myOffice = action.payload.myOffice; // Ensure this matches the API response
        state.totalPages = action.payload.totalPages; // Ensure this matches the API response
        state.currentPage = action.payload.currentPage; // Ensure this matches the API response
      })
      .addCase(fetchOfficeMy.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(fetchOfficeId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchOfficeId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOfficeId.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.officeId = action.payload.officeId; // Ensure this matches the API response
        state.totalPages = action.payload.totalPages; // Ensure this matches the API response
        state.currentPage = action.payload.currentPage; // Ensure this matches the API response
      })
      .addCase(createOffice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createOffice.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.office.push(action.payload); // Add the new job to the list
      })
      .addCase(createOffice.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    // Add the fetchQuickJobs reducer here
  },
});

export const { setCurrentPage } = officeApiSlice.actions;
export default officeApiSlice.reducer;
