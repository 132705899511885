import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAwards,
  addAward,
  updateAward,
  deleteAward,
} from "./awardsThunks"; // Adjust the path according to your structure

const initialState = {
  awards: [],
  status: "idle",
  error: null,
};

const awardsSlice = createSlice({
  name: "awards",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAwards.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAwards.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.awards = action.payload.data; // Adjust based on actual response structure
      })
      .addCase(fetchAwards.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addAward.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addAward.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload.data && action.payload.data.awards) {
          state.awards.push(action.payload.data.awards); // Adjust based on actual response structure
        } else {
          state.error = "Failed to add award";
        }
      })
      .addCase(addAward.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateAward.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateAward.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.awards.findIndex(
          (award) => award.id === action.payload.data.id
        );
        if (index !== -1) {
          state.awards[index] = action.payload.data.awards; // Adjust based on actual response structure
        }
      })
      .addCase(updateAward.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteAward.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteAward.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.awards = state.awards.filter(
          (award) => award.id !== action.payload.data.id
        ); // Adjust based on actual response structure
      })
      .addCase(deleteAward.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default awardsSlice.reducer;
