import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

// Add contact
export const updateCompanyIntro = createAsyncThunk(
  "company/updateintro",
  async ({ id, description }) => {
    const response = await axios.patch(`companies/${id}/minorChange`, {
      description,
    });
    return response.data; // Assuming the response contains the added contact
  }
);

export const fetchCompanies = createAsyncThunk(
  "company/fetchCompanies",
  async () => {
    const response = await axios.get("companies");
    console.log("employers response", response.data.data);
    return {
      companies: response.data.data, // Adjust based on actual response structure
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
    };
  }
);

export const fetchCompaniesById = createAsyncThunk(
  "company/fetchCompaniesbyid",
  async (id) => {
    const response = await axios.get(`companies/${id}`);
    console.log("employers response", response.data.data);
    return {
      companies: response.data.data, // Adjust based on actual response structure
    };
  }
);

export const updateCompany = createAsyncThunk(
  "company/updateintro",
  async ({ id, body }) => {
    const response = await axios.patch(`companies/${id}/minorChange`, body);
    return response.data; // Assuming the response contains the added contact
  }
);

export const deleteCompanyEmployer = createAsyncThunk(
  "company/delete",
  async ({ userId, id }) => {
    await axios.delete(`/companies/${id}/removeEmployer`, {
      data: { userId },
    });
    return id; // Return the deleted user ID
  }
);

export const addCompanyEmployer = createAsyncThunk(
  "company/addemployer",
  async ({ userId, id }) => {
    const response = await axios.post(
      `companies/${id}/addingEmployerManually`,
      { userId }
    );
    return response.data;
  }
);

export const requestCompanyEmployer = createAsyncThunk(
  "company/request",
  async (id) => {
    const response = await axios.post(
      `companies/${id}/sendingRequest`);
    return response.data;
  }
);
