import React, { useEffect, useState, useCallback, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import io from "socket.io-client";
import { BASE_URL_DOMAIN } from "../../api/api";
import chatbg from "../../assets/img/chatbg.png";
import read from "../../assets/img/read.png";
import unread from "../../assets/img/unread.png";
import { ChatBottom } from "../../components/icon";
import { fetchCurrentUser } from "../../store/usersApi/usersThunks";
import { useDispatch, useSelector } from "react-redux";
import {
  AiOutlineFile,
  AiOutlineAudio,
  AiOutlineVideoCamera,
  AiOutlineClose,
} from "react-icons/ai";
import FileDisplay from "../../components/FileDisplay";

const socket = io(BASE_URL_DOMAIN); // Change to your server URL

const Chat = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.users);
  const { chatRoomId } = useParams();
  const user = currentUser?.data;
  const myUserId = user?._id;
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [otherUserId, setOtherUserId] = useState(null);
  const [otherUser, setOtherUser] = useState(null);
  const [messagesFetched, setMessagesFetched] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [progressFile, setProgressFile] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [currentFile, setCurrentFile] = useState(null);

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, [dispatch]);

  const handleNewMessage = useCallback((message) => {
    console.log(message, "handleNewMessage");
    setProgressFile([]);
    setCurrentFile([]);
    setMessages((prevMessages) => [...prevMessages, message]);
  }, []);

  const handleChatRoomResponse = useCallback((response) => {
    console.log(response);
    setMessages(response.data?.messageHistory);
    setOtherUserId(response.data?.otherUser._id);
    setOtherUser(response.data?.otherUser);
    setMessagesFetched(true);
  }, []);

  const fetchMessages = useCallback(() => {
    if (user && chatRoomId && !messagesFetched) {
      socket.emit("singleChatRoom", { userId: user._id, chatRoomId });
      socket.once("chatRoomResponse", handleChatRoomResponse);
    }
  }, [chatRoomId, user, handleChatRoomResponse, messagesFetched]);

  const markMessagesAsSeen = useCallback(() => {
    if (messages.length > 0) {
      const updatedMessages = messages.map((message) => ({
        ...message,
        seen: true,
      }));
      setMessages(updatedMessages);
      socket.emit("markMessagesAsSeen", {
        userId: user._id,
        chatRoomId,
      });
    }
  }, [messages, chatRoomId, user]);

  useEffect(() => {
    if (user && chatRoomId) {
      console.count("Chat component rendered");
      socket.emit("joinRoom", { userId: user._id, chatRoomId });

      const handleGetOnlineUsers = (onlineUsers) => {
        console.count("Online users received");
        console.log("Online users:", onlineUsers);
      };

      socket.on("getOnlineUsers", handleGetOnlineUsers);
      socket.on("getMessage", handleNewMessage);
      socket.on("messageRead", (data) => {
        console.log("Message read:", data);
      });
      socket.on("joinedRoom", (data) => {
        console.log("joinedRoom:", data);
        fetchMessages(); // Fetch messages upon joining the room
        markMessagesAsSeen(); // Mark all messages as seen
      });
      socket.on("leftRoom", (data) => {
        console.log("leftRoom:", data);
      });
      socket.on("errorNotification", (error) => {
        console.error("Error notification:", error);
      });

      fetchMessages();

      return () => {
        socket.emit("leaveRoom", { userId: user._id, chatRoomId });
        socket.off("getMessage", handleNewMessage);
        socket.off("chatRoomResponse", handleChatRoomResponse);
        socket.off("getOnlineUsers", handleGetOnlineUsers);
      };
    } else {
      console.warn("User or chatRoomId is missing, cannot join room.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRoomId]);

  useEffect(() => {
    if (user) {
      console.count("Chat component mounted");
      socket.emit("heartbeat", user._id);
      const heartbeatInterval = setInterval(() => {
        socket.emit("heartbeat", user._id);
      }, 20 * 60 * 1000); // 20 minutes

      return () => {
        clearInterval(heartbeatInterval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
    setProgressFile(files);
    const newSelectedImages = await Promise.all(
      files.map((file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve({ file, url: reader.result });
          };
          reader.readAsDataURL(file);
        });
      })
    );

    setSelectedImages(newSelectedImages);
    setProgressFile(newSelectedImages);
  };

  const handleRemoveFile = (index) => {
    setSelectedImages((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const renderIcon = (file) => {
    const fileType = file.type?.split("/")[0];
    switch (fileType) {
      case "image":
        return "";
      case "audio":
        return <AiOutlineAudio className="text-3xl" />;
      case "video":
        return <AiOutlineVideoCamera className="text-3xl" />;
      default:
        return <AiOutlineFile className="text-3xl" />;
    }
  };

  // ------------------- File Upload -------------------
  const CHUNK_SIZE = 512 * 512; // 256KB chunks
  const splitFileIntoChunks = (file) => {
    const chunks = [];
    let currentByte = 0;
    while (currentByte < file.size) {
      const chunk = file.slice(currentByte, currentByte + CHUNK_SIZE);
      chunks.push(chunk);
      currentByte += CHUNK_SIZE;
    }
    console.log("chunks: ", chunks);
    return chunks;
  };
  const sendChunkedFiles = async (files, socket, senderId, recipientId) => {
    let uploadedUrls = [];
    for (const file of files) {
      setCurrentFile(file.name);
      await new Promise((resolve) => {
        const chunks = splitFileIntoChunks(file);
        let processedChunks = 0;
        const totalChunks = chunks.length;
        console.log("totalChunks: ", totalChunks);
        let currentChunkIndex = 0;
        const intervalId = setInterval(async () => {
          if (currentChunkIndex >= totalChunks) {
            clearInterval(intervalId);
            resolve();
            return;
          }

          const chunk = chunks[currentChunkIndex];
          const base64Chunk = await convertFileChunkToBase64(chunk);
          processedChunks++;
          const progress = Math.round((processedChunks / totalChunks) * 100);

          setUploadProgress((prevProgress) => {
            console.log(`Updating progress: ${progress}%`);
            return progress;
          });

          // console.log("processedChunks: ", processedChunks);
          // console.log("totalChunks: ", totalChunks);
          socket.emit(
            "fileChunk",
            {
              name: file.name,
              type: file.type,
              data: base64Chunk,
              chunkIndex: currentChunkIndex,
              totalChunks: totalChunks,
              senderId,
              recipientId,
            },
            (uploadedUrl) => {
              if (uploadedUrl) {
                uploadedUrls.push(uploadedUrl);
                if (
                  processedChunks === totalChunks &&
                  uploadedUrls.length === selectedFiles.length
                ) {
                  UploadImages(uploadedUrls);
                }
              }
            }
          );

          currentChunkIndex++;
        }, 200); // Interval delay of 200ms
      });
    }
  };
  function UploadImages(uploadedUrls) {
    console.log("New Message: ", newMessage);
    let messageData = null;
    if (
      selectedFiles.length > 0 &&
      uploadedUrls !== null &&
      uploadedUrls !== undefined
    ) {
      messageData = {
        text: newMessage.length > 0 ? newMessage : "",
        senderId: user._id,
        recipientId: otherUserId,
        chatRoomId,
        timestamp: new Date().toISOString(),
        files: selectedFiles.map((file, index) => ({
          name: uploadedUrls[index].name,
          type: uploadedUrls[index].type,
          url: uploadedUrls[index].url || null, // Ensure we have a URL or null
        })), // Meta data of files with uploaded URLs
      };
    }
    // Emit the message and handle the acknowledgment
    socket.emit("sendMessage", messageData, (response) => {
      if (response.success) {
        console.log("Message sent successfully", response);
      } else {
        console.error("Failed to send message", response.error);
      }
    });

    console.log("messageData", messageData);
    setNewMessage("");
    setSelectedFiles([]);
    setUploadProgress(0); // Reset the upload progress
  }
  const convertFileChunkToBase64 = (chunk) => {
    console.log("chunk: ", chunk);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(chunk);
    });
  };
  const sendMessage = async (e) => {
    e.preventDefault();
    setSelectedImages([]);

    if (newMessage.trim() || (user && chatRoomId && otherUserId)) {
      if (selectedFiles?.length > 0) {
        await sendChunkedFiles(selectedFiles, socket, user._id, otherUserId);
      } else {
        let messageData = null;
        messageData = {
          text: newMessage.length > 0 ? newMessage : "",
          senderId: user._id,
          recipientId: otherUserId,
          chatRoomId,
          timestamp: new Date().toISOString(),
          files: selectedFiles.map((file, index) => ({
            name: file.name,
            type: file.type,
          })), // Meta data of files with uploaded URLs
        };
        // Emit the message and handle the acknowledgment
        socket.emit("sendMessage", messageData, (response) => {
          if (response.success) {
            console.log("Message sent successfully", response);
          } else {
            console.error("Failed to send message", response.error);
          }
        });
        console.log("messageData", messageData);
        setNewMessage("");
      }
    } else {
      console.error("Failed to send message:", {
        newMessage,
        selectedFiles,
        user,
        chatRoomId,
        otherUserId,
      });
    }
  };
  // ------------------- File Upload -------------------

  const bottomRef = useRef(null);
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  console.log("current: ", currentFile);

  return (
    <div>
      {/* Progress Bar */}

      <div className="fixed z-10 w-full overflow-hidden antialiased bg-gray-200">
        <div className="relative flex flex-col flex-1">
          <div className="z-20 flex items-center px-4 flex-grow-0 flex-shrink-0 w-full pr-3 bg-[#5081AF] border-b">
            <Link to={"/chat"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#fff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M19 12H6M12 5l-7 7 7 7" />
              </svg>
            </Link>
            <div
              className="w-12 h-12 mx-4 my-2 bg-blue-500 bg-center bg-no-repeat bg-cover rounded-full cursor-pointer"
              style={{
                backgroundImage: `url(${otherUser?.avatar})`,
              }}
            ></div>
            <div className="flex flex-col justify-center flex-1 overflow-hidden cursor-pointer">
              <div className="overflow-hidden text-base font-medium leading-tight text-white whitespace-no-wrap">
                {otherUser?.fullName}
              </div>
            </div>

            <button className="p-2 text-white flex self-center rounded-full md:hidden focus:outline-none">
              <ChatBottom />
            </button>
          </div>
        </div>
      </div>

      <div className="flex pt-16 h-screen antialiased text-gray-800">
        <div className="flex flex-row h-full w-full overflow-x-hidden">
          <div className="flex flex-col flex-auto h-full">
            <div
              style={{
                backgroundImage: `url(${chatbg})`,
              }}
              className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-full top-0 bottom-0 left-0 right-0 overflow-hidden  bg-bottom bg-cover"
            >
              <div className="flex flex-col h-full overflow-x-auto mb-4 mt-4 px-4">
                <div className="flex flex-col h-full ">
                  <div className="grid grid-cols-12 gap-y-2 pb-10">
                    {messages?.map((message, index) => (
                      <div
                        key={index}
                        className={`${
                          message.senderId._id === myUserId
                            ? "col-start-3 col-end-13 rounded-lg "
                            : "col-start-1 col-end-11 mb-3 rounded-lg"
                        } `}
                      >
                        <div
                          className={`${
                            message.senderId._id === myUserId
                              ? "flex flex-col items-end justify-end"
                              : "flex flex-row items-center justify-start"
                          } `}
                        >
                          <div
                            className={`${
                              message.fileUrls?.length > 0
                                ? `${
                                    message.senderId._id === myUserId
                                      ? "relative text-sm bg-indigo-300   shadow rounded-xl"
                                      : "relative ml-3 text-sm bg-white   shadow rounded-xl"
                                  } `
                                : `${
                                    message.senderId._id === myUserId
                                      ? "relative text-sm bg-indigo-300   shadow rounded-xl"
                                      : "relative ml-3 text-sm bg-white py-2 px-4  shadow rounded-xl"
                                  } `
                            }`}
                          >
                            <div className="  text-base">
                              {message.fileUrls?.length > 0 ? (
                                <FileDisplay
                                  message={message}
                                  key={index}
                                  url={message}
                                />
                              ) : (
                                <div className="flex justify-between items-end gap-2 text-base p-2">
                                  <p>{message.text}</p>
                                  <div className="ml-1 flex items-center gap-2 text-xs font-medium text-gray-600">
                                    {new Date(
                                      message.timestamp
                                    ).toLocaleTimeString([], {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: false,
                                    })}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          {message.senderId._id === myUserId ? (
                            <img
                              src={message.seen ? read : unread}
                              alt={message.seen}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ))}
                    {currentFile ? (
                      <div className="mb-3 col-start-3 col-end-13 rounded-lg">
                        {progressFile?.length
                          ? progressFile.map((fileObj, index) => (
                              <div
                                key={index}
                                className="file-preview flex flex-col items-end justify-end"
                              >
                                <div className="flex items-center">
                                  <div className="ml-2  relative">
                                    {fileObj.file?.type.startsWith("image/") &&
                                    fileObj.url ? (
                                      <div className="relative">
                                        <img
                                          src={fileObj.url}
                                          alt={fileObj.file.name}
                                          className="w-full rounded-md max-w-44 relative"
                                        />{" "}
                                        <div class="absolute inset-0 bg-white bg-opacity-10 backdrop-blur-sm"></div>
                                      </div>
                                    ) : (
                                      <p>{fileObj.file?.name}</p>
                                    )}
                                    <div
                                      className="bg-green-500  text-sm leading-none py-1 text-center text-white"
                                      style={{ width: `${uploadProgress}%` }}
                                    >
                                      {uploadProgress}%
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          : ""}
                      </div>
                    ) : (
                      ""
                    )}
                    <div ref={bottomRef} />
                  </div>
                </div>
              </div>

              <div className="fixed bottom-0 w-full">
                {selectedImages?.length ? (
                  <div className="mt-4 p-4 w-full bg-gray-100 backdrop-blur bg-opacity-70  flex flex-wrap gap-4">
                    {selectedImages.map((fileObj, index) => (
                      <div
                        key={index}
                        className="file-preview flex items-center justify-between"
                      >
                        <div className="flex items-center">
                          {renderIcon(fileObj.file)}
                          <div className="ml-2">
                            {fileObj.file.type.startsWith("image/") &&
                            fileObj.url ? (
                              <img
                                src={fileObj.url}
                                alt={fileObj.file.name}
                                className="w-32 h-32 object-cover rounded-md"
                              />
                            ) : (
                              <p>{fileObj.file.name}</p>
                            )}
                          </div>
                          <button
                            onClick={() => handleRemoveFile(index)}
                            className="ml-4 text-red-500"
                          >
                            <AiOutlineClose className="text-2xl" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )}
                <form
                  onSubmit={sendMessage}
                  className="flex flex-row items-center h-16 rounded-xl bg-white w-full px-4"
                >
                  <label htmlFor="upload">
                    <svg
                      className="w-5 h-5 cursor-pointer"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                      />
                    </svg>
                  </label>
                  <input
                    type="file"
                    multiple
                    className="hidden"
                    onChange={handleFileChange}
                    id="upload"
                  />
                  <div className="flex-grow ml-4">
                    <div className="relative w-full">
                      <input
                        placeholder="Message..."
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        type="text"
                        className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
                      />
                    </div>
                  </div>
                  <div className="ml-4">
                    <button className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1 flex-shrink-0">
                      <span>Send</span>
                      <span className="ml-2">
                        <svg
                          className="w-4 h-4 transform rotate-45 -mt-px"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
