import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { AppliedBack, HRLocation, HRUser } from "../../components/icon";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserById } from "../../store/usersApi/usersThunks";
import { fetchEmployersMaker } from "../../store/employersApi/employersThunks";
import quickJobsImg from "../../assets/img/quickjobs.png";


export const UserInformation = () => {
  const { id } = useParams();
  const jobid = localStorage.getItem('_jobid');
  const { singleUser } = useSelector((state) => state.users);
  const { jobs } = useSelector((state) => state.employers);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserById(id));
    dispatch(fetchEmployersMaker(id))
  }, [dispatch, id]);

  
  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = date.getUTCDate();
    const year = date.getUTCFullYear();

    // Array of month names
    const months = [
      "Yanvar",
      "Fevral",
      "Mart",
      "Aprel",
      "May",
      "Iyun",
      "Iyul",
      "Avgust",
      "Sentabr",
      "Oktabr",
      "Noyabr",
      "Dekabr",
    ];

    const month = months[date.getUTCMonth()];

    // Add leading zero to day if necessary
    const formattedDay = day < 10 ? `0${day}` : day;

    return `${formattedDay} ${month} ${year}`;
  }

  const maskPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
    const maskedPart = "****";
    return phoneNumber.slice(0, -4) + maskedPart;
  };
  
  console.log(singleUser);
  console.log(jobs?.Quickjob)
  return (
    <div className="mx-auto mb-10">
      <div className="flex justify-between mb-5">
        <Link
          to={`/quickjobs/${jobid}`}
          className="flex gap-4 items-center font-bold text-xl"
        >
          <AppliedBack />
        </Link>
      </div>

      <div className="flex items-center gap-5">
        <img
          className="h-40 w-40 rounded-full"
          alt={singleUser?.fullName}
          src={singleUser?.avatar}
        />
        <div className="flex flex-col gap-4">
          <p className="text-black text-xl font-semibold flex gap-2 items-center">
            <HRUser />
            {singleUser?.fullName}
          </p>
          <p className="text-meta-10 text-xl font-normal flex gap-2 items-center">
            {" "}
            <HRLocation />
            {singleUser?.location}
          </p>{" "}
        </div>
      </div>


      <ul className="mt-10 grid place-items-center grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
        {jobs?.Quickjob?.map((job) => (
          <Link
            key={job?._id}
            to={`/quickjobs/${job?._id}`}
            className="rounded-2xl w-full shadow-md h-40 justify-between flex gap-2"
          >
            <div className="p-4 justify-between flex gap-2">
              <div className=" relative flex flex-col justify-between">
                <div>
                  <div className="flex justify-between">
                    <h3 className="text-[#242132] text-[14px] text-start not-italic font-semibold leading-[normal]">
                      {job?.title?.substring(0, 20)}...
                    </h3>
                    <p className="m-0 text-[#888] text-[12px] not-italic font-medium leading-[161.023%]">
                      +998{maskPhoneNumber(job?.phoneNumber)}
                    </p>
                  </div>
                  <p className="mt-2 text-[#888] text-[12px] font-medium text-start leading-[161.023%]">
                    {job?.description.substring(0, 50)}...
                  </p>
                </div>
                <div className="mt-2 flex justify-between items-center">
                  <p className="text-[#504d4d] text-[12px] font-medium">
                    {formatDate(job?.createdAt)}
                  </p>
                  <p className="text-[#504d4d] text-[12px] font-medium text-end">
                    {job?.location}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="w-20 h-full">
                <img className="h-full" src={quickJobsImg} alt="quickjobs" />
              </div>
            </div>
          </Link>
        ))}
      </ul>

    </div>
  );
};
