import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as HomeIcon } from "../../assets/icons/icons8-home.svg";
import { ReactComponent as MessageIcon } from "../../assets/icons/message.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/user.svg";
import { AddAnnoun } from "../../components/icon";
import { useTranslation } from "react-i18next";

function EmployerFooterMobile() {
  const { t } = useTranslation();

  return (
    <div className="w-full flex z-20 border-t border-t-gray-200 fixed bottom-0 h-20 bg-white justify-between px-5">
      <Link
        to="/"
        className="flex items-center justify-center h-full flex-col w-1/4 text-main_gray focus:text-main_blue"
      >
        <HomeIcon className="h-6 w-6" />
        <span className="">{t(`Home`)}</span>
      </Link>
      <Link
        to="/announ"
        className="flex items-center justify-center h-full flex-col w-1/4 text-main_gray focus:text-main_blue"
      >
        <AddAnnoun />

        <span className="">{t("Post")}</span>
      </Link>
      <Link
        to="/chat"
        className="flex items-center justify-center h-full flex-col w-1/4 text-main_gray focus:text-main_blue"
      >
        <MessageIcon className="h-6 w-6" />
        <span className="">{t("Messages")}</span>
      </Link>
      <Link
        to="/profile"
        className="flex items-center justify-center h-full flex-col w-1/4 text-main_gray focus:text-main_blue"
      >
        <UserIcon className="h-6 w-6" />
        <span className="">{t("profile")}</span>
      </Link>
    </div>
  );
}

export default EmployerFooterMobile;
