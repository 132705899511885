import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchEmployersById } from "../../../../store/employersApi/employersThunks";
import { Link, useParams } from "react-router-dom";
import {
  AppliedBack,
  HRArrow,
  HRLocation,
  HRUser,
} from "../../../../components/icon";
import { fetchCurrentUser } from "../../../../store/usersApi/usersThunks";
import { addCompanyEmployer } from "../../../../store/companyApi/companyThunk";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export const AddHrSingle = () => {
  const { id } = useParams();
  const { currentUser } = useSelector((state) => state.users);
  const { singleEmployer } = useSelector((state) => state.employers);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(searchEmployersById(id));
    dispatch(fetchCurrentUser());
  }, [dispatch, id]);

  const handleAdded = (id) => {
    const employeesId = id.target.id;
    const companyId = currentUser.data.company._id;
    dispatch(addCompanyEmployer({ userId: employeesId, id: companyId })).then(
      (result) => {
        console.log(result);
        if (singleEmployer?._id === currentUser?.data?._id) {
          toast.error("Bu sizning profilingiz!");
          return;
        }
        if (result?.meta.requestStatus === "fulfilled") {
          dispatch(fetchCurrentUser());
          toast.success("Assistent muvaffaqqiyatli qo'shildi");
        }
        if (!result?.payload) {
          toast.error("Avval qo'shilgan!");
        }
      }
    );
  };

  const { t } = useTranslation();

  return (
    <div className="mb-10 hero-banner">
      <div >
        <div className="flex justify-between mb-5">
          <Link
            to={"/profile/hrs"}
            className="flex gap-4 items-center font-bold text-xl"
          >
            <AppliedBack />
          </Link>
        </div>

        <div className="flex items-center gap-5">
          <img
            className="h-40 w-40 rounded-full"
            alt={singleEmployer?.fullName}
            src={singleEmployer?.avatar}
          />
          <div className="flex flex-col gap-4">
            <p className="text-black text-xl font-semibold flex gap-2 items-center">
              <HRUser />
              {singleEmployer?.fullName}
            </p>
            <p className="text-meta-10 text-xl font-normal flex gap-2 items-center">
              {" "}
              <HRLocation />
              {t(`${singleEmployer?.location}`)}
            </p>{" "}
          </div>
        </div>

        <div className="mt-20">
          <hr />
          <Link
            className="flex py-4 items-center justify-between"
            to={`/profile/company`}
          >
            <div className="flex gap-4">
              <div>
                <p className="text-black text-xl font-semibold">
                  {t("About Company")}
                </p>
              </div>
            </div>
            <HRArrow />
          </Link>
          <hr />
        </div>
      </div>
      <div className="w-full ">
        {/* {!singleEmployer?.company ? (
          ""
        ) : ( */}
        <button
          id={singleEmployer?._id}
          onClick={(id) => handleAdded(id)}
          className={`shadow-md   mx-auto sm:w-3/4 md:w-2/4 fixed inset-x-0 bottom-10  text-white font-bold text-base  bg-[#246BFD] rounded-[100px] p-3 `}
          type="button"
        >
          {t("Add_To_Company")}
        </button>
        {/* )} */}
      </div>
    </div>
  );
};
