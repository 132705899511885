import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchRecommendedJobSeekers } from "../../../../store/jobSeekersApi/jobSeekersThunks";
import { AppliedBack } from "../../../../components/icon";
import EmployerJobCard from "../../../../components/cards/EmployerJobCard";
import { useTranslation } from "react-i18next";

const AllRecomended = () => {
  const dispatch = useDispatch();
  const { totalPages } = useSelector((state) => state.jobSeekers);
  const [currentPage, setCurrentPage] = useState(1);
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchRecommendedJobSeekers({ page: currentPage, pageSize: 10 }))
      .then((action) => {
        const jobSeekers = action.payload?.recommendedJobSeekers || [];
        setJobs((prevJobs) => [...prevJobs, ...jobSeekers]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching job seekers:", error);
        setIsLoading(false);
      });
  }, [dispatch, currentPage]);

  const handleShowMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const { t } = useTranslation();


  return (
    <nav className="overflow-hidden mx-auto mb-10">
      <div className="flex items-center justify-between mb-5">
        <Link to={"/"} className="flex gap-4 items-center font-bold text-xl">
          <AppliedBack />
          {t("Recommended Talents")}
        </Link>
      </div>
      <EmployerJobCard data={jobs} loading={isLoading} />
      {currentPage < totalPages && (
        <button
          onClick={handleShowMore}
          className="mt-10 block m-auto p-2 bg-blue-500 text-white rounded"
          disabled={isLoading}
        >
          {isLoading ? "Yuklanmoqda..." : "Ko'proq ko'rish"}
        </button>
      )}
    </nav>
  );
};

export default AllRecomended;
