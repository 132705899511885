import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

// Fetch contacts
export const fetchContacts = createAsyncThunk(
  "contacts/fetchContacts",
  async () => {
    const response = await axios.get("/users/resume/contacts");
    console.log("fetchContacts", response.data);
    return response.data; // Assuming the response contains { data: { contacts: [...] } }
  }
);

// Add contact
export const addContact = createAsyncThunk(
  "contacts/addContact",
  async (contact) => {
    console.log("addContact", contact);
    const response = await axios.post("/users/resume/contact", contact);
    return response.data; // Assuming the response contains the added contact
  }
);

// Update contact by id
export const updateContact = createAsyncThunk(
  "contacts/updateContact",
  async ({ id, contact }) => {
    const response = await axios.put(`/users/resume/contacts/${id}`, {
      email: contact.email,
      phone: contact.phone,
    });
    return response.data; // Assuming the response contains the updated contact
  }
);

// Delete contact by id
export const deleteContact = createAsyncThunk(
  "contacts/deleteContact",
  async (id) => {
    const response = await axios.delete(`/users/resume/contacts/${id}`);
    return response.data; // Assuming the response contains the updated contacts list
  }
);
