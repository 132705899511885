import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fakeimg from "../../../assets/img/hero-bg.png";
import { Link } from "react-router-dom";
import { AppliedBack } from "../../../components/icon";
import { fetchOffice } from "../../../store/officeApi/officeApiThunk";

const AllOffice = () => {
  const dispatch = useDispatch();
  const { totalPages } = useSelector((state) => state.office);
  const [currentPage, setCurrentPage] = useState(1);
  const [office, setOffice] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchOffice({ page: currentPage, pageSize: 10 })).then(
      (action) => {
        setOffice((prevJobs) => [...prevJobs, ...action.payload?.office]);
        setIsLoading(false);
      }
    );
  }, [dispatch, currentPage]);

  const handleShowMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <nav className="pt-25 overflow-hidden mx-auto mb-20">
      <div className="flex items-center justify-between mb-0">
        <Link
          to={"/"}
          className="flex gap-4 items-center font-bold text-xl mb-5"
        >
          <AppliedBack />
          Ofislar
        </Link>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
        {office?.length
          ? office?.map((item) => (
              <Link
                key={item?._id}
                className="w-full"
                to={`/office/${item?._id}`}
              >
                <div className="shadow-lg p-2 gap-2">
                  <div className="relative rounded-sm">
                    <img
                      className="w-full h-39 object-cover"
                      src={item?.images.length ? item?.images[0] : fakeimg}
                      alt="'name"
                    />
                  </div>
                  <div className="relative">
                    <div className="flex justify-between mt-4">
                      <h3 className="text-[#323221] text-[13px] not-italic font-bold leading-[normal]">
                        {item?.title}
                      </h3>
                      <p className="text-[#323221] text-[12px] not-italic font-semibold leading-[normal]">
                        {item?.officeType}
                      </p>
                    </div>
                    <div className="flex justify-between mt-4">
                      <h3 className="flex items-center gap-1 text-[#666656] text-[13px] not-italic font-bold leading-[normal]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#000000"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <circle cx="12" cy="10" r="3" />
                          <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                        </svg>{" "}
                        {item?.location}
                      </h3>
                      <p className="text-[#275ccf] text-[14px] not-italic font-semibold leading-[normal]">
                        {item?.price}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))
          : ""}
      </div>
      {currentPage < totalPages && (
        <button
          onClick={handleShowMore}
          className="mt-10 block m-auto p-2 bg-blue-500 text-white rounded"
          disabled={isLoading}
        >
          {isLoading ? "Yuklanmoqda..." : "Ko'proq ko'rish"}
        </button>
      )}
    </nav>
  );
};

export default AllOffice;
