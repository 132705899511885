import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

// Fetch CV files
export const fetchCVFiles = createAsyncThunk(
  "cvFiles/fetchCVFiles",
  async () => {
    const response = await axios.get("/users/resume/cv");
    console.log("fetchCVFiles", response.data);
    return response.data; // Assuming the response contains { data: { cvFiles: [...] } }
  }
);

// Upload CV file
export const uploadCVFile = createAsyncThunk(
  "cvFiles/uploadCVFile",
  async (cvFile) => {
    const formData = new FormData();
    formData.append("cv", cvFile);

    const response = await axios.post("/users/resume/cv", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data; // Assuming the response contains the uploaded CV file information
  }
);

// Delete CV file by id
export const deleteCVFile = createAsyncThunk(
  "cvFiles/deleteCVFile",
  async (id) => {
    const response = await axios.delete(`/users/resume/cv/${id}`);
    return response.data; // Assuming the response contains the updated CV files list
  }
);
