/* eslint-disable react/style-prop-object */
// components/JobsList.js
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import avatar from "../../../assets/img/avatar.png";
import {
  fetchQuickJobs,
  fetchRecentQuickJobs,
} from "../../../store/openApi/openApiThunk";
import quickJobsImg from "../../../assets/img/quickjobs.png";
import { useFetchAllQuickJobsQuery } from "../../../services";
import { useTranslation } from "react-i18next";

const Recomended = () => {
  // const dispatch = useDispatch();
  // const { quickJobs, currentPage, status } = useSelector(
  //   (state) => state.openApi
  // );

  // useEffect(() => {
  //   dispatch(fetchQuickJobs({ page: currentPage, pageSize: 10 }));
  // }, [dispatch, currentPage]);
  const { data: quickJobs } = useFetchAllQuickJobsQuery({
    page: 1,
    pageSize: 10,
  });

  const maskPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
    const maskedPart = "****";
    return phoneNumber.slice(0, -4) + maskedPart;
  };
  /* const formatSalaryRange = (salaryRange) => {
    if (!salaryRange) return "";
    return salaryRange
      .split("-")
      .map((range) => {
        const num = parseInt(range.trim());
        if (num < 1000) return num;
        if (num < 1000000) return `${num / 1000}k`;
        return `${num / 1000000}M`;
      })
      .join(" - ");
  }; */

  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = date.getUTCDate();
    const year = date.getUTCFullYear();

    // Array of month names
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const month = months[date.getUTCMonth()];

    // Add leading zero to day if necessary
    const formattedDay = day < 10 ? `0${day}` : day;

    return `${formattedDay} ${month} ${year}`;
  }

  const { t } = useTranslation();

  return (
    <div className="overflow-hidden mx-auto hero-banner mb-10 mt-10">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-black text-lg font-bold">{t("posts")}</h3>
        <Link
          className="text-blue-600 text-base font-bold"
          to={"/allquickjobs"}
        >
          {t("All")}
        </Link>
      </div>

      <div>
        <ul className="mt-1 grid place-items-center grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-5">
          {quickJobs?.data?.map((job) => (
            <Link
              key={job?._id}
              to={`/quickjobs/${job?._id}`}
              className="rounded-2xl w-full shadow-md h-44 bg-white justify-between flex gap-2"
            >
              <div className="p-4 justify-between flex gap-2">
                <div className=" relative flex flex-col justify-between">
                  <div>
                    <div className="flex justify-between">
                      <h3 className="text-[#242132] text-[14px] text-start not-italic font-semibold leading-[normal]">
                        {job?.title?.substring(0, 20)}...
                      </h3>
                      <p className="m-0 text-[#888] text-[12px] not-italic font-medium leading-[161.023%]">
                        +998{maskPhoneNumber(job?.phoneNumber)}
                      </p>
                    </div>
                    <p className="mt-2 text-[#888] text-[12px] font-medium text-start leading-[161.023%]">
                      {job?.description.substring(0, 50)}...
                    </p>
                  </div>
                  <div className="mt-2 flex justify-between items-center">
                    <p className="text-[#504d4d] text-[12px] font-medium">
                      {formatDate(job?.createdAt)}
                    </p>
                    <p className="text-[#504d4d] text-[12px] font-medium text-end">
                      {t(`${job?.location}`)}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="w-20 h-full">
                  <img className="h-full" src={quickJobsImg} alt="quickjobs" />
                </div>
              </div>
            </Link>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Recomended;
