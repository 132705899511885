import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AppliedBack } from "../../../components/icon";
import { useDispatch } from "react-redux";
import { deleteAccount } from "../../../store/auth/authThunks";
import DeleteAccountModal from "../../../components/modals/DeleteAccountModal";
import PrivacyModal from "../../../components/modals/PrivacyModal";

export const ProfileProtected = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteClick = () => {
    setIsModalOpen(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteAccount());
    setIsModalOpen(false);
  };

  const handleCancelDelete = () => {
    setIsModalOpen(false);
  };

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="mb-10">
      <Link
        to={"/profile"}
        className="flex gap-4 items-center font-bold text-xl"
      >
        <AppliedBack />
        Maxfiylik va xavfsizlik
      </Link>

      <div>
        <ul className="mt-7">
          <li onClick={toggleModal} className="flex cursor-pointer items-center justify-between">
            <div className="flex justify-between gap-5">
              <h3 className="text-[#212121] text-base font-bold">
                Foydalanuvchi kelishuvi
              </h3>
            </div>
            <div className="flex items-center gap-5">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.08325 4.16659L12.9166 9.99992L7.08325 15.8333"
                  stroke="#212121"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </li>
          <li
            onClick={handleDeleteClick}
            className="flex items-center justify-between mt-6 cursor-pointer"
          >
            <div className="flex justify-between gap-5">
              <h3 className="text-[#F75555] text-base font-bold">
                Akkountni o'chirish
              </h3>
            </div>
            <div className="flex items-center gap-5 cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#F75555"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M9 18l6-6-6-6" />
              </svg>
            </div>
          </li>
        </ul>

        <ul className="mt-7 flex flex-col gap-8">
          <Link to={"/profile/protected/phone"} className="">
            <div className="flex justify-center bg-gray-200 p-4 rounded-3xl gap-5">
              <h3 className="text-blue-700 text-base font-bold">
                Telefon raqamni o'zgartirish
              </h3>
            </div>
          </Link>
        </ul>
      </div>
      <PrivacyModal show={showModal} onClose={toggleModal} />

      {isModalOpen && (
        <DeleteAccountModal
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};
