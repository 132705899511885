import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./useCustomQuery";

const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery:baseQueryWithReauth,
  tagTypes: [
    "User",
    "JobSeeker",
    "Employer",
    "Job",
    "QuickJob",
    "Company",
    "Office",
  ],  endpoints: builder => ({}),
})

export default apiSlice