import { createSlice } from "@reduxjs/toolkit";
import {
  fetchContacts,
  addContact,
  updateContact,
  deleteContact,
} from "./contactsThunks"; // Adjust the path according to your structure

const initialState = {
  contacts: [],
  status: "idle",
  error: null,
};

const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchContacts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchContacts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.contacts = action.payload.data; // Adjust based on actual response structure
      })
      .addCase(fetchContacts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addContact.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addContact.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload.data && action.payload.data.contacts) {
          state.contacts.push(action.payload.data.contacts); // Adjust based on actual response structure
        } else {
          state.error = "Failed to add contact";
        }
      })
      .addCase(addContact.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateContact.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateContact.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.contacts.findIndex(
          (contact) => contact.id === action.payload.data.id
        );
        if (index !== -1) {
          state.contacts[index] = action.payload.data.contacts; // Adjust based on actual response structure
        }
      })
      .addCase(updateContact.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteContact.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.contacts = state.contacts.filter(
          (contact) => contact.id !== action.payload.data.id
        ); // Adjust based on actual response structure
      })
      .addCase(deleteContact.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default contactsSlice.reducer;
