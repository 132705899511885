import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import PinInput from "react-pin-input";

const ConfirmationModal = ({ onClose, onSubmit, phoneNumber, dialCode }) => {
  const [code, setCode] = useState("");
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const handleCodeChange = (e) => {
    console.log(e);
    // const { value } = e.target;
    // const newValue = value.replace(/\D/g, "").slice(0, 6);
    setCode(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(code); // Submit the code
  };
  const { t } = useTranslation();

  return createPortal(
    <div className="fixed  z-9999 inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white w-full max-w-[500px] rounded-lg p-8 relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <svg
            class="w-6 h-6 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </button>
        <h2 className="w-full max-w-70 text-center mx-auto mb-5 text-[#000] text-[14px] not-italic font-normal leading-[normal]">
          {`${dialCode}${phoneNumber}`} raqamiga maxsus tasdiqlash kodi yuborildi!
        </h2>
        <form onSubmit={handleSubmit}>
          <PinInput
            length={6}
            initialValue=""
            onChange={handleCodeChange}
            type="numeric"
            inputMode="number"
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              fontWeight:"700",
              fontSize:"24px",
            }}
            inputStyle={{ borderColor: "#EEE", borderRadius: "16px",              backgroundColor:"#FAFAFA"
            }}
            inputFocusStyle={{ borderColor: "#EEE" }}
            onComplete={(value, index) => {}}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
          {/* </div> */}
          <div className="text-[#000] text-center mb-7 text-[14px] not-italic font-normal leading-[normal]">
            Kodni qayta yuborish {seconds} s
          </div>
          <button
            type="submit"
            className="bg-[#4F7BBC] w-full max-w-90 block mx-auto text-white px-4 py-2 rounded"
            disabled={code.length < 6 || seconds === 0}
          >
            {t("Verify")}
          </button>
        </form>
      </div>
    </div>,
    document.body
  );
};

export default ConfirmationModal;
