import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import { Pagination, FreeMode } from "swiper/modules";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo1.jpg";
import { useTranslation } from "react-i18next";

export const LastJobsCard = ({ jobs, status }) => {
  const { t } = useTranslation();
  return (
    <div className="quickjobs lasts">
      {jobs?.length ? (
        <>
          <Swiper
            slidesPerView={1.1} // Initial value for smallest screens
            spaceBetween={20} // Minimum space between slides
            slideToClickedSlide={true}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              1024: {
                slidesPerView: 3.2,
                spaceBetween: 20,
              },
              992: {
                slidesPerView: 2.2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2.2,
                spaceBetween: 20,
              },
              650: {
                slidesPerView: 2.2,
                spaceBetween: 20,
              },
              550: {
                slidesPerView: 1.2, // Show 1.2 slides on smaller screens
                spaceBetween: 10,
              },
              400: {
                slidesPerView: 1.2, // Show 1.2 slides on smaller screens
                spaceBetween: 5,
              },
            }}
            modules={[FreeMode, Pagination]}
          >
            {jobs.map((job) => (
              <SwiperSlide
                className="min-h-[220px]"
                style={{ marginRight: "0 !important" }}
                key={job?._id}
              >
                <Link
                  to={`/jobs/${job?._id}`}
                  className="rounded-2xl bg-white h-full relative border flex flex-col justify-between border-[#d8d1d1] p-4 max-h-50 min-h-[220px]"
                >
                  <div className="w-75 h-full flex flex-col justify-between">
                    <div className="">
                      <div className="">
                        <h3 className="text-black text-start text-lg font-semibold leading-[normal]">
                          {job?.jobTitle?.substring(0, 35)}...
                        </h3>
                      </div>
                      <p className="mt-3 flex items-center justify-between h-5 text-black text-xs font-medium text-start leading-[161.023%]">
                        {job?.company}
                        <p className="m-0 text-[#246BFD] text-lg not-italic font-medium leading-[161.023%]">
                          {t(`${job?.salaryRange}`)}
                        </p>
                      </p>

                      <div className="h-[56px]">
                        <ul className="text-start flex flex-wrap mt-2 gap-1  overflow-hidden">
                          <li className="border border-[#d8d1d1] flex items-center justify-center rounded-md p-1">
                            <span className="text-[#757575] text-xs font-semibold">
                              {t(`${job?.jobType}`)}
                            </span>
                          </li>
                          <li className="border border-[#d8d1d1] flex items-center justify-center rounded-md p-1">
                            <span className="text-[#757575] text-xs font-semibold">
                              {t(`${job?.workingtype}`)}
                            </span>
                          </li>

                          <li className="border border-[#d8d1d1] flex items-center justify-center rounded-md p-1">
                            <span className="text-[#757575] text-xs font-semibold">
                              {t(`${job?.experience}`)}
                            </span>
                          </li>
                          <li className="border border-[#d8d1d1] flex items-center justify-center rounded-md p-1">
                            <span className="text-[#757575] text-xs font-semibold">
                              {t(`${job?.industry}`)}
                            </span>
                          </li>

                          {/* {job?.languagesRequired?.map((item) => (
                              <li
                                key={item}
                                className="border border-[#d8d1d1] flex items-center justify-center rounded-md p-1"
                              >
                                <span className="text-[#757575] text-xs font-semibold">
                                  {t(`${item}`)}
                                </span>
                              </li>
                            ))} */}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between mt-3 items-center">
                    <div className="flex items-center gap-2">
                      <img
                        className="w-8 h-7 rounded-full"
                        src={
                          job?.hr_name === "deleted user"
                            ? logo
                            : job?.hr_avatar
                        }
                        alt="avatar"
                      />
                      <h4 className="text-black text-xs font-semibold">
                        {job?.hr_name}
                      </h4>
                    </div>
                    <p className="m-0 text-[#757575] text-xs font-semibold">
                      {t(`${job?.location}`)}
                    </p>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      ) : (
        ""
      )}
    </div>
  );
};
