export const uzbekistanRegions = [
  "Tashkent city",
  "Tashkent",
  "Samarkand",
  "Bukhara",
  "Navoi",
  "Andijan",
  "Ferghana",
  "Namangan",
  "Khorezm",
  "Surkhandarya",
  "Sirdarya",
  "Djizzakh",
  "Kashkadarya",
  "Karakalpakhistan",
];
