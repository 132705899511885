/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  // BackSingle,
  Candidats,
  HRLocation,
  Jobseekerstar,
  JobseekerstarApply,
} from "../../icon";
import { useDispatch, useSelector } from "react-redux";
import {
  favoriteQuickJobs,
  fetchAllQuickJobsId,
  removeFavoriteQuickJobs,
} from "../../../store/quickJobsApi/quickJobsThunks";
import ApplyQuickJobButton from "./ApplyQuickJobButton";
import { fetchCurrentUser } from "../../../store/usersApi/usersThunks";
import toast from "react-hot-toast";
import io from "socket.io-client";
import { BASE_URL_DOMAIN } from "../../../api/api";
import { useDeleteQuickJobByIdMutation } from "../../../services";
import { useTranslation } from "react-i18next";
const socket = io(BASE_URL_DOMAIN); // Change to your server URL

const maskPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return "";
  const maskedPart = "****";
  return phoneNumber.slice(0, -4) + maskedPart;
};

export const QuickJobsSingle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.users);
  const { quickJobsId } = useSelector((state) => state.quickJobs);
  const [loading, setLoading] = useState(true);
  const [chatRoomId, setChatRoomId] = useState(null);
  const [loadingChatRoom, setLoadingChatRoom] = useState(true);
  const user = currentUser?.data;

  useEffect(() => {
    dispatch(fetchAllQuickJobsId(id)).then((result) => {
      if (result?.payload?.quickJobsId) {
        setLoading(false);
      }
    });
    dispatch(fetchCurrentUser());
  }, [id]);

  useEffect(() => {
    if (user && quickJobsId) {
      const handleChatRoomsResponse = (response) => {
        // console.log("response: ", response);

        if (response.status === 200 && response.data.length > 0) {
          const existingRoom = response.data.find(
            (room) => room.otherUser._id === quickJobsId.createdBy
          );
          if (existingRoom) {
            setChatRoomId(existingRoom._id);
            setLoadingChatRoom(false);
          } else {
            createNewChatRoom();
          }
        } else {
          createNewChatRoom();
        }
      };

      const handleChatRoomCreated = (response) => {
        if (response.status === 200) {
          setChatRoomId(response.chatRoomId);
          setLoadingChatRoom(false);
        }
      };

      const createNewChatRoom = () => {
        socket.emit("createChatRoom", {
          userId: user._id,
          otherUserId: quickJobsId.createdBy,
        });
      };

      socket.emit("requestChatRooms", { userId: user._id });
      socket.on("chatRoomsResponse", handleChatRoomsResponse);
      socket.on("chatRoomCreated", handleChatRoomCreated);

      return () => {
        socket.off("chatRoomsResponse", handleChatRoomsResponse);
        socket.off("chatRoomCreated", handleChatRoomCreated);
      };
    }
  }, [quickJobsId, user]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();

    // Array of month names
    const months = [
      "Yanvar",
      "Fevral",
      "Mart",
      "Aprel",
      "May",
      "Iyun",
      "Iyul",
      "Avgust",
      "Sentabr",
      "Oktabr",
      "Noyabr",
      "Dekabr",
    ];

    const month = months[date.getUTCMonth()];
    // Add leading zero to day if necessary
    const formattedDay = day < 10 ? `0${day}` : day;
    return `${formattedDay} ${month} ${year}`;
  }

  const handleFavortite = () => {
    dispatch(favoriteQuickJobs(id)).then((res) => {
      if (res.payload?.result) {
        toast.success("Sevimlilarga qo'shildi!");
        dispatch(fetchAllQuickJobsId(id));
      }
      if (res.error?.message === "Request failed with status code 401") {
        toast.error("Tizimga kirish talab etiladi!");
      }
      if (res.error?.message === "Failed to refresh token") {
        toast.error("Tizimga kirish talab etiladi!");
      }
      if (!res.payload) {
        dispatch(removeFavoriteQuickJobs(id)).then((res) => {
          // console.log(res, "failed to remove");
          if (res?.payload?.result === "success") {
            toast.success("Sevimlilardan chiqarildi!");
            dispatch(fetchAllQuickJobsId(id));
          }
        });
      }
    });
  };

  const [
    deleteQuickJobById,
    {
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      error: deleteError,
    },
  ] = useDeleteQuickJobByIdMutation();

  useEffect(() => {
    if (isDeleteSuccess) {
      toast.success("Ish muvaffaqqiyatli o'chirildi!");
      navigate("/announ");
    }
  }, [isDeleteSuccess, isDeleteError]);

  const handleDelete = async () => {
    try {
      await deleteQuickJobById(id).unwrap();
    } catch (error) {
      console.error("Error deleting job: ", error);
    }
  };

  function formatJobDescription(input) {
    return input?.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  }

  const handleForward = () => {
    localStorage.setItem("_jobid", id);
  };

  const myId = currentUser?.data._id;
  const hasUserDeleted = quickJobsId?.createdBy?.includes(myId);
  const hasCandidates = quickJobsId?.createdBy?.includes(myId);
  const hasUserFavorite = quickJobsId?.likedBy?.includes(myId);
  const { t } = useTranslation();

  return (
    <div className="mb-10 bg-white mx-auto">
      <div className="shadow-md flex flex-col gap-5">
        <div className="border border-solid px-3 py-3">
          {loading ? (
            <>
              <div className="flex animate-pulse  justify-between items-center mt-6 py-2">
                <h3 className="text-black text-2xl font-semibold leading-[normal]">
                  <div className="h-4.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                </h3>
                <p className="m-0 text-[#246BFD] text-lg not-italic font-medium leading-[161.023%]">
                  <div className="h-4.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                </p>
              </div>
              <div className="flex animate-pulse justify-between mt-3 items-end">
                <svg
                  className="w-10 h-10 me-3 text-gray-200 dark:text-gray-700"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                </svg>
                <div>
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col justify-center py-0 gap-3">
                <div className="flex justify-between items-center">
                  <div className="w-full hidden md:flex  items-center gap-10">
                    {hasUserDeleted ? (
                      <Link to={`/announ/quick/${id}`}>
                        <button
                          className={`shadow-md text-[#f0f5f0] font-bold text-base left-5 bg-[#3776ffc9] rounded-[100px] py-2 px-4 w-full max-w-35`}
                        >
                          {t(`Edit`)}
                        </button>
                      </Link>
                    ) : (
                      <ApplyQuickJobButton quickJobId={quickJobsId} />
                    )}
                    <div className="relative hidden md:block">
                      {hasUserDeleted ? (
                        <button
                          onClick={() => handleDelete()}
                          className={`shadow-md text-white font-bold text-base  bg-red-600 rounded-[100px] w-full py-2 px-4 max-w-45`}
                          type="button"
                        >
                          {t(`Delete`)}
                        </button>
                      ) : (
                        <Link
                          to={loadingChatRoom ? "#" : `/chat/${chatRoomId}`}
                          className={`shadow-md text-center text-white font-bold text-base bg-[#246BFD] rounded-[100px] w-full py-2 px-4 max-w-45         }`}
                          type="button"
                          onClick={
                            loadingChatRoom
                              ? () =>
                                  toast.error("Tizimga kirish talab etiladi")
                              : null
                          }
                        >
                          {t(`Send a message`)}
                        </Link>
                      )}
                    </div>
                  </div>{" "}
                </div>
                <span
                  onClick={handleFavortite}
                  className="relative flex justify-end cursor-pointer"
                >
                  {hasUserFavorite ? <JobseekerstarApply /> : <Jobseekerstar />}
                </span>
                <h3 className="text-black flex justify-between gap-4 items-start text-xl font-semibold leading-[normal]">
                  {quickJobsId?.title}
                  <div className="flex min-w-[80px] gap-4 items-center">
                    <p className="text-[#504d4d] text-[12px] font-medium">
                      {formatDate(quickJobsId?.createdAt)}
                    </p>

                    {/* <JobsFavoriteStar /> */}
                  </div>
                </h3>
              </div>
              <div className="flex justify-between mt-3 gap-3">
                {user ? (
                  <Link
                    onClick={handleForward}
                    to={`/user/information/${quickJobsId?.createdBy}`}
                    className="flex items-center gap-2"
                  >
                    <img
                      className="w-8 h-7 rounded-full"
                      src={quickJobsId?.hr_avatar}
                      alt="avatar"
                    />
                    <h4 className="text-black text-xs font-semibold">
                      {quickJobsId?.hr_name}
                    </h4>
                  </Link>
                ) : (
                  <p className="flex items-center gap-2">
                    <img
                      className="w-8 h-7 rounded-full"
                      src={quickJobsId?.hr_avatar}
                      alt="avatar"
                    />
                    <h4 className="text-black text-xs font-semibold">
                      {quickJobsId?.hr_name?.substring(0, 10)}****
                    </h4>
                  </p>
                )}
                <p className="m-0  flex gap-2 items-center text-[#757575] text-xs font-semibold">
                  <HRLocation />

                  {t(`${quickJobsId?.location}`)}
                </p>
              </div>
            </>
          )}
        </div>
        {hasCandidates ? (
          <Link
            to={`/quickjobs/candidates/${id}`}
            className="border border-solid  px-3 py-3 "
          >
            <div className="flex justify-between items-center py-2">
              <h3 className="text-black flex items-center gap-1 text-[13px] font-normal leading-[normal]">
                <Candidats />
                {quickJobsId?.applicants?.length ? (
                  <span className="ms-7 text-[13px] text-[#06f64e]  ">
                    {quickJobsId?.applicants?.length}
                  </span>
                ) : (
                  <span className="ms-7 text-[13px] text-[#F63106]  ">
                    {quickJobsId?.applicants?.length}
                  </span>
                )}
                {t(`Applicants`)}
              </h3>
              {quickJobsId?.applicants?.length ? (
                <p className="m-0 text-[#06f64e] text-[13px] not-italic font-medium leading-[161.023%]">
                  {t(`There are candidates`)}
                </p>
              ) : (
                <p className="m-0 text-[#F63106] text-[13px] not-italic font-medium leading-[161.023%]">
                  {t(`No Candidates`)}
                </p>
              )}
            </div>
          </Link>
        ) : (
          <p className="border border-solid px-3 py-3 ">
            <div className="flex justify-between items-center py-2">
              <h3 className="text-black flex items-center gap-1 text-[13px] font-normal leading-[normal]">
                <Candidats />
                {quickJobsId?.applicants?.length ? (
                  <span className="ms-7 text-[13px] text-[#06f64e]  ">
                    {quickJobsId?.applicants?.length}
                  </span>
                ) : (
                  <span className="ms-7 text-[13px] text-[#F63106]  ">
                    {quickJobsId?.applicants?.length}
                  </span>
                )}
                {t(`Applicants`)}
              </h3>
              {quickJobsId?.applicants?.length ? (
                <p className="m-0 text-[#06f64e] text-[13px] not-italic font-medium leading-[161.023%]">
                  {t(`There are candidates`)}
                </p>
              ) : (
                <p className="m-0 text-[#F63106] text-[13px] not-italic font-medium leading-[161.023%]">
                  {t(`No Candidates`)}
                </p>
              )}
            </div>
          </p>
        )}
        <div className="border border-solid  px-3 py-3">
          <div className="py-2">
            <h3 className="text-black mb-3 text-2xl font-bold leading-[normal]">
              {t(`Job Description`)}
            </h3>
            {loading ? (
              <p className=" animate-pulse text-base font-medium text-[#424242]">
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </p>
            ) : (
              <p className="text-base font-medium text-[#424242]">
                {formatJobDescription(quickJobsId?.description)}
              </p>
            )}
          </div>
        </div>

        <div className="border border-solid px-3 py-3">
          <div className="flex justify-between">
            <div className="flex items-center gap-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 3L16 3C17.1046 3 18 3.89543 18 5L18 19C18 20.1046 17.1046 21 16 21H8C6.89543 21 6 20.1046 6 19L6 5C6 3.89543 6.89543 3 8 3L9 3M15 3V4C15 5.10457 14.1046 6 13 6H11C9.89543 6 9 5.10457 9 4L9 3M15 3L9 3"
                  stroke="#888888"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              {loading ? (
                <p className="animate-pulse text-base font-medium text-[#424242]">
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                </p>
              ) : (
                <h4 className="text-[#888888] text-base font-bold">
                  +998{maskPhoneNumber(quickJobsId?.phoneNumber)}
                </h4>
              )}
            </div>
            <a
              href={`tel:998${quickJobsId?.phoneNumber}`}
              className="rounded-[30px] bg-[#D8E5FF] py-3 px-5"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 10L20 4M20 4V8M20 4L16 4M16.062 14.2507V14.2507C16.6052 13.5264 17.5886 13.2943 18.3983 13.6992L19.6337 14.3168C20.4404 14.7202 20.8773 15.6135 20.7004 16.4979L20.3216 18.3922C20.1346 19.3271 19.3109 20.0146 18.3636 19.9075C11.1005 19.0859 4.91414 12.8995 4.09253 5.63644C3.98537 4.68912 4.67292 3.86542 5.60777 3.67845L7.50207 3.29959C8.38646 3.12271 9.27982 3.55963 9.68316 4.36632L10.3008 5.60168C10.7057 6.41142 10.4736 7.39483 9.7493 7.93802V7.93802C9.27761 8.2918 8.99002 8.86004 9.21796 9.40381C10.0014 11.2728 12.7272 13.9986 14.5962 14.782C15.14 15.01 15.7082 14.7224 16.062 14.2507Z"
                  stroke="#246BFD"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </a>
          </div>
        </div>
        {user ? (
          <a
            target="_blank"
            href={`https://telegram.me/${quickJobsId?.telegramUsername?.substring(
              1,
              100
            )}`}
            className="border border-solid px-3 py-3"
          >
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="24"
                  height="24"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#29b6f6"
                    d="M24,4C13,4,4,13,4,24s9,20,20,20s20-9,20-20S35,4,24,4z"
                  ></path>
                  <path
                    fill="#fff"
                    d="M34,15l-3.7,19.1c0,0-0.2,0.9-1.2,0.9c-0.6,0-0.9-0.3-0.9-0.3L20,28l-4-2l-5.1-1.4c0,0-0.9-0.3-0.9-1	c0-0.6,0.9-0.9,0.9-0.9l21.3-8.5c0,0,0.7-0.2,1.1-0.2c0.3,0,0.6,0.1,0.6,0.5C34,14.8,34,15,34,15z"
                  ></path>
                  <path
                    fill="#b0bec5"
                    d="M23,30.5l-3.4,3.4c0,0-0.1,0.1-0.3,0.1c-0.1,0-0.1,0-0.2,0l1-6L23,30.5z"
                  ></path>
                  <path
                    fill="#cfd8dc"
                    d="M29.9,18.2c-0.2-0.2-0.5-0.3-0.7-0.1L16,26c0,0,2.1,5.9,2.4,6.9c0.3,1,0.6,1,0.6,1l1-6l9.8-9.1	C30,18.7,30.1,18.4,29.9,18.2z"
                  ></path>
                </svg>

                <h4 className="text-[#888888] text-base font-bold">
                  {t(`Contact_By_Telegram`)}
                </h4>
              </div>
              <p className="rounded-[30px] bg-[#D8E5FF] py-3 px-5">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 10L20 4M20 4V8M20 4L16 4M16.062 14.2507V14.2507C16.6052 13.5264 17.5886 13.2943 18.3983 13.6992L19.6337 14.3168C20.4404 14.7202 20.8773 15.6135 20.7004 16.4979L20.3216 18.3922C20.1346 19.3271 19.3109 20.0146 18.3636 19.9075C11.1005 19.0859 4.91414 12.8995 4.09253 5.63644C3.98537 4.68912 4.67292 3.86542 5.60777 3.67845L7.50207 3.29959C8.38646 3.12271 9.27982 3.55963 9.68316 4.36632L10.3008 5.60168C10.7057 6.41142 10.4736 7.39483 9.7493 7.93802V7.93802C9.27761 8.2918 8.99002 8.86004 9.21796 9.40381C10.0014 11.2728 12.7272 13.9986 14.5962 14.782C15.14 15.01 15.7082 14.7224 16.062 14.2507Z"
                    stroke="#246BFD"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </p>
            </div>
          </a>
        ) : (
          ""
        )}

        {!hasUserDeleted && quickJobsId?.callOnly ? (
          ""
        ) : (
          <div className="relative flex md:hidden justify-between">
            <div className="fixed bottom-5 w-full">
              {hasUserDeleted ? (
                <Link to={`/announ/quick/${id}`}>
                  <button
                    className={`shadow-md text-[#f0f5f0] font-bold text-base left-5 bg-[#3776ffc9] rounded-[100px] py-2 px-4 w-full max-w-35`}
                  >
                    {t(`Edit`)}
                  </button>
                </Link>
              ) : (
                <ApplyQuickJobButton quickJobId={quickJobsId} />
              )}
            </div>
            {hasUserDeleted ? (
              <button
                onClick={() => handleDelete()}
                className={`shadow-md fixed bottom-5 right-5 text-white font-bold text-base  bg-red-600 rounded-[100px] w-full p-2 max-w-45`}
                type="button"
              >
                {t(`Delete`)}
              </button>
            ) : (
              <Link
                to={loadingChatRoom ? "#" : `/chat/${chatRoomId}`}
                className={`shadow-md text-center fixed bottom-5 right-5 text-white font-bold text-base bg-[#246BFD] rounded-[100px] w-full p-2 max-w-45`}
                type="button"
                onClick={
                  loadingChatRoom
                    ? () => toast.error("Tizimga kirish talab etiladi")
                    : null
                }
              >
                {t(`Send a message`)}
              </Link>
            )}
          </div>
        )}
        {hasUserDeleted && quickJobsId?.callOnly ? (
          ""
        ) : (
          <div className="relative flex md:hidden justify-between">
            <div className="fixed bottom-5 w-full">
              {hasUserDeleted ? (
                <Link to={`/announ/quick/${id}`}>
                  <button
                    className={`shadow-md text-[#f0f5f0] font-bold text-base left-5 bg-[#3776ffc9] rounded-[100px] py-2 px-4 w-full max-w-35`}
                  >
                    {t(`Edit`)}
                  </button>
                </Link>
              ) : (
                <ApplyQuickJobButton quickJobId={quickJobsId} />
              )}
            </div>
            {hasUserDeleted ? (
              <button
                onClick={() => handleDelete()}
                className={`shadow-md fixed bottom-5 right-5 text-white font-bold text-base  bg-red-600 rounded-[100px] w-full p-2 max-w-45`}
                type="button"
              >
                {t(`Delete`)}
              </button>
            ) : (
              <Link
                to={loadingChatRoom ? "#" : `/chat/${chatRoomId}`}
                className={`shadow-md text-center fixed bottom-5 right-5 text-white font-bold text-base bg-[#246BFD] rounded-[100px] w-full p-2 max-w-45`}
                type="button"
                onClick={
                  loadingChatRoom
                    ? () => toast.error("Tizimga kirish talab etiladi")
                    : null
                }
              >
                {t(`Send a message`)}
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
