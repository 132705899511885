import React, { useEffect, useState } from "react";
import { ArrowLeftIcon, HeaderFilter } from "../../components/icon";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobs, fetchQuickJobs } from "../../store/openApi/openApiThunk";
import { QuickJobCard } from "../../components/cards/QuickJobCard";
import logo from "../../assets/img/logo1.jpg";
import { useTranslation } from "react-i18next";

const Search = () => {
  const dispatch = useDispatch();
  const { jobs, quickJobs, currentPage, status } = useSelector(
    (state) => state.openApi
  );
  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(fetchJobs({ page: currentPage, pageSize: 10 }));
    dispatch(fetchQuickJobs({ page: currentPage, pageSize: 10 }));
  }, [dispatch, currentPage]);

  useEffect(() => {
    if (id) {
      dispatch(fetchJobs({ jobTitle: id, dispatch }));
      dispatch(
        fetchQuickJobs({
          page: 1,
          pageSize: 10,
          jobTitle: id,
          dispatch,
        })
      );
    }
  }, [id]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value.trim() === "") {
      dispatch(fetchJobs({ page: 1, pageSize: 10 }));
      dispatch(fetchQuickJobs({ page: 1, pageSize: 10 }));
    } else {
      dispatch(fetchJobs({ jobTitle: value.trim(), dispatch }));
      dispatch(
        fetchQuickJobs({
          page: 1,
          pageSize: 10,
          jobTitle: value.trim(),
          dispatch,
        })
      );
    }
  };
  const { t } = useTranslation();
  return (
    <nav className="py-3 overflow-hidden mx-auto">
      <div className="flex items-center gap-3">
        <Link
          to={"/"}
          className="border border-[#F5F5F5] w-10 h-8 flex items-center justify-center rounded-full"
        >
          <ArrowLeftIcon />
        </Link>
        <form className="w-full flex justify-between gap-3" autoComplete="off">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            Search
          </label>
          <div className="relative w-full">
            <input
              type="text"
              name="jobTitle"
              id="default-search"
              value={id ? id : searchTerm}
              onInput={handleInputChange}
              className="block w-full p-4 ps-10 text-sm text-gray-900 border border-[#F5F5F5] rounded-lg bg-white focus:ring-white focus:border-[#F5F5F5]"
              placeholder={t(`Search for a job`)}
            />
            <span className="text-white absolute end-2.5 bottom-2.5 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              <HeaderFilter />
            </span>
          </div>
        </form>
      </div>

      <div className="flex items-center justify-between mb-5 mt-10"></div>

      {quickJobs.length > 0 ? (
        <div>
          <div className="flex items-center justify-between mb-4">
            <h1 className="text-black text-lg font-bold">{t("posts")}</h1>
            <Link
              className="text-blue-600 text-base font-bold"
              to={"/allquickjobs"}
            >
              {t("All")}
            </Link>
          </div>
          <QuickJobCard status={status} quickJobs={quickJobs} />
        </div>
      ) : (
        <p className="text-center text-gray-500 mt-4">{t("Not_Found")}</p>
      )}

      <ul className="mt-10 flex flex-col gap-5">
        {jobs?.length ? (
          <div>
            <div className="flex items-center justify-between mb-5">
              <h1 className="text-black text-lg font-bold">
                {t("Standard_Jobs")}
              </h1>
              <Link
                className="text-blue-600 text-base font-bold"
                to={"/alljobs"}
              >
                {t("All")}
              </Link>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
              {status === "loading" ? (
                <div className="animate-pulse rounded-2xl w-full border border-[#d8d1d1] p-4">
                  <div>
                    <div className="flex justify-between mb-10">
                      <div className="h-4 bg-gray-300 rounded-full dark:bg-gray-700 w-15"></div>
                      <div className="h-4 bg-gray-300 rounded-full dark:bg-gray-700 w-15"></div>
                    </div>
                    <ul className="text-start flex flex-wrap mt-2 gap-1">
                      <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                      <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>

                      <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                      <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                    </ul>
                    <ul className="text-start flex flex-wrap mt-2 gap-1">
                      <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                      <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>

                      <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                      <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                    </ul>
                    <div className="flex items-center mt-4">
                      <svg
                        className="w-10 h-10 me-3 text-gray-200 dark:text-gray-700"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                      </svg>
                      <div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                        <div className="w-48 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                jobs.map((job) => (
                  <Link
                    to={`/jobs/${job?._id}`}
                    key={job?._id}
                    className="rounded-2xl border border-[#d8d1d1] p-4"
                  >
                    <div>
                      <div className="flex justify-between">
                        <h3 className="text-black text-2xl font-semibold leading-[normal]">
                          {job?.jobTitle?.substring(0, 15)}...
                        </h3>
                      </div>
                      <div className="flex  items-center justify-between my-2">
                        <p className="mt-2 h-5 text-black text-xs font-medium text-start leading-[161.023%]">
                          {job?.company}
                        </p>
                        <p className="m-0 text-[#246BFD] text-lg not-italic font-medium leading-[161.023%]">
                          {t(`${job?.salaryRange}`)}
                        </p>
                      </div>

                      <ul className="text-start flex flex-wrap mt-2 gap-1  overflow-hidden">
                        <li className="border border-[#d8d1d1] flex items-center justify-center rounded-md p-1">
                          <span className="text-[#757575] text-xs font-semibold">
                            {t(`${job?.jobType}`)}
                          </span>
                        </li>
                        <li className="border border-[#d8d1d1] flex items-center justify-center rounded-md p-1">
                          <span className="text-[#757575] text-xs font-semibold">
                            {t(`${job?.workingtype}`)}
                          </span>
                        </li>

                        <li className="border border-[#d8d1d1] flex items-center justify-center rounded-md p-1">
                          <span className="text-[#757575] text-xs font-semibold">
                            {t(`${job?.experience}`)}
                          </span>
                        </li>
                        <li className="border border-[#d8d1d1] flex items-center justify-center rounded-md p-1">
                          <span className="text-[#757575] text-xs font-semibold">
                            {t(`${job?.industry}`)}
                          </span>
                        </li>

                        {job?.languagesRequired?.map((item) => (
                          <li
                            key={item}
                            className="border border-[#d8d1d1] flex items-center justify-center rounded-md p-1"
                          >
                            <span className="text-[#757575] text-xs font-semibold">
                              {t(`${item}`)}
                            </span>
                          </li>
                        ))}
                      </ul>

                      <div className="flex justify-between mt-3 items-center">
                        <div className="flex items-center gap-2">
                          <img
                            className="w-8 h-7 rounded-full"
                            src={
                              job?.hr_name === "deleted user"
                                ? logo
                                : job?.hr_avatar
                            }
                            alt="avatar"
                          />
                          <h4 className="text-black text-xs font-semibold">
                            {job?.hr_name}
                          </h4>
                        </div>
                        <p className="m-0 text-[#757575] text-xs font-semibold">
                          {t(`${job?.location}`)}
                        </p>
                      </div>
                    </div>
                  </Link>
                ))
              )}
            </div>
          </div>
        ) : (
          <p className="text-center text-gray-500 mt-4">{t("Not_Found")}</p>
        )}
      </ul>
    </nav>
  );
};

export default Search;
