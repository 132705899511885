// store/authThunks.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Use the configured axios instance
import toast from "react-hot-toast";

// Helper function to set tokens in local storage
export const setTokens = (data) => {
  localStorage.setItem("userData", JSON.stringify(data));
  localStorage.setItem("accessToken", data.accessToken);
  localStorage.setItem("refreshToken", data.refreshToken);
};
// Helper function to clear tokens from local storage
export const clearTokens = () => {
  localStorage.removeItem("userData");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

// Async thunk for logging in (sends a code)
export const login = createAsyncThunk(
  "auth/login",
  async (userData, thunkAPI) => {
    try {
      const response = await axios.post("/auth/sign-in", userData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for code verification during login
export const codeVerify = createAsyncThunk(
  "auth/codeverify",
  async (userData, thunkAPI) => {
    try {
      const response = await axios.post("/auth/sign-in/confirm", userData);
      const { accessToken, refreshToken } = response.data.data;
      setTokens({ accessToken, refreshToken });
      toast.success("Tizimga kirdingiz!");
      return { ...response.data.data, logged: true };
    } catch (error) {
      toast.error("Tasdiqlash kodini to'g'ri kiriting!");
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for code verification during sign up
export const codeVerifySignUp = createAsyncThunk(
  "auth/codeverifySignUp",
  async (userData, thunkAPI) => {
    try {
      const response = await axios.post(
        "/auth/create-user/confirmCode",
        userData
      );
      const { accessToken, refreshToken } = response.data.data;
      setTokens({ accessToken, refreshToken });
      toast.success("Tizimga kirdingiz!");
      return { ...response.data.data, logged: true };
    } catch (error) {
      toast.error("Tasdiqlash kodini to'g'ri kiriting!");
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for registering a new user
export const register = createAsyncThunk(
  "auth/register",
  async (userData, thunkAPI) => {
    try {
      const response = await axios.post("/auth/create-user", userData);
      return { ...response.data, logged: true };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// // Async thunk for signing out
// export const signOut = createAsyncThunk(
//   "auth/signOut",
//   async (mobileToken, thunkAPI) => {
//     try {
//       const response = await axios.post("/auth/sign-out", { mobileToken });
//       clearTokens();
//       toast.success("Tizimdan chiqildi!");
//       return response.data;
//     } catch (error) {
//       toast.error("Tizimdan chiqishda xatolik!");
//       return thunkAPI.rejectWithValue(error.response.data);
//     }
//   }
// );

// Async thunk for signing out
export const signOut = createAsyncThunk(
  "auth/signOut",
  async (mobileToken, thunkAPI) => {
    try {
      const response = await axios.post("/auth/sign-out", {
        mobileToken: "fcmToken",
      });
      console.log("signOut");
      clearTokens();
      toast.success("Tizimdan chiqildi!");
      return response.data;
    } catch (error) {
      toast.error("Tizimdan chiqishda xatolik!");
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for signing out a role
export const signOutRole = createAsyncThunk(
  "auth/signOutRole",
  async (mobileToken, thunkAPI) => {
    try {
      console.log("signOutRole");
      const response = await axios.post("/auth/sign-out", {
        mobileToken: "fcmToken",
      });
      clearTokens();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteAccount = createAsyncThunk(
  "auth/deleteAccount",
  async () => {
    try {
      const response = await axios.delete("/auth/deleteAccount");
      clearTokens();
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
