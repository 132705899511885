import React from "react";

const FileDisplay = ({ message }) => {
  const imageExtensions = /\.(jpeg|jpg|gif|png|bmp|webp)$/i;
  const isImage = (url) => imageExtensions.test(url);

  const getGridStyles = () => {
    switch (message.fileUrls.length) {
      case 1:
        return "grid-cols-1";
      case 2:
        return "grid-cols-2";
      case 3:
        return "grid-cols-2 grid-rows-2";
      case 4:
        return "grid-cols-2 grid-rows-2";
      default:
        return "grid-cols-3 grid-rows-2"; // for 5 or more images
    }
  };

  const getTileStyle = (index) => {
    const length = message.fileUrls.length;
    if (length === 1) return "col-span-1 row-span-1";
    if (length === 2) return "col-span-1 row-span-1";
    if (length === 3) {
      if (index === 0) return "col-span-2 row-span-2"; // first image larger
      return "col-span-1 row-span-1";
    }
    if (length === 4) return "col-span-1 row-span-1";
    if (length >= 5) {
      if (index === 0) return "col-span-3 row-span-2"; // first image larger
      return "col-span-1 row-span-1";
    }
    return "col-span-1 row-span-1";
  };

  return (
    <div className={`grid gap-2 ${getGridStyles()}`}>
      {message.fileUrls?.map((fileUrl, index) => (
        <div key={index} className={`relative ${getTileStyle(index)}`}>
          {isImage(fileUrl.url) ? (
            <div className="relative h-full">
              <img
                src={fileUrl.url}
                alt="uploaded"
                className="w-full h-full object-cover rounded-md"
              />
              <div className="absolute rounded-full px-1 right-2 bottom-2 bg-slate-400 flex items-center gap-2 text-xs font-medium text-black">
                {new Date(message.timestamp).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                })}
              </div>
            </div>
          ) : (
            <div className="flex justify-between items-end gap-2 p-2 text-base bg-gray-100 rounded-md h-full">
              <p className="flex items-center gap-3 justify-between">
                {fileUrl.name?.split("/").pop()}
                <a href={fileUrl.url} download>
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M13 11.15V4a1 1 0 1 0-2 0v7.15L8.78 8.374a1 1 0 1 0-1.56 1.25l4 5a1 1 0 0 0 1.56 0l4-5a1 1 0 1 0-1.56-1.25L13 11.15Z"
                      clip-rule="evenodd"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M9.657 15.874 7.358 13H5a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-2.358l-2.3 2.874a3 3 0 0 1-4.685 0ZM17 16a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H17Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
              </p>
              <div className="ml-1 flex items-center gap-2 text-xs font-medium text-gray-600">
                {new Date(message.timestamp).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                })}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FileDisplay;
