import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppliedBack } from "../../components/icon";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/img/logo1.jpg";
import { fetchJobs } from "../../store/openApi/openApiThunk";
import { useTranslation } from "react-i18next";

export const AllJobs = () => {
  const dispatch = useDispatch();
  const { totalPages } = useSelector((state) => state.openApi);
  const [currentPage, setCurrentPage] = useState(1);
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchJobs({ page: currentPage, pageSize: 10 })).then((action) => {
      const fetchedJobs = action?.payload?.jobs;
      if (Array.isArray(fetchedJobs)) {
        setJobs((prevJobs) => [...prevJobs, ...fetchedJobs]);
      }
      setIsLoading(false);
    });
  }, [dispatch, currentPage]);

  const handleShowMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
  const { t } = useTranslation();

  return (
    <div className="mb-10">
      <Link to={"/"} className="flex gap-4 items-center font-bold text-xl">
        <AppliedBack />
      {t("All jobs")}
      </Link>

      <ul className="mt-10 grid place-items-center grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
        {jobs.map((job) => (
          <Link
            key={job?._id}
            to={`/jobs/${job?._id}`}
            className="rounded-2xl bg-white min-h-[200px] w-full relative border flex flex-col justify-between border-[#d8d1d1] p-4 max-h-54 "
          >
            <div className="flex flex-col h-50 justify-between">
              <div>
                <h3 className="text-black text-start text-xl font-semibold leading-[normal]">
                  {job?.jobTitle}
                </h3>
                <p className="mt-3 flex items-center justify-between h-5 text-black text-xs font-medium text-start leading-[161.023%]">
                  {job?.company}
                  <span className="m-0 text-[#246BFD] text-lg not-italic font-medium leading-[161.023%]">
                    {t(`${job?.salaryRange}`)}
                  </span>
                </p>

                <ul className="text-start flex flex-wrap mt-2 gap-1">
                  <li className="border border-[#d8d1d1] flex items-center justify-center rounded-md p-1">
                    <span className="text-[#757575] text-xs font-semibold">
                      {t(`${job?.jobType}`)}
                    </span>
                  </li>
                  <li className="border border-[#d8d1d1] flex items-center justify-center rounded-md p-1">
                    <span className="text-[#757575] text-xs font-semibold">
                      {t(`${job?.workingtype}`)}
                    </span>
                  </li>
                  <li className="border border-[#d8d1d1] flex items-center justify-center rounded-md p-1">
                    <span className="text-[#757575] text-xs font-semibold">
                      {t(`${job?.experience}`)}
                    </span>
                  </li>
                  <li className="border border-[#d8d1d1] flex items-center justify-center rounded-md p-1">
                    <span className="text-[#757575] text-xs font-semibold">
                      {t(`${job?.industry}`)}
                    </span>
                  </li>
                  <li className="border border-[#d8d1d1] flex items-center justify-center rounded-md p-1">
                    <span className="text-[#757575] text-xs font-semibold">
                      {t(`${job?.jobStatus}`)}
                    </span>
                  </li>

                  {/* {job?.languagesRequired?.map((item) => (
                    <li
                      key={item}
                      className="border border-[#d8d1d1] flex items-center justify-center rounded-md p-1"
                    >
                      <span className="text-[#757575] text-xs font-semibold">
                        {t(`${item}`)}
                      </span>
                    </li>
                  ))} */}
                </ul>
              </div>
              <div className="flex justify-between mt-3 items-center">
                <div className="flex items-center gap-2">
                  <img
                    className="w-8 h-7 rounded-full"
                    src={
                      job?.hr_name === "deleted user" ? logo : job?.hr_avatar
                    }
                    alt="avatar"
                  />
                  <h4 className="text-black text-xs font-semibold">
                    {job?.hr_name}
                  </h4>
                </div>
                <p className="m-0 text-[#757575] text-xs font-semibold">
                  {t(`${job?.location}`)}
                </p>
              </div>
            </div>
          </Link>
        ))}
      </ul>

      {currentPage < totalPages && jobs.length > 0 && (
        <button
          onClick={handleShowMore}
          className="mt-10 block mx-auto p-2 bg-blue-500 text-white rounded"
          disabled={isLoading}
        >
          {isLoading ? "Yuklanmoqda..." : "Ko'proq ko'rish"}
        </button>
      )}
    </div>
  );
};
