import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

// Fetch skills
export const fetchSkills = createAsyncThunk("skills/fetchSkills", async () => {
  const response = await axios.get("/users/resume/skills");
  console.log("fetchSkills", response.data);
  return response.data; // Assuming the response contains { data: { skills: [...] } }
});

// Add skills
export const addSkills = createAsyncThunk(
  "skills/addSkills",
  async (skills) => {
    console.log("addSkills", skills);
    const response = await axios.post("/users/resume/skills", { skills });
    return response.data; // Assuming the response contains the added skills
  }
);

// Update skills by id
export const updateSkills = createAsyncThunk(
  "skills/updateSkills",
  async ({ id, skills }) => {
    const response = await axios.put(`/users/resume/skills/${id}`, {
      skills,
    });
    return response.data; // Assuming the response contains the updated skills
  }
);

// Delete skills by id
export const deleteSkills = createAsyncThunk(
  "skills/deleteSkills",
  async (id) => {
    const response = await axios.delete(`/users/resume/skills/${id}`);
    return response.data; // Assuming the response contains the updated skills list
  }
);
