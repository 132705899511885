import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";
import ConfirmationModal from "../../components/modals/ConfirmationModal"; // Import the modal component
import toast from "react-hot-toast";
import UAParser from "ua-parser-js";
import axios from "axios";
import login_bg from "../../assets/img/auth-bg.webp";
import { useTranslation } from "react-i18next";
import CountrySelector from "../../components/CountrySelect";

const SignIn = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState("");
  const [location, setLocation] = useState("");
  const [dialCode, setDialCode] = useState("+93");
  const { isLoading } = useSelector((state) => state.auth);
  const { userLogin, userCodeVerify } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const parser = new UAParser();
    const result = parser.getResult();
    setDeviceInfo(result);

    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        setLocation(response.data);
      })
      .catch((error) => {
        console.error("Error fetching location data:", error);
      });
  }, []);

  const handleChange = (event) => {
    const { value } = event.target;
    const onlyNumbers = value.replace(/\D/g, "");
    setPhoneNumber(onlyNumbers);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (phoneNumber === "") {
      toast.error(t("Enter_Phone_Number"));
      return;
    }
    userLogin({ phoneNumber: `${dialCode}${phoneNumber}` })
      .then(() => {
        toast.success(t("Code has been send to"));
        setIsModalOpen(true);
      })
      .catch((error) => {
        if (error.msg === "User not found") {
          toast.error("Foydalanuvchi topilmadi!");
        }
        return error;
      });
  };

  const handleCodeSubmit = (code) => {
    userCodeVerify({
      phoneNumber: `${dialCode}${phoneNumber}`,
      confirmationCode: code,
      mobileToken: "fcmToken",
      deviceId: "",
      deviceName: "",
      region: location?.region,
      os: JSON.stringify(deviceInfo?.os),
      browser: deviceInfo?.browser?.name,
      ip: location?.ip,
    })
      .then((res) => {
        navigate("/"); // Navigate to the dashboard or desired route
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const { t } = useTranslation();

  return (
    <div
      style={{ backgroundImage: `url(${login_bg})` }}
      className="overflow-x-hidden h-screen bg-no-repeat bg-cover bg-[center_center] object-cover"
    >
      <div className="absolute z-99 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-full max-w-[500px]">
        <h3 className="text-[#000] text-[32px] not-italic font-normal leading-[91.5%] text-center">
          Topish AI
        </h3>
        <div className="mt-12 overflow-hidden bg-[#fff] py-6 px-10  my-[0] rounded-2xl">
          <h3 className="text-[#707070] text-xl not-italic font-normal leading-[140%] text-center">
            Profilga kirish
          </h3>

          <form className="w-full mt-6" onSubmit={handleSubmit}>
            <div className="relative flex items-center">
              <div className=" start-0 top-0 flex gap-2 items-center ps-3.5 ">
                <CountrySelector onChange={setDialCode} />
              </div>
              <input
                className="rounded-2xl outline-none ms-2 w-full border-[0.5px] border-solid border-[#E5E5E5] bg-[#FFF] focus:border-transparent focus:outline-none"
                value={phoneNumber}
                onChange={handleChange}
                type="text"
                placeholder="Telefon raqam"
              />
            </div>

            <button
              disabled={isLoading}
              type="submit"
              className="rounded-[12px] my-5 bg-[#4F7BBC] text-[#FFF] text-[20px] not-italic font-normal leading-[91.5%] w-full py-2"
            >
              {t("Sign in")}
            </button>
          </form>
          <div className="w-full flex justify-center gap-2 mt-9">
            <p className="text-[#000] text-[14px] not-italic font-normal leading-[normal]">
              Hali profil mavjud emassmi?
            </p>
            <Link
              className="text-[#000] text-[14px] not-italic font-normal leading-[normal]"
              to={"/signup-role"}
            >
              {t("Sign up")}
            </Link>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ConfirmationModal
          dialCode={dialCode}
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleCodeSubmit}
          phoneNumber={phoneNumber}
        />
      )}
      <div className="absolute bg-[rgba(223,_223,_255,_0.90)] w-full h-full top-0"></div>
    </div>
  );
};

export default SignIn;
