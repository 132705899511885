import { Logo } from "./icon";
import NavLinks from "./NavLinks";
import Wrapper from "../assets/wrappers/BigSidebar";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import playMarket from "../assets/img/play-market.jpg";
import appStore from "../assets/img/app-store.jpg";
const BigSidebar = () => {
  const { isSidebarOpen } = useSelector((store) => store.users);

  return (
    <Wrapper className="wrapper relative">
      <div
        className={
          isSidebarOpen
            ? "sidebar-container "
            : "sidebar-container show-sidebar"
        }
      >
        <div className="content h-screen relative">
          <header>
            <Logo />
          </header>
          <NavLinks />
          <div className="flex-col absolute bottom-8 items-center mt-8">
            <div className="sticky top-[10px] py-5 px-4">
              <h3 className="text-[#2F94CD] text-base text-center not-italic font-semibold">
                Ro’yxatdan o’tganmisiz?
              </h3>
              <ul className="mt-3 flex gap-2">
                <Link
                  to={"/profile"}
                  className="text-[#5081AF] w-full text-center border border-[#5081AF] inline-block py-2 px-2 rounded-md text-[13px] font-semibold"
                >
                  Profilga Kirish
                </Link>
                <Link
                  to={"/"}
                  className="text-[#C0B75B] w-full text-center border border-[#C0B75B] inline-block py-2 px-2 rounded-md text-[13px] font-semibold"
                >
                  CV Yaratish
                </Link>
              </ul>
            </div>

            <div className="mt-5">
              <h3 className="text-[#2F94CD] text-base text-center not-italic font-semibold">
                Mobil ilovani yuklab olish
              </h3>
              <div className="flex flex-col justify-center items-center gap-2 mt-2">
                <img
                  className="rounded-lg w-30 h-10"
                  src={playMarket}
                  alt="Play Market"
                />
                <img
                  className="rounded-lg w-30 h-10"
                  src={appStore}
                  alt="App Store"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default BigSidebar;
