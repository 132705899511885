import React from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  applyForQuickJob,
  fetchAllQuickJobsId,
} from "../../../store/quickJobsApi/quickJobsThunks";
import { useTranslation } from "react-i18next";

const ApplyQuickJobButton = ({ quickJobId }) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.users);
  const handleApply = () => {
    dispatch(applyForQuickJob(quickJobId?._id))
      .then((res) => {
        console.log(res);
        if (res.meta.requestStatus === "fulfilled") {
          toast.success("Muvaffaqqiyatli yuborildi");
          dispatch(fetchAllQuickJobsId(quickJobId?._id));
        } else if (
          res.error.message === "Request failed with status code 401"
        ) {
          toast.error("Tizimga kirish talab etiladi");
        } 
        else if (
          res.error.message === "Failed to refresh token"
        ) {
          toast.error("Tizimga kirish talab etiladi");
        }
        else if (
          res.error.message === "Request failed with status code 400"
        ) {
          toast.error("Siz oldin yuborgansiz");
        }
      })
      .catch((err) => {
        toast.error("Xatolik yuz berdi");
      });
  };

  const userID = currentUser?.data._id;
  const hasUserApplied = quickJobId?.applicants?.includes(userID);
  const { t } = useTranslation();

  return (
    <div>
      <button
        onClick={handleApply}
        className={`shadow-md text-[${
          hasUserApplied ? "#B88C1A" : "#246BFD"
        }] font-bold text-base left-5 bg-[${
          hasUserApplied ? "#D8E5FF" : "#D8E5FF"
        }] rounded-[100px] py-2 px-4 w-full max-w-35`}
      >
        {hasUserApplied ? t(`Applied`) : t(`Apply`)}
        </button>
    </div>
  );
};

export default ApplyQuickJobButton;
