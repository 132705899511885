// store/jobsApi/quickJobsThunks.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

export const fetchAllQuickJobs = createAsyncThunk(
  "quickJobsApi/fetchAllQuickJobs",
  async (filters) => {
    const response = await axios.get("/quickjobs", {
      params: {
        page: 1,
        limit: 10,
      }, // Spread the filters object to include all filter parameters
    });
    return {
      quickJobs: response.data.data, // Adjust based on actual response structure
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
      totalDocuments: response.data.pagination.totalDocuments,
    };
  }
);

export const fetchQuickJobsMe= createAsyncThunk(
  "quickJobsApi/fetchQuickJobsMe",
  async (filters) => {
    const response = await axios.get("/quickjobs/myJobs", {
      params: {
        page: 1,
        limit: 10,
      }, // Spread the filters object to include all filter parameters
    });
    return {
      quickJobsMe: response.data.data, // Adjust based on actual response structure
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
      totalDocuments: response.data.pagination.totalDocuments,
    };
  }
);

export const fetchAllQuickJobsId = createAsyncThunk(
  "quickJobsApi/fetchAllQuickJobsId",
  async (id) => {
    const response = await axios.get(`/quickjobs/${id}`);
    return {
      quickJobsId: response.data.data, // Adjust based on actual response structure
    };
  }
);

export const createQuickJob = createAsyncThunk(
  "quickJobsApi/createQuickJob",
  async (newJob) => {
    const response = await axios.post("/quickjobs", newJob);
    return response.data; // Adjust based on actual response structure
  }
);

export const searchQuickJobs = createAsyncThunk(
  "quickJobsApi/searchQuickJobs",
  async (filters) => {
    const response = await axios.get("/quickjobs/search", {
      params: filters, // Spread the filters object to include all filter parameters
    });
    return {
      searchQuickJobs: response.data.data, // Adjust based on actual response structure
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
      totalDocuments: response.data.pagination.totalDocuments,
    };
  }
);

export const updateQuickJobById = createAsyncThunk(
  "quickJobsApi/updateQuickJobById",
  async ({ id, updatedJob }) => {
    const response = await axios.patch(`/quickjobs/${id}`, updatedJob);
    return response.data.data; // Adjust based on actual response structure
  }
);

export const deleteQuickJobById = createAsyncThunk(
  "quickJobsApi/deleteQuickJobById",
  async (id) => {
    await axios.delete(`/quickjobs/${id}`);
    return id; // Return the deleted job ID
  }
);

export const applyForQuickJob = createAsyncThunk(
  "quickJobsApi/applyForQuickJob",
  async (jobID) => {
    const response = await axios.post(`/quickjobs/${jobID}/apply`);
    return response.data; // Adjust based on actual response structure
  }
);

export const fetchEmployerPosts = createAsyncThunk(
  "quickJobsApi/fetchEmployerPosts",
  async ({ page = 1, limit = 10 }) => {
    const response = await axios.get("/quickjobs/myJobs", {
      params: { page, limit },
    });
    return {
      employerPosts: response.data.data, // Adjust based on actual response structure
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
      totalDocuments: response.data.pagination.totalDocuments,
    };
  }
);

export const favoriteQuickJobs = createAsyncThunk(
  "usersApi/favoriteQuickJobs",
  async (id) => {
    const response = await axios.post(`/users/favoriteQuickJob/${id}`);
    return response.data;
  }
);

export const removeFavoriteQuickJobs = createAsyncThunk(
  "usersApi/removeFavoriteQuickJobs",
  async (id) => {
    const response = await axios.delete(`/users/favoriteJob/${id}`);
    return response.data;
  }
);

export const fetchCandidates = createAsyncThunk(
  "usersApi/fetchCandidates",
  async (filters) => {
    const response = await axios.get(`/quickjobs/myJobs/${filters.id}/applicants`, {
      params: filters,
    });
    console.log(response.data.data);
    return {
      candidates: response.data.data, // Adjust based on actual response structure
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
    };
  }
);