import React from "react";
import "./Modal.css"; // Import your CSS file for styling

const PrivacyModal = ({ show, onClose }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <iframe
          src="https://topish.xyz/api/v1/privatePolicy"
          width="100%"
          height="500px"
          title="Privacy Policy"
        ></iframe>
      </div>
    </div>
  );
};

export default PrivacyModal;
