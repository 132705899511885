import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentUser } from "../../../store/usersApi/usersThunks";
import { updateCompany } from "../../../store/companyApi/companyThunk";
import { Link } from "react-router-dom";
import { AppliedBack } from "../../../components/icon";
import toast from "react-hot-toast";
import { uzbekistanRegions } from "../../../constants/Region";
import { fieldsEmployer } from "../../../constants/FieldsEmployer";
import { useTranslation } from "react-i18next";

export const EditCompany = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.users);
  const [company, setCompany] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);

  const sizes = ["1-10", "11-50", "51-200", "201-500"];
  const weekDays = [
    "Dushanba",
    "Seshanba",
    "Chorshanba",
    "Payshanba",
    "Juma",
    "Shanba",
    "Yakshanba",
  ];

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, [dispatch]);

  useEffect(() => {
    setCompany(currentUser?.data.company);
  }, [currentUser]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.includes("info.")) {
      const key = name.split(".")[1];
      setCompany({ ...company, info: { ...company.info, [key]: value } });
    } else {
      setCompany({ ...company, [name]: value });
    }
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setCompany({ ...company, [name]: value });
  };

  const handleSwitchChange = () => {
    setCompany({ ...company, overtime: !company.overtime });
  };

  const handleDateChange = (date) => {
    setCompany({ ...company, date_of_establishment: date });
  };

  const handleSubmit = async () => {
    const body = {
      name: company?.name,
      description: company?.description,
      location: company?.location,
      type: company?.type,
      working_time: company?.working_time,
      working_days: company?.working_days,
      overtime: company?.overtime,
      info: {
        legal_representative: company?.info.legal_representative,
        registration_capital: company?.info.registration_capital,
        date_of_establishment: company?.date_of_establishment,
      },
      size: company?.size,
    };

    dispatch(updateCompany({ id: company?._id, body })).then((res) => {
      if (res.payload.result === "success") {
        toast.success("Ma'lumotlar yangilandi!");
      }
    });
  };

  const handleDaySelect = (e) => {
    const { value, checked } = e.target;
    setSelectedDays((prev) =>
      checked ? [...prev, value] : prev.filter((day) => day !== value)
    );
  };

  const handleModalSave = () => {
    if (selectedDays.length > 1) {
      setCompany({
        ...company,
        working_days: `${selectedDays[0]}dan ${
          selectedDays[selectedDays.length - 1]
        }gacha`,
      });
    }
    setShowModal(false);
  };

  const { t } = useTranslation();

  return (
    <div className="mx-auto bg-white shadow-md rounded-md mb-10 p-5">
      <div className="flex justify-between mb-5">
        <Link
          to={"/profile/company"}
          className="flex gap-4 items-center font-bold text-xl"
        >
          <AppliedBack />
          <p>Kompaniya akkountni tahrirlash</p>
        </Link>
      </div>{" "}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Nomi</label>
        <input
          type="text"
          name="name"
          value={company.name || ""}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Tavsifi
        </label>
        <textarea
          name="description"
          value={company.description || ""}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        ></textarea>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Manzil
        </label>
        <select
          name="location"
          value={company.location || ""}
          onChange={handleSelectChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          {uzbekistanRegions.map((region) => (
            <option key={region} value={region}>
              {t(`${region}`)}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Turi</label>
        <select
          name="type"
          value={company.type || ""}
          onChange={handleSelectChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          {fieldsEmployer.map((type) => (
            <option key={type} value={type}>
              {t(`${type}`)}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Ish vaqti
        </label>
        <input
          type="text"
          name="working_time"
          value={company.working_time || ""}
          onChange={handleInputChange}
          onFocus={(e) => {
            e.target.type = "time";
            e.target.focus();
          }}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Ish kuni
        </label>
        <input
          type="text"
          name="working_days"
          value={company.working_days || ""}
          readOnly
          onClick={() => setShowModal(true)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm cursor-pointer"
        />
      </div>
      <div className="mb-4 flex items-center">
        <label className="block text-sm font-medium text-gray-700 mr-2">
          Overtime
        </label>
        <button
          onClick={handleSwitchChange}
          className={`relative inline-flex items-center h-6 rounded-full w-11 focus:outline-none ${
            company.overtime ? "bg-blue-600" : "bg-gray-200"
          }`}
        >
          <span
            className={`transform transition ease-in-out duration-200 ${
              company.overtime ? "translate-x-6" : "translate-x-1"
            } inline-block w-4 h-4 bg-white rounded-full`}
          ></span>
        </button>
        <span className="ml-3 text-sm text-gray-600">
          {company.overtime ? "Overtime" : "No Overtime"}
        </span>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Yuridik vakil{" "}
        </label>
        <input
          type="text"
          name="info.legal_representative"
          value={company.info?.legal_representative || ""}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Ro'yxatdan o'tish kapitali{" "}
        </label>
        <input
          type="text"
          name="info.registration_capital"
          value={company.info?.registration_capital || ""}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Tashkil etilgan sana{" "}
        </label>
        <input
          type="date"
          name="date_of_establishment"
          value={company.date_of_establishment || ""}
          onChange={(e) => handleDateChange(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Size</label>
        <select
          name="size"
          value={company.size || ""}
          onChange={handleSelectChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          {sizes.map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </div>
      <div className="mt-6">
        <button
          onClick={handleSubmit}
          className="px-4 py-2 w-full bg-blue-600 text-white rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Tahrirlash
        </button>
      </div>
      {showModal && (
        <div className="fixed  z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-30 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block w-full align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Ish kunini tanlang
                  </h3>
                  <div className="mt-2">
                    {weekDays?.map((day) => (
                      <div key={day} className="flex items-center mb-2">
                        <input
                          type="checkbox"
                          value={day}
                          checked={selectedDays.includes(day)}
                          onChange={handleDaySelect}
                          className="mr-2"
                        />
                        <label className="block text-sm font-medium text-gray-700">
                          {day}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  onClick={handleModalSave}
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
