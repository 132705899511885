import React from "react";
import { Category } from "./category/Category";
import Office from "./office/Office";
import Recomended from "./recomended/Recomended";
import Experienced from "./experienced/Experienced";

export const EmployerHome = () => {
  return (
    <div className="">
      <Category />
      <Office />
      <Recomended />
      <Experienced/>
    </div>
  );
};
