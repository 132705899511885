import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/api"; // Adjust the path according to your structure


export const fetchUserAvatar = createAsyncThunk(
  "avatar/fetchUserAvatar",
  async () => {
    const response = await api.get("/users/avatar");

    return response.data; // Assuming the response contains { avatar: 'avatar_url' }
  }
);
export const uploadUserAvatar = createAsyncThunk(
  "avatar/uploadUserAvatar",
  async (file, { dispatch }) => {
    const formData = new FormData();
    formData.append("avatar", file);

    const response = await api.post("/users/avatar", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    // Fetch the updated avatar
    dispatch(fetchUserAvatar());

    return response.data; // Assuming the response contains { message: 'Avatar uploaded successfully', url: 'avatar_url' }
  }
);



export const updateUserAvatar = createAsyncThunk(
  "avatar/updateUserAvatar",
  async (file, { dispatch }) => {
    const formData = new FormData();
    formData.append("avatar", file);

    const response = await api.put("/users/avatar", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    // Fetch the updated avatar
    dispatch(fetchUserAvatar());

    return response.data; // Assuming the response contains { message: 'Avatar updated successfully', url: 'avatar_url' }
  }
);

export const deleteUserAvatar = createAsyncThunk(
  "avatar/deleteUserAvatar",
  async () => {
    const response = await api.delete("/users/avatar");

    return response.data; // Assuming the response contains { message: 'Avatar deleted successfully' }
  }
);
