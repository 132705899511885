import React, { useState } from "react";
import yellowEllips from "../../assets/img/yellow-ellips.png";
import blueEllips from "../../assets/img/blue-ellips.png";
import "./AdvancedSearch.css";
import Select from "react-select";

const options = [
  { value: "navaiy", label: "Navoiy" },
  { value: "tashkent", label: "Tashkent" },
  { value: "samarkand", label: "Samarkand" },
  // Add more options as needed
];

const customStyles = {
  control: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    borderColor: "#D1D5DB",
    borderRadius: "0.5rem",
    padding: "0.25rem",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#D1D5DB",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0",
    paddingLeft: "1.75rem", // Adjust padding to align text properly
  }),
  placeholder: (provided) => ({
    ...provided,
    marginLeft: "0",
  }),
  singleValue: (provided) => ({
    ...provided,
    marginLeft: "0",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "0",
  }),
  input: (provided) => ({
    ...provided,
    marginLeft: "0",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

export const AdvancedSearch = () => {
  const [selected, setSelected] = useState("");
  const handleSelect = (option) => {
    setSelected(option);
  };
  const [selectedCurrency, setSelectedCurrency] = useState("UZS");
  const dataUZS = [
    "Kelishiladi",
    "0 - 10 000",
    "1 00 000 - 3 000 000",
    "6 000 000 - 10 000 000",
    "100 000 - 1 000 000",
    "10 000 000",
  ];
  const dataUSD = [
    "Negotiable",
    "0 - 10",
    "100 - 300",
    "600 - 1000",
    "10 - 100",
    "1000",
  ];

  const handleCurrencyChange = (currency, e) => {
    e.preventDefault();
    setSelectedCurrency(currency);
  };
  return (
    <div className="xl:h-230 lg:h-230 md:h-203 h-203  relative   bg-no-repeat mb-10">
      <div className="">
        <h3 className="text-[#2D4064] mb-6 xl:text-[46px] lg:text-[40px] md:text-[35px] text-[30px] not-italic font-semibold leading-[normal]">
          Kengaytirilgan Qidiruv
        </h3>

        <form className="">
          <input
            className="w-full hero-input py-3 xl:max-w-125 lg:max-w-125 md:max-w-125 max-w-full rounded-3xl outline-none"
            placeholder="Kasb, Kompaniya yoki ish nomini yozing"
            type="text"
          />

          <div className="mt-4 w-full  max-w-230 grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-5">
            <div className="w-full xl:max-w-[370px] lg:max-w-[370px] md:max-w-[370px] max-w-full">
              <h3 className="text-[#212121] text-[20px] not-italic font-bold leading-[120%]">
                Manzil va Maosh
              </h3>
              <div className="relative py-4">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                  <svg
                    className="text-gray-400"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.91406 8.59846C2.91406 4.76523 6.1173 1.66699 9.99193 1.66699C13.8775 1.66699 17.0807 4.76523 17.0807 8.59846C17.0807 10.5301 16.3782 12.3233 15.222 13.8433C13.9464 15.5199 12.3742 16.9807 10.6045 18.1273C10.1995 18.3923 9.83396 18.4123 9.38944 18.1273C7.60968 16.9807 6.03747 15.5199 4.77281 13.8433C3.61572 12.3233 2.91406 10.5301 2.91406 8.59846ZM7.65925 8.81428C7.65925 10.0984 8.70711 11.1084 9.99193 11.1084C11.2776 11.1084 12.3355 10.0984 12.3355 8.81428C12.3355 7.54015 11.2776 6.48102 9.99193 6.48102C8.70711 6.48102 7.65925 7.54015 7.65925 8.81428Z"
                      fill="#212121"
                    />
                  </svg>{" "}
                </div>
                <Select
                  options={options}
                  styles={customStyles}
                  placeholder="Viloyatni tanlang"
                  className="pl-10"
                  isSearchable={false} // Disable text input
                  components={{ IndicatorSeparator: () => null }} // Remove default indicator separator
                />
              </div>
              <div className="flex xl:flex-row lg:flex-row md:flex-row flex-col gap-2 items-center justify-between">
                <ul className="grid w-full xl:max-w-[300px] lg:max-w-[300px] md:max-w-[300px] max-w-full grid-cols-2 gap-2">
                  {(selectedCurrency === "UZS" ? dataUZS : dataUSD).map(
                    (item, index) => (
                      <li
                        key={index}
                        onClick={() => handleSelect(item)}
                        className={`rounded-[22px] w-full py-2 px-1 text-center text-[13px] not-italic font-normal leading-[140%] cursor-pointer ${
                          selected === item
                            ? "border-2 border-blue-500"
                            : "bg-[#F7F7F7] text-[#000]"
                        } border-2`}
                        // className="rounded-[22px] w-full py-2 px-1 bg-[#F7F7F7] text-[#000] text-center text-[13px] not-italic font-normal leading-[140%]"
                      >
                        {item}
                      </li>
                    )
                  )}
                </ul>
                <div className="flex xl:flex-col lg:flex-col md:flex-col flex-row gap-2 w-full max-w-11">
                  <button
                    onClick={(e) => handleCurrencyChange("UZS", e)}
                    className={`rounded-[22px] px-3 py-1 text-center text-[13px] not-italic font-normal leading-[140%] ${
                      selectedCurrency === "UZS"
                        ? "bg-[#FFEE57] text-[#000]"
                        : "bg-[#FFEE57] text-[#000]"
                    }`}
                  >
                    UZS
                  </button>
                  <button
                    onClick={(e) => handleCurrencyChange("USD", e)}
                    className={`rounded-[22px] px-3 py-1 text-center text-[13px] not-italic font-normal leading-[140%] ${
                      selectedCurrency === "USD"
                        ? "bg-[#246BFD] text-[#fff]"
                        : "bg-[#246BFD] text-[#fff]"
                    }`}
                  >
                    USD
                  </button>
                </div>
              </div>
              <div className="mt-6">
                <h3 className="text-[#212121] text-[20px] not-italic font-bold leading-[120%]">
                  Ish Ko’rinishi{" "}
                </h3>
                <div className="flex xl:flex-col lg:flex-col md:flex-col flex-row gap-5">
                  <label className="flex items-center mt-3 cursor-pointer">
                    <input
                      type="radio"
                      className="form-radio h-5 w-5 text-blue-600"
                      name="workType"
                      value="onsite"
                      // checked={checked}
                      // onChange={onChange}
                    />
                    <span className="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]">
                      Ofisdan ish
                    </span>
                  </label>
                  <label className="flex items-center mt-3 cursor-pointer">
                    <input
                      type="radio"
                      className="form-radio h-5 w-5 text-blue-600"
                      name="workType"
                      value="remote"
                    />
                    <span className="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]">
                      Masofaviy
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="xl:mb-0 lg:mb-0 md:mb-0 mb-20">
              <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 ">
                <div class="p-3 ">
                  <h3 className="text-[#212121] text-5 font-bold ">
                    Ish tajriba{" "}
                  </h3>
                  <ul
                    class="space-y-2 mt-5 text-sm flex flex-col gap-2"
                    aria-labelledby="dropdownDefault"
                  >
                    <li class="flex items-center">
                      <input
                        id="canon"
                        type="checkbox"
                        value=""
                        class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                      />

                      <label
                        for="canon"
                        class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                      >
                        Tajriba yo'q
                      </label>
                    </li>
                    <li class="flex items-center">
                      <input
                        id="1-5"
                        type="checkbox"
                        value=""
                        class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                      />

                      <label
                        for="1-5"
                        class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                      >
                        1 - 5 yil
                      </label>
                    </li>
                    <li class="flex items-center">
                      <input
                        id="6-10"
                        type="checkbox"
                        value=""
                        class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                      />

                      <label
                        for="6-10"
                        class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                      >
                        6 - 10 yil
                      </label>
                    </li>
                    <li class="flex items-center">
                      <input
                        id="more-10"
                        type="checkbox"
                        value=""
                        class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                      />

                      <label
                        for="more-10"
                        class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                      >
                        10 yildan ko'p
                      </label>
                    </li>
                  </ul>
                </div>
                <div class="p-3">
                  <h3 className="text-[#212121] text-[20px] not-italic font-bold leading-[120%]">
                    Ish grafigi{" "}
                  </h3>
                  <ul
                    class="space-y-2 mt-5 text-sm flex flex-col gap-2"
                    aria-labelledby="dropdownDefault"
                  >
                    <li class="flex items-center">
                      <input
                        id="canon12"
                        type="checkbox"
                        value=""
                        class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                      />

                      <label
                        for="canon12"
                        class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                      >
                        To'liq kun
                      </label>
                    </li>
                    <li class="flex items-center">
                      <input
                        id="part"
                        type="checkbox"
                        value=""
                        class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                      />

                      <label
                        for="part"
                        class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                      >
                        Yarim kun
                      </label>
                    </li>
                    <li class="flex items-center">
                      <input
                        id="frilans"
                        type="checkbox"
                        value=""
                        class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                      />

                      <label
                        for="frilans"
                        class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                      >
                        Frilanser
                      </label>
                    </li>
                    <li class="flex items-center">
                      <input
                        id="docs"
                        type="checkbox"
                        value=""
                        class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                      />

                      <label
                        for="docs"
                        class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                      >
                        Shartnoma{" "}
                      </label>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="xl:w-56 lg:w-56 md:w-56 sm:w-56 w-full p-3 mt-5">
                <h3 className="text-[#212121] text-[20px] not-italic font-bold leading-[120%]">
                  Ta'lim darajasi{" "}
                </h3>
                <ul
                  class="space-y-2 mt-5 text-sm flex flex-col gap-2"
                  aria-labelledby="dropdownDefault"
                >
                  <li class="flex items-center">
                    <input
                      id="canon1"
                      type="checkbox"
                      value=""
                      class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                    />

                    <label
                      for="canon1"
                      class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                    >
                      O'rta maktabdan kamroq{" "}
                    </label>
                  </li>
                  <li class="flex items-center">
                    <input
                      id="high"
                      type="checkbox"
                      value=""
                      class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                    />

                    <label
                      for="hign"
                      class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                    >
                      O'rta maktab
                    </label>
                  </li>
                  <li class="flex items-center">
                    <input
                      id="doctor"
                      type="checkbox"
                      value=""
                      class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                    />

                    <label
                      for="doctor"
                      class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                    >
                      Doktorlik yoki professional daraja
                    </label>
                  </li>
                  <li class="flex items-center">
                    <input
                      id="bachelor"
                      type="checkbox"
                      value=""
                      class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                    />

                    <label
                      for="bachelor"
                      class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                    >
                      Bakalavr diplomi{" "}
                    </label>
                  </li>
                  <li class="flex items-center">
                    <input
                      id="docent"
                      type="checkbox"
                      value=""
                      class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                    />

                    <label
                      for="docent"
                      class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                    >
                      Dotsent darajasi{" "}
                    </label>
                  </li>
                  <li class="flex items-center">
                    <input
                      id="magistr"
                      type="checkbox"
                      value=""
                      class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                    />

                    <label
                      for="magistr"
                      class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                    >
                      Magistr diplomi{" "}
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <button className="xl:w-22 lg:w-22 md:w-22 w-full fixed bottom-5 left-1/2 transform -translate-x-1/2 rounded-[7px] bg-[#1E3E5C] text-[#FFF] text-[17px] py-2">
            Izlash
          </button>
        </form>
      </div>

      <div>
        <div className="absolute top-mini-banner">
          <img className="relative" src={blueEllips} alt="yellow ellips" />
          <h3 className="top-mini-text  absolute top-2/4 left-2/4 -translate-x-1/3 -translate-y-30 text-[#FFF] font-[Caveat] text-[80px] not-italic font-normal ">
            Top Ish toping
          </h3>
        </div>
        <img
          className="absolute bottom-mini-banner"
          src={yellowEllips}
          alt="yellow ellips"
        />
      </div>
    </div>
  );
};
