/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppliedBack, FileUpload } from "../../../components/icon";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGallery,
  addOrUpdateGallery,
  deleteGallery,
  privacy,
} from "../../../store/galleryApi/galleryThunks";
import toast from "react-hot-toast";
import Delete from "../../../assets/img/delete.png";

export const ProfileGallery = () => {
  const { gallery, privacys } = useSelector((state) => state.gallery);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(fetchGallery());
    dispatch(privacy())
  }, []);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    console.log(files);
    setLoading(true)
    dispatch(addOrUpdateGallery({ gallery: files })).then((res) => {
      // console.log(res);
      if (res?.payload?.result === "success") {
        toast.success("Rasm qo'shildi!");
        dispatch(fetchGallery());
        setLoading(false)
      } else {
        toast.error("Nimadir xato, qayta urining!");
      }
    });
  };

  const handleDelete = (e) => {
    const gallery = e.target.id;
    // console.log(gallery);
    dispatch(deleteGallery({ imageUrl: gallery })).then((res) => {
      console.log(res);
      if (res?.payload?.result === "success") {
        toast.success("Rasm o'chirildi!");
        dispatch(fetchGallery());
      } else {
        toast.error("Nimadir xato, qayta urining!");
      }
    });
  };


  return (
    <div className="mx-auto applied mb-10">
      <Link
        to={"/profile"}
        className="flex gap-4 items-center font-bold text-xl"
      >
        <AppliedBack />
        Mening galereyam
      </Link>

      <div className="rounded-md border block m-auto my-5 border-indigo-500 bg-gray-50 p-2 shadow-md w-50">
        <label
          htmlFor="upload"
          className="flex items-center gap-2 cursor-pointer"
        >
          <FileUpload/>
          <span className="text-gray-600 font-medium">{loading ? "Yuklanmoqda" : "Rasm yuklash"}</span>
        </label>
        <input
          id="upload"
          type="file"
          className="hidden"
          onChange={handleFileChange}
        />
      </div>

      <section className="">
        <div className="container px-4 mx-auto">
          <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 -m-4">
            {gallery?.images?.length
              ? gallery?.images?.map((item, index) => (
                  <div key={index} className="p-4 w-full">
                    <a className="block relative min-h-50 max-h-50 rounded overflow-hidden">
                      <img
                        alt="ecommerce"
                        className="object-cover object-center w-full"
                        src={item}
                      />
                      <img
                        id={item}
                        onClick={(e) => handleDelete(e)}
                        alt="ecommerce"
                        className="object-cover absolute top-2 end-2 object-center w-6 h-6 cursor-pointer block"
                        src={Delete}
                      />
                    </a>
                  </div>
                ))
              : ""}
          </div>
        </div>
      </section>
    </div>
  );
};
