// store/jobs/jobsSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAllJobs,
  createJob,
  searchJobs,
  updateJobById,
  deleteJobById,
  applyForJob,
  fetchEmployerPosts,
  fetchAllJobsId,
  fetchJobsMe,
  favoriteJobs,
  removeFavoriteJobs,
} from "./jobsThunks"; // Adjust the path according to your structure

const initialState = {
  jobs: [],
  myJobs: [],
  employerPosts: [], // Add a new state for employer's posts
  searchJob: [],
  singleJob: null,
  currentPage: 1,
  totalPages: 1,
  totalDocuments: 0,
  employerTotalDocuments: 0,
  employerTotalPages: 1,
  status: "idle",
  searchStatus: "idle",
  applicationStatus: "idle",
  employerPostsStatus: "idle", // Add a new status for employer's posts
  error: null,
  filters: {
    page: 1,
    limit: 10,
    education: "",
    experience: "",
    location: "",
    workingtype: "",
    recommended: false,
    salary: "",
    title: "",
    sort: "",
    recentjob: false,
    numericFilters: "",
    jobType: "",
  },
};

const jobsSlice = createSlice({
  name: "jobsApi",
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetSearchJob(state) {
      state.searchJob = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllJobs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllJobs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.jobs = action.payload.jobs;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
        state.totalDocuments = action.payload.totalDocuments;
      })
      .addCase(fetchAllJobs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(fetchJobsMe.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchJobsMe.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.myJobs = action.payload.myJobs;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
        state.totalDocuments = action.payload.totalDocuments;
      })
      .addCase(fetchJobsMe.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error;
      })

      .addCase(fetchAllJobsId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllJobsId.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.jobs = action.payload.jobs;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
        state.totalDocuments = action.payload.totalDocuments;
      })
      .addCase(fetchAllJobsId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createJob.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createJob.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.jobs.push(action.payload); // Add the new job to the list
      })
      .addCase(createJob.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(searchJobs.pending, (state) => {
        state.searchStatus = "loading";
      })
      .addCase(searchJobs.fulfilled, (state, action) => {
        state.searchStatus = "succeeded";
        state.searchJob = action.payload.searchJob;
        state.searchTotalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
        state.searchTotalDocuments = action.payload.totalDocuments;
      })
      .addCase(searchJobs.rejected, (state, action) => {
        state.searchStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(updateJobById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateJobById.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (Array.isArray(state.jobs)) {
          const index = state.jobs?.findIndex(
            (job) => job._id === action.payload._id
          );
          if (index !== -1) {
            state.jobs[index] = action.payload; // Update the job in the list
          }
        }
      })
      .addCase(updateJobById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteJobById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteJobById.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (Array.isArray(state.jobs)) {
          // Check if state.jobs is an array
          state.jobs = state.jobs.filter((job) => job._id !== action.payload); // Remove the deleted job from the list
        }
      })
      .addCase(deleteJobById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(applyForJob.pending, (state) => {
        state.applicationStatus = "loading";
      })
      .addCase(applyForJob.fulfilled, (state) => {
        state.applicationStatus = "succeeded";
      })
      .addCase(applyForJob.rejected, (state, action) => {
        state.applicationStatus = "failed";
        state.error = action.error;
      })
      .addCase(fetchEmployerPosts.pending, (state) => {
        state.employerPostsStatus = "loading";
      })
      .addCase(fetchEmployerPosts.fulfilled, (state, action) => {
        state.employerPostsStatus = "succeeded";
        state.employerPosts = action.payload.jobs; // Set the jobs posted by the employer
        state.employerTotalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
        state.employerTotalDocuments = action.payload.totalDocuments;
      })
      .addCase(fetchEmployerPosts.rejected, (state, action) => {
        state.employerPostsStatus = "failed";
        state.error = action.error.message;
      })

      .addCase(favoriteJobs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(favoriteJobs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(favoriteJobs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.quickJobs = action.payload; // Update currentUser or handle as needed
      })

      .addCase(removeFavoriteJobs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(removeFavoriteJobs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeFavoriteJobs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.jobs = action.payload; // Update currentUser or handle as needed
      });
  },
});

export const { setCurrentPage, setFilters, resetSearchJob } = jobsSlice.actions;
export default jobsSlice.reducer;
