import { createSlice } from "@reduxjs/toolkit";
import {
  fetchGallery,
  fetchAllGalleries,
  fetchGalleryById,
  fetchJobSeekerGallery,
  addOrUpdateGallery,
  deleteGallery,
  privacy,
} from "./galleryThunks"; // Adjust the path according to your structure

const initialState = {
  gallery: [],
  privacys:[],
  status: "idle",
  error: null,
};

const gallerySlice = createSlice({
  name: "gallery",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGallery.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGallery.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.gallery = action.payload.data; // Adjust based on actual response structure
      })
      .addCase(fetchGallery.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(privacy.pending, (state) => {
        state.status = "loading";
      })
      .addCase(privacy.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.privacys = action.payload.data; // Adjust based on actual response structure
      })
      .addCase(privacy.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(fetchAllGalleries.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllGalleries.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.gallery = action.payload.data; // Adjust based on actual response structure
      })
      .addCase(fetchAllGalleries.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchGalleryById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGalleryById.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Assuming action.payload.data is a single gallery post
        const index = state.gallery.findIndex(
          (post) => post.id === action.payload.data.id
        );
        if (index !== -1) {
          state.gallery[index] = action.payload.data;
        } else {
          state.gallery.push(action.payload.data);
        }
      })
      .addCase(fetchGalleryById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchJobSeekerGallery.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchJobSeekerGallery.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.gallery = action.payload.data; // Adjust based on actual response structure
      })
      .addCase(fetchJobSeekerGallery.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addOrUpdateGallery.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addOrUpdateGallery.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Ensure state.gallery is an array
        if (!Array.isArray(state.gallery)) {
          state.gallery = [];
        }
        const index = state.gallery.findIndex(
          (post) => post.id === action.payload.data.id
        );
        if (index !== -1) {
          state.gallery[index] = action.payload.data;
        } else {
          state.gallery.push(action.payload.data);
        }
      })
      .addCase(addOrUpdateGallery.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteGallery.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteGallery.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Ensure state.gallery is an array
        if (!Array.isArray(state.gallery)) {
            state.gallery = [];
        }
        state.gallery = state.gallery.filter(
            (post) => post.id !== action.payload.data.id
        );
    })
      .addCase(deleteGallery.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default gallerySlice.reducer;
