import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTournamentId,
  fetchTournamentJoin,
} from "../../store/tournamentApi/tournamentThunk";
import { Link, useParams } from "react-router-dom";
import { AppliedBack } from "../../components/icon";
import toast from "react-hot-toast";

export const TournamentSingle = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { tournamentSingle } = useSelector((state) => state.tournamentApi);
  const [currentParticipant, setCurrentParticipant] = useState(null);

  const { currentUser } = useSelector((state) => state.users);
  useEffect(() => {
    dispatch(fetchTournamentId(id)); // Fetch tournament data here.
  }, [dispatch, id]);

  useEffect(() => {
    if (tournamentSingle?.participants) {
      const user = currentUser?.data;
      const currentId = user?._id;
      const participant = tournamentSingle.participants.find(
        (p) => p.userId === currentId
      );
      setCurrentParticipant(participant);
    }
  }, [tournamentSingle]);

  const handleButtonClick = (e, id) => {
    e.preventDefault(); // Prevent navigation
    console.log(id);
    dispatch(fetchTournamentJoin(id)).then((res) => {
      if (res?.meta.requestStatus === "fulfilled") {
        dispatch(fetchTournamentId(id)); // Fetch tournament data here.
        toast.success("Kod olindi");
      }
    }); // Dispatch the thunk function
  };

  return (
    <div className="mx-auto mb-10">
      <div className="flex justify-between mb-5">
        <Link
          to={"/profile/tournament"}
          className="flex gap-4 items-center font-bold text-xl"
        >
          <AppliedBack />
          Turnirlar
        </Link>
      </div>
      <div className="my-2 bg-[#F4F7F8] rounded-xl overflow-hidden shadow-lg">
        <div className="flex justify-between items-center">
          <div className="scale-90">
            <img
              className="rounded-l-lg w-47.5 h-47.5  object-cover object-center"
              src={tournamentSingle.image}
              alt="Tournament"
            />
          </div>
          <div className="flex-1 p-2">
            <div className="flex justify-between items-center">
              <div></div>
              <p className="text-[#D3E4EE] text-xs font-semibold leading-none tracking-wider">
                {tournamentSingle.type}
              </p>
            </div>

            <div className="mt-0.5">
              <p className="text-base font-bold">
                {tournamentSingle.tournament_name}
              </p>
            </div>

            <div className="mt-1.5">
              <p className="text-[#2D7FAE] text-xs font-semibold leading-none tracking-wider">
                {tournamentSingle.date_range}
              </p>
            </div>

            <div className="mt-1">
              <p className="text-[#2D7FAE] text-xs font-semibold leading-none tracking-wider">
                {tournamentSingle.location}
              </p>
            </div>

            <div className="mt-1.5">
              <p className="text-[#599ABF] text-xs font-semibold">
                Bosh sovrin jamg'armasi:
              </p>
            </div>

            <div className="mt-1.5">
              <p className="text-[#D7C878] text-xl font-semibold ">
                {tournamentSingle.prize_pool}
              </p>
            </div>

            <div className="mt-2">
                    {currentParticipant && tournamentSingle?.status === "open" ? (
                      <button className="bg-[#D3E4EE] text-[#438CB7] text-sm h-7 w-36 rounded-3xl">
                        {currentParticipant.specialCode}
                      </button>
                    ) : tournamentSingle?.status === "open" ? (
                      <button
                        onClick={(e) => handleButtonClick(e, tournamentSingle._id)}
                        className="bg-[#438CB7] text-white text-sm h-7 w-36 rounded-3xl"
                      >
                        Kod olish
                      </button>
                    ) : tournamentSingle.status === "closed" ? (
                      <button className="bg-gradient-to-r from-red-400 via-red-500 to-red-600 text-white text-sm h-7 w-36 rounded-3xl">
                        Yopilgan
                      </button>
                    ) : tournamentSingle.status === "expired" ? (
                      <button className=" bg-gradient-to-r from-gray-500 via-gray-500 to-gray-600 text-white text-sm h-7 w-36 rounded-3xl">
                        Muddati tugagan
                      </button>
                    ) : tournamentSingle.status === "coming" ? (
                      <button className="bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 text-white text-sm h-7 w-36 rounded-3xl">
                        Tez kunda
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
          </div>
        </div>
      </div>

      <div className="mt-6">
        {currentParticipant ? (
          <ul className="mb-2 flex flex-col gap-1">
            <li className="flex justify-between">
              <p className="text-[#438CB7] text-[12px] not-italic font-semibold leading-[normal] tracking-[0.2px]">
                Sizning identifikatsiya raqamingiz:
              </p>
              <p className="text-[#438CB7] text-[12px] not-italic font-semibold leading-[normal] tracking-[0.2px]">
                {currentParticipant?.playerId}
              </p>
            </li>
            <li className="flex justify-between">
              <p className="text-[#438CB7] text-[12px] not-italic font-semibold leading-[normal] tracking-[0.2px]">
                Maxsus kodingiz:
              </p>
              <p className="text-[#438CB7] text-[12px] not-italic font-semibold leading-[normal] tracking-[0.2px]">
                {currentParticipant?.specialCode}
              </p>
            </li>
          </ul>
        ) : (
          ""
        )}
        <p className="text-[#000] text-[16px] not-italic font-semibold leading-[normal] tracking-[0.2px]">
          {tournamentSingle?.description}
        </p>
      </div>
    </div>
  );
};
