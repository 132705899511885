export const fieldsEmployer = [
  "Ta'lim",
  "Moliyaviy xizmatlar",
  "Qurilish",
  "Media va madaniyat",
  "Turizm",
  "Biznes boshqaruvi",
  "Tibbiy xizmatlar",
  "Mexanika",
  "Boshqalar",
];
