import { createSlice } from "@reduxjs/toolkit";
import {
  fetchEmployers,
  searchEmployersByCompany,
  searchEmployersById,
  EditEmployerEmail,
  EditEmployerPhone,
  EditEmployerPassword,
  fetchEmployersMaker,
} from "./employersThunks"; // Adjust the path according to your structure

const initialState = {
  employers: [],
  jobs: [],
  singleEmployer: null,
  currentPage: 1,
  totalPages: 1,
  status: "idle",
  error: null,
  filters: {
    page: 1,
    limit: 10,
    companyName: "",
  },
};

const employersSlice = createSlice({
  name: "employersApi",
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
      state.filters.page = action.payload;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(EditEmployerPassword.pending, (state) => {
        state.status = "loading";
      })
      .addCase(EditEmployerPassword.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.employers = action.payload;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(EditEmployerPassword.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(EditEmployerPhone.pending, (state) => {
        state.status = "loading";
      })
      .addCase(EditEmployerPhone.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.employers = action.payload;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(EditEmployerPhone.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(EditEmployerEmail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(EditEmployerEmail.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.employers = action.payload;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(EditEmployerEmail.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchEmployers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchEmployers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.employers = action.payload.employers;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(fetchEmployers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(searchEmployersByCompany.pending, (state) => {
        state.status = "loading";
      })
      .addCase(searchEmployersByCompany.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.employers = action.payload.employers;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(searchEmployersByCompany.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(searchEmployersById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(searchEmployersById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.singleEmployer = action.payload.singleEmployer;
      })
      .addCase(searchEmployersById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(fetchEmployersMaker.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchEmployersMaker.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.jobs = action.payload.jobs;
      })
      .addCase(fetchEmployersMaker.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setCurrentPage, setFilters } = employersSlice.actions;
export default employersSlice.reducer;
