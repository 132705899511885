/* eslint-disable react/style-prop-object */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  EducationExperience,
  EducationJobseeker,
  JobType,
  Location,
  Salary,
} from "../icon";
import { useTranslation } from "react-i18next";

const AccordionItem = ({
  title,
  experience,
  avatar,
  desc,
  id,
  salary,
  jobType,
  location,
  education,
  Open,
}) => {
  const [isOpen, setIsOpen] = useState(Open ? false : true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  const { t } = useTranslation();

  return (
    <div className="border-b">
      <button
        className="w-full flex justify-between items-center text-left p-4 font-semibold hover:bg-gray-100 focus:outline-none"
        onClick={toggleAccordion}
      >
        <div className="flex items-center">
          <img
            src={avatar}
            alt="Avatar"
            className="h-10 w-10 rounded-full mr-4"
          />
          <p className="flex flex-col gap-1">
            {title} <span className="text-slate-500 h-6">{desc || ""}</span>
          </p>
        </div>
        <span>{isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}</span>
      </button>
      <div
        className={`overflow-hidden transition-[max-height] duration-500 ease-in-out ${
          isOpen ? "max-h-0 p-0" : "max-h-screen p-4 bg-blue-200"
        }`}
      >
        <div
          className={`transition-opacity duration-500 ${
            isOpen ? "opacity-0" : "opacity-100"
          }`}
        >
          <div className="flex flex-col gap-4 items-center">
            <ul className="flex flex-wrap justify-between items-center gap-4">
              {education ? (
                <li className="flex justify-between items-center gap-2">
                  <EducationJobseeker />

                  {t(`${education}`)}
                </li>
              ) : (
                ""
              )}
              {experience ? (
                <li className="flex justify-between items-center gap-2">
                  <EducationExperience />

                  {t(`${experience}`)}
                </li>
              ) : (
                ""
              )}
              {location ? (
                <li className="flex justify-between items-center gap-2">
                  <Location />

                  {t(`${location}`)}
                </li>
              ) : (
                ""
              )}
              {salary ? (
                <li className="flex justify-between items-center gap-2">
                  <Salary />
                  {t(`${salary}`)}
                </li>
              ) : (
                ""
              )}
              {jobType ? (
                <li className="flex justify-between items-center gap-2">
                  <JobType />
                  {t(`${jobType}`)}
                </li>
              ) : (
                ""
              )}
            </ul>

            <div className="w-full ">
              <Link
                to={`/jobseeker/${id}`}
                type="button"
                className="text-white flex justify-center w-full bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-4 py-2 me-2"
              >
                {t(`READ MORE`)}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EmployerJobCard = ({ data, loading, Open }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-5 mt-5">
      {data?.map((item) => (
        <div key={item?._id} className="w-full">
          <div className="p-2 gap-2">
            <div className="w-full bg-white shadow-lg rounded-lg">
              <AccordionItem
                title={item?.fullName}
                desc={item?.jobSeeker.jobTitle}
                content="This is the content for the first accordion panel."
                avatar={item.avatar}
                salary={item?.jobSeeker.expectedSalary}
                location={item?.location}
                jobType={item?.jobSeeker.employmentType}
                id={item?._id}
                experience={item?.jobSeeker.experience}
                education={item?.jobSeeker.educationalBackground}
                Open={Open}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EmployerJobCard;
