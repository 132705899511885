import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AppliedBack } from "../../../components/icon";
import { fetchFavoriteUsers } from "../../../store/usersApi/usersThunks";
import EmployerJobCard from "../../../components/cards/EmployerJobCard";

export const FavoriteResume = () => {
  const dispatch = useDispatch();
  const { favoriteUser } = useSelector((state) => state.users);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchFavoriteUsers())
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [dispatch]);

  return (
    <nav className="overflow-hidden mx-auto mb-10">
      <div className="flex items-center justify-between mb-5">
        <Link
          to={"/profile"}
          className="flex gap-4 items-center font-bold text-xl"
        >
          <AppliedBack />
          Yoqtirgan talantlar
        </Link>
      </div>
      <EmployerJobCard data={favoriteUser} loading={isLoading} />
    </nav>
  );
};
