import { createSlice } from "@reduxjs/toolkit";
import {
  uploadUserAvatar,
  fetchUserAvatar,
  updateUserAvatar,
  deleteUserAvatar,
} from "./avatarThunks";

const initialState = {
  avatar: "",
  status: "idle",
  error: null,
};

const avatarSlice = createSlice({
  name: "avatar",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadUserAvatar.pending, (state) => {
        state.status = "loading";
      })
      .addCase(uploadUserAvatar.fulfilled, (state, action) => {
        state.status = "succeeded";
        // The avatar will be updated by the fetchUserAvatar fulfilled case
      })
      .addCase(uploadUserAvatar.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchUserAvatar.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserAvatar.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.avatar = action.payload.avatar;
      })
      .addCase(fetchUserAvatar.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateUserAvatar.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUserAvatar.fulfilled, (state, action) => {
        state.status = "succeeded";
        // The avatar will be updated by the fetchUserAvatar fulfilled case
      })
      .addCase(updateUserAvatar.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteUserAvatar.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteUserAvatar.fulfilled, (state) => {
        state.status = "succeeded";
        state.avatar = "";
      })
      .addCase(deleteUserAvatar.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default avatarSlice.reducer;
