import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addCertificate } from '../../../store/certificatesApi/certificatesThunks';
import toast from "react-hot-toast";

const CertificateDrawer = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [organization, setOrganization] = useState('');
  const [dateOfIssue, setDateOfIssue] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [notExpire, setNotExpire] = useState(false);
  const [credentialId, setCredentialId] = useState('');
  const [credentialUrl, setCredentialUrl] = useState('');

  const handleSubmit = () => {
   if(
      title?.trim() === "" || 
      organization?.trim() === "" || dateOfIssue?.trim() === "" ) {
        toast.error("Maydonni to'ldiring");
      }else {
        dispatch(addCertificate({ title, organization, dateOfIssue, expirationDate, notExpire, credentialId, credentialUrl }));
        onClose();
      }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm z-50">
      <div className="relative bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full">
        <button className="absolute top-0 right-0 m-4 text-lg" onClick={onClose}>X</button>
        <h2 className="text-center text-xl mb-4">Sertifikatlar</h2>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="text"
          placeholder="Sarlavha"
          value={title}
          
          onChange={(e) => setTitle(e.target.value)}
        />
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="text"
          placeholder="Qaysi tashkilot tomonidan berilgan"
          value={organization}
          onChange={(e) => setOrganization(e.target.value)}
        />
        <div className="flex mb-4">
          <div className="w-1/2 mr-2">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="date"
              placeholder="Date of Issue"
              value={dateOfIssue}
              onChange={(e) => setDateOfIssue(e.target.value)}
            />
          </div>
          <div className="w-1/2 ml-2">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="date"
              placeholder="Expiration Date"
              value={expirationDate}
              onChange={(e) => setExpirationDate(e.target.value)}
              disabled={notExpire}
            />
          </div>
        </div>
        <div className="flex items-center mb-4">
          <input
            className="mr-2 leading-tight"
            type="checkbox"
            checked={notExpire}
            onChange={(e) => setNotExpire(e.target.checked)}
          />
          <span>Bu ma'lumot eskirmaydi</span>
        </div>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="text"
          placeholder="Guvohnoma ID raqami (ixtiyoriy)"
          value={credentialId}
          onChange={(e) => setCredentialId(e.target.value)}
        />
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="url"
          placeholder="Guvohnoma URL manzili (ixtiyoriy)"
          value={credentialUrl}
          onChange={(e) => setCredentialUrl(e.target.value)}
        />
        <button
          onClick={handleSubmit}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
          type="button"
        >
          Saqlash
        </button>
      </div>
    </div>
  );
};

export default CertificateDrawer;
