import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

export const fetchEmployers = createAsyncThunk(
  "employersApi/fetchEmployers",
  async (filters) => {
    const response = await axios.get("/users/getAllEmployers", {
      params: filters,
    });
    console.log("employers response", response.data.data);
    return {
      employers: response.data.data, // Adjust based on actual response structure
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
    };
  }
);

export const fetchEmployersMaker = createAsyncThunk(
  "employersApi/fetchEmployersMaker",
  async (id) => {
    const response = await axios.get(`/users/getJobMaker/${id}`);
    console.log("employers response", response.data.data);
    return {
      jobs: response.data.data, // Adjust based on actual response structure
    };
  }
);

export const searchEmployersByCompany = createAsyncThunk(
  "employersApi/searchEmployersByCompany",
  async (filters) => {
    const response = await axios.get("/users/searchEmployers", {
      params: filters,
    });
    console.log("employers response", response.data.data);
    return {
      employers: response.data.data, // Adjust based on actual response structure
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
    };
  }
);

export const searchEmployersById = createAsyncThunk(
  "employersApi/getEmployer",
  async (id) => {
    const response = await axios.get(`/users/getEmployer/${id}`);
    console.log("getEmployer response", response.data.data);
    return {
      singleEmployer: response.data.data, // Adjust based on actual response structure
    };
  }
);

export const EditEmployerEmail = createAsyncThunk(
  "employersApi/EditEmployerEmail",
  async (profileData) => {
    const response = await axios.patch(
      "/users/updateUserEmail",
      profileData
    );
    return response.data;
  }
);

export const EditEmployerPhone = createAsyncThunk(
  "employersApi/updateUserNumber",
  async (profileData) => {
    const response = await axios.patch(
      "/users/updateUserNumber",
      profileData
    );
    console.log("response.data :", response.data);
    return response.data;
  }
);

export const EditEmployerPassword = createAsyncThunk(
  "employersApi/EditEmployerPassword",
  async (profileData) => {
    const response = await axios.patch(
      "/users/updatePassword",
      profileData
    );
    console.log("response.data :", response.data);
    return response.data;
  }
);