/* eslint-disable react/style-prop-object */
import React from "react";
import { Link } from "react-router-dom";
import { QuickJobCard } from "../../../components/cards/QuickJobCard";
import { useFetchRecentQuickJobsQuery } from "../../../services";
import { useTranslation } from "react-i18next";

const QuickJobs = () => {
  const { data: recentJob, isLoading } = useFetchRecentQuickJobsQuery({
    page: 1,
    pageSize: 10,
  });
  const { t } = useTranslation();

  return (
    <div class="overflow-hidden mx-auto hero-banner mt-10">
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-black text-lg font-bold ">{t("Recent Jobs")}</h1>
        <Link
          className="text-blue-600 text-base font-bold "
          to={"/allrecentjobs"}
        >
          {t("All")}{" "}
        </Link>
      </div>

      <QuickJobCard loading={isLoading} quickJobs={recentJob?.data} />
    </div>
  );
};

export default QuickJobs;
