import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchQuickJobsMe } from "./../../../store/quickJobsApi/quickJobsThunks";
import { fetchOfficeMy } from "../../../store/officeApi/officeApiThunk";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import fakeimg from "../../../assets/img/hero-bg.png";
import { fetchJobsMe } from "../../../store/jobsApi/jobsThunks";
import { QuickJobCard } from "../../../components/cards/QuickJobCard";
import { LastJobsCard } from "../../../components/cards/LastJobsCard";
import { useFetchQuickJobsMeQuery } from "../../../services";
import { useTranslation } from "react-i18next";

export const Announ = () => {
  const dispatch = useDispatch();
  const { myOffice } = useSelector((state) => state.office);
  const { myJobs } = useSelector((state) => state.jobs);

  useEffect(() => {
    dispatch(fetchOfficeMy({ page: 1, pageSize: 10 }));
    dispatch(fetchJobsMe({ page: 1, pageSize: 10 }));
  }, [dispatch]);

  const { data: quickJobsMe, isLoading } = useFetchQuickJobsMeQuery({
    page: 1,
    pageSize: 10,
  });

  const isEmpty =
    quickJobsMe?.data.length === 0 &&
    myOffice.length === 0 &&
    myJobs?.length === 0;
  const { t } = useTranslation();

  return (
    <div className="overflow-hidden mx-auto  hero-banner">
      <h3 className="text-black flex justify-center text-2xl font-semibold leading-[normal]">
        {t("Create a post")}
      </h3>

      <div>
        <div className="flex justify-between mt-5">
          <Link
            to={"/announ/standart"}
            type="button"
            className="px-4 py-2 rounded-full text-sm font-medium text-white bg-slate-700"
          >
            {" "}
            {t("Standard post")}
          </Link>
          <Link
            to={"/announ/quick"}
            type="button"
            className="px-4 py-2 rounded-full text-sm font-medium text-white bg-blue-500"
          >
            {" "}
            {t("Quick post")}
          </Link>
          <Link
            to={"/announ/office"}
            type="button"
            className="px-4 py-2 rounded-full text-sm font-medium text-white bg-blue-700"
          >
            {t("Office post")}
          </Link>
        </div>
      </div>

      <div className=" mb-20">
        {isEmpty ? (
          <p className="flex justify-center font-bold mt-10">
            Hozircha e'lon joylamagansiz.
          </p>
        ) : (
          <div>
            <div className="mt-10">
              <h4 className="font-semibold mb-5">E'lonlarim</h4>
              <QuickJobCard status={isLoading} quickJobs={quickJobsMe?.data} />
            </div>

            <div className="mt-5">
              {/* <h3 className="font-semibold">Ofislar</h3> */}

              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  992: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  650: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  550: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {isLoading ? (
                  <SwiperSlide className="animate-pulse">
                    <Link className="w-full">
                      <div className="shadow-lg p-2 gap-2">
                        <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                          <svg
                            className="w-10 h-10 text-gray-200 dark:text-gray-600"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 16 20"
                          >
                            <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                            <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                          </svg>
                        </div>

                        <div className="relative">
                          <div>
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4" />
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5" />
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5" />
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                ) : (
                  myOffice?.map((item) => (
                    <SwiperSlide key={item?._id}>
                      <Link className="" to={`/office/${item?._id}`}>
                        <div className="shadow-lg rounded-md   p-4 xl:w-90 lg:w-70 w-80 gap-2">
                          <div className="relative rounded-sm">
                            <img
                              className="w-full h-39 object-cover"
                              src={
                                item?.images.length ? item?.images[0] : fakeimg
                              }
                              alt="'name"
                            />{" "}
                          </div>
                          <div className="relative">
                            <div className="flex justify-between mt-4">
                              <h3 className="text-[#323221] text-[13px] not-italic font-bold leading-[normal]">
                                {item?.title}
                              </h3>
                              <p className="text-[#323221] text-[12px] not-italic font-semibold leading-[normal]">
                                {item?.officeType}
                              </p>
                            </div>
                            <div className="flex justify-between mt-4">
                              <h3 className="flex items-center gap-1 text-[#666656] text-[13px] not-italic font-bold leading-[normal]">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="#000000"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <circle cx="12" cy="10" r="3" />
                                  <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                                </svg>{" "}
                                {item?.location}
                              </h3>
                              <p className="text-[#275ccf] text-[14px] not-italic font-semibold leading-[normal]">
                                {item?.price}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  ))
                )}
              </Swiper>
            </div>

            <div className="mt-5">
              {/* <h3 className="font-semibold">Standart ishlar</h3> */}

              <LastJobsCard jobs={myJobs} status={isLoading} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
