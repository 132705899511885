import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addLanguage } from '../../../store/languagesApi/languagesThunks';
import toast from "react-hot-toast";

const LanguageDrawer = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [language, setLanguage] = useState('');
  const [proficiency, setProficiency] = useState('');

  const languages = ['O\'zbek', 'Ingliz', 'Rus'];
  const proficiencyLevels = ['Boshlang\'ich daraja', 'Cheklangan daraja', 'Professional daraja', 'Ona tili daraja'];

  const handleSubmit = () => {
   if(
      language?.trim() === "" || 
      proficiency?.trim() === "" ){
        toast.error("Maydonni to'ldiring");
      }else {
        dispatch(addLanguage({ language, proficiency }));
        onClose();
      }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm z-50">
      <div className="relative bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full">
        <button className="absolute top-0 right-0 m-4 text-lg" onClick={onClose}>X</button>
        <h2 className="text-center text-xl mb-4">Tillar</h2>
        <select
          className="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
        >
          <option value="">Til</option>
          {languages.map((lang, index) => (
            <option key={index} value={lang}>{lang}</option>
          ))}
        </select>
        <select
          className="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          value={proficiency}
          onChange={(e) => setProficiency(e.target.value)}
        >
          <option value="">Bilish darajasi</option>
          {proficiencyLevels.map((level, index) => (
            <option key={index} value={level}>{level}</option>
          ))}
        </select>
        <button
          onClick={handleSubmit}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
          type="button"
        >Saqlash
        </button>
      </div>
    </div>
  );
};

export default LanguageDrawer;
