import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppliedBack } from "../../components/icon";
import { TournamentCard } from "../../components/cards/TournamentCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchTournament } from "../../store/tournamentApi/tournamentThunk";

function Tournament() {
  const dispatch = useDispatch();
  const { tournament } = useSelector((state) => state.tournamentApi);

  useEffect(() => {
    dispatch(fetchTournament()); // Fetch tournament data here.
  }, [dispatch]);

  console.log(tournament);


  return (
    <div className="mx-auto mb-10">
      <div className="flex justify-between mb-5">
        <Link
          to={"/profile"}
          className="flex gap-4 items-center font-bold text-xl"
        >
          <AppliedBack />
          Turnirlar
        </Link>
      </div>
      <TournamentCard
        fetchTournament={fetchTournament}
        tournament={tournament}
      />
    </div>
  );
}

export default Tournament;
