// store/openApiThunks.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

export const fetchOffice = createAsyncThunk(
  "officeApi/office",
  async ({ page, pageSize }) => {
    const response = await axios.get("/offices", {
      params: {
        page,
        pageSize,
      },
    });
    return {
      office: response.data.data, // Adjust based on actual response structure
      totalPages: response.data.pagination?.totalPages,
      currentPage: response.data.pagination?.currentPage,
    };
  }
);

export const fetchOfficeMy = createAsyncThunk(
  "officeApi/myoffice",
  async ({ page, pageSize }) => {
    const response = await axios.get("/offices/myposts", {
      params: {
        page,
        pageSize,
      },
    });
    return {
      myOffice: response.data.data, // Adjust based on actual response structure
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
    };
  }
);

export const fetchOfficeId = createAsyncThunk(
  "piofficeA/fetchOfficeId",
  async (id) => {
    const response = await axios.get(`/offices/${id}`);
    return {
      officeId: response.data.data, // Adjust based on actual response structure
    };
  }
);

export const createOffice = createAsyncThunk(
  "officeApi/Office",
  async (newJob) => {
    const response = await axios.post("/offices", newJob);
    console.log(response.data, "success");
    return response.data.data; // Adjust based on actual response structure
  }
);

export const deleteOfficeById = createAsyncThunk(
  "officeApi/deleteOfficeById",
  async (id) => {
    await axios.delete(`/offices/${id}`);
    return id; // Return the deleted job ID
  }
);


export const updateOffice= createAsyncThunk(
  "officeApi/updateOffice",
  async ({ id, updatedOffice }) => {
    const response = await axios.patch(`/offices/${id}`, updatedOffice);
    return response.data.data; // Adjust based on actual response structure
  }
);