import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppliedBack } from "../../../components/icon";
import { EditEmployerPhone } from "../../../store/employersApi/employersThunks";
import toast from "react-hot-toast";
import { ButtonSpinner } from "../../../components/buttonSpinner/ButtonSpinner";
import InputMask from "react-input-mask";

export const ChangePhone = () => {
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState("+998");

  const { status } = useSelector((state) => state.employers);

  const handleChange = (event) => {
    console.log(event.target.value);
    setPhoneNumber(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const rawPhoneNumber = phoneNumber.replace(/\D/g, "").slice(3, 12);

    dispatch(EditEmployerPhone({ phoneNumber: rawPhoneNumber })).then((response) => {
      if (response?.payload?.result === "success") {
        toast.success(response.payload.msg);
      }
    });
  };

  return (
    <div className="mb-10">
      <Link
        to={"/profile/protected"}
        className="flex gap-4 items-center font-bold text-xl"
      >
        <AppliedBack />
        Telefon raqam o'zgartirish
      </Link>

      <div>
        <form className="mt-10" onSubmit={handleSubmit}>
          <div className="flex flex-col gap-3">
            <label className="text-gray-700 font-semibold">Yangi Raqam</label>
            <InputMask
                mask="+998 (99) 999 9999"
                maskChar={null}
                value={phoneNumber}
                onChange={handleChange}
                className="border-gray-300 outline-none border border-solid text-gray-900 text-sm rounded-lg block w-full p-2.5"
                placeholder="+998 (__) ___ ____"
                alwaysShowMask
              />
          </div>

          <div className="flex justify-center">
            <button
              className="shadow-md fixed bottom-25 text-white font-bold text-base bg-[#246BFD] rounded-[100px] w-full p-3 max-w-45"
              type="submit"
            >
              {status === "loading" ? <ButtonSpinner /> : "Saqlash"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
