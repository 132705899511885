import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { AppliedBack } from "../../../components/icon";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobSeekersFavorite } from "../../../store/jobSeekersApi/jobSeekersThunks";
import { QuickJobCard } from "../../../components/cards/QuickJobCard";
import { LastJobsCard } from "../../../components/cards/LastJobsCard";
import { useTranslation } from "react-i18next";

export const FavoriteJobseeker = () => {
  const { favoriteJobSeekers, status } = useSelector(
    (state) => state.jobSeekers
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchJobSeekersFavorite());
  }, [dispatch]);
  const { jobs, quickJobs } = favoriteJobSeekers;
  const { t } = useTranslation();

  return (
    <div className="overflow-hidden mx-auto applied mb-10">
      <Link
        to={"/profile"}
        className="flex gap-4 items-center font-bold text-xl"
      >
        <AppliedBack />
        {t("Saved Vacancies")}
      </Link>

      <div className="mt-10">
        {quickJobs?.length ? (
          <>
            {" "}
            <div className="flex items-center justify-between mb-4 px-4">
              <h1 className="text-black text-lg font-bold ">
                {t("Recent Jobs")}
              </h1>
              <Link
                className="text-blue-600 text-base font-bold "
                to={"/allrecentjobs"}
              >
                {t("All")}
              </Link>
            </div>
            <div>
              <QuickJobCard status={status} quickJobs={quickJobs} />
            </div>
          </>
        ) : (
          ""
        )}

        {jobs?.length ? (
          <div className="hero-banner mt-10">
            <div className="flex items-center justify-between px-4 mb-4">
              <h1 className="text-black text-lg font-bold">
                {t("Standard_Jobs")}
              </h1>
              <Link
                className="text-blue-600 text-base font-bold"
                to={"/alljobs"}
              >
                {t("All")}
              </Link>
            </div>
            <LastJobsCard jobs={jobs} status={status} />
          </div>
        ) : (
          <p className="flex justify-center font-bold"></p>
        )}
      </div>
    </div>
  );
};
