import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BackSingle,
  Candidats,
  HRLocation,
  JobsFavoriteStar,
  Jobseekerstar,
  JobseekerstarApply,
  QuickJobsWallet,
} from "../../icon";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteJobById,
  favoriteJobs,
  fetchAllJobsId,
  fetchJobsMe,
  removeFavoriteJobs,
} from "../../../store/jobsApi/jobsThunks";
import ApplyJobButton from "./ApplyJobButton";
import toast from "react-hot-toast";
import io from "socket.io-client";
import { BASE_URL_DOMAIN } from "../../../api/api";
import { fetchCurrentUser } from "../../../store/usersApi/usersThunks";
import { useTranslation } from "react-i18next";

const socket = io(BASE_URL_DOMAIN); // Change to your server URL

export const JobsSingle = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigete = useNavigate();
  const [loading, setLoading] = useState(true);
  const [chatRoomId, setChatRoomId] = useState(null);
  const [loadingChatRoom, setLoadingChatRoom] = useState(true);
  const { currentUser } = useSelector((state) => state.users);
  const { jobs } = useSelector((state) => state.jobs);
  const user = currentUser?.data;
  useEffect(() => {
    dispatch(fetchAllJobsId(id)).then((result) => {
      console.log(result);
      if (result?.payload?.jobs) {
        setLoading(false);
      }
    });
    dispatch(fetchCurrentUser());
  }, [id]);

  useEffect(() => {
    if (user && jobs) {
      const handleChatRoomsResponse = (response) => {
        // console.log("response: ", response);

        if (response.status === 200 && response.data.length > 0) {
          const existingRoom = response.data.find(
            (room) => room.otherUser._id === jobs.createdBy
          );
          if (existingRoom) {
            setChatRoomId(existingRoom._id);
            setLoadingChatRoom(false);
          } else {
            createNewChatRoom();
          }
        } else {
          createNewChatRoom();
        }
      };

      const handleChatRoomCreated = (response) => {
        // console.log("created chat room: ", response);
        if (response.status === 200) {
          setChatRoomId(response.chatRoomId);
          setLoadingChatRoom(false);
        }
      };

      const createNewChatRoom = () => {
        socket.emit("createChatRoom", {
          userId: user.id,
          otherUserId: jobs.createdBy,
        });
      };

      socket.emit("requestChatRooms", { userId: user._id });
      socket.on("chatRoomsResponse", handleChatRoomsResponse);
      socket.on("chatRoomCreated", handleChatRoomCreated);

      return () => {
        socket.off("chatRoomsResponse", handleChatRoomsResponse);
        socket.off("chatRoomCreated", handleChatRoomCreated);
      };
    }
  }, [jobs, user]);

  const handleFavortite = () => {
    dispatch(favoriteJobs(id)).then((res) => {
      if (res.payload?.result) {
        toast.success("Sevimlilarga qo'shildi!");
        dispatch(fetchAllJobsId(id));
      }
      if (res.error?.message === "Request failed with status code 401") {
        toast.error("Tizimga kirish talab etiladi!");
      }
      if (res.error?.message === "Failed to refresh token") {
        toast.error("Tizimga kirish talab etiladi!");
      }
      if (!res.payload) {
        dispatch(removeFavoriteJobs(id)).then((res) => {
          if (res?.payload?.result === "success") {
            toast.success("Sevimlilardan chiqarildi!");
            dispatch(fetchAllJobsId(id));
          }
        });
      }
    });
  };

  const handleDelete = () => {
    // console.log(id);
    dispatch(deleteJobById(id)).then((result) => {
      if (result?.meta.requestStatus === "fulfilled") {
        toast.success("Ish muvaffaqqiyatli o'chirildi");
        dispatch(fetchJobsMe({ page: 1, pageSize: 10 }));
        navigete("/announ");
      }
    });
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    // Array of month names
    const months = [
      "Yanvar",
      "Fevral",
      "Mart",
      "Aprel",
      "May",
      "Iyun",
      "Iyul",
      "Avgust",
      "Sentabr",
      "Oktabr",
      "Noyabr",
      "Dekabr",
    ];

    const month = months[date.getUTCMonth()];
    // Add leading zero to day if necessary
    const formattedDay = day < 10 ? `0${day}` : day;
    return `${formattedDay} ${month} ${year}`;
  }
  const myId = currentUser?.data._id;
  const hasUserDeleted = jobs?.createdBy?.includes(myId);
  const hasCandidates = jobs?.createdBy?.includes(myId);
  const hasUserFavorite = jobs?.likedBy?.includes(myId);
  const { t } = useTranslation();

  return (
    <div className="px-4 mb-10 mx-auto">
      <div className="shadow-md flex flex-col gap-5">
        <div className="border border-solid  px-3 py-3">
          {loading ? (
            <>
              <div className="flex animate-pulse  justify-between items-center mt-6 py-2">
                <h3 className="text-black text-2xl font-semibold leading-[normal]">
                  <div className="h-4.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                </h3>
                <p className="m-0 text-[#246BFD] text-lg not-italic font-medium leading-[161.023%]">
                  <div className="h-4.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                </p>
              </div>
              <div className="flex animate-pulse justify-between mt-3 items-end">
                <svg
                  className="w-10 h-10 me-3 text-gray-200 dark:text-gray-700"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                </svg>
                <div>
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col justify-center py-2 gap-3">
                <div className="w-full hidden md:flex  items-center gap-10">
                  {hasUserDeleted ? (
                    <Link to={`/announ/standart/${id}`}>
                      <button
                        className={`shadow-md text-[#f0f5f0] font-bold text-base left-5 bg-[#3776ffc9] rounded-[100px] py-2 px-4 w-full max-w-35`}
                      >
                        {t(`Edit`)}
                      </button>
                    </Link>
                  ) : (
                    <ApplyJobButton jobId={jobs} />
                  )}
                  <div className="relative hidden md:block">
                    {hasUserDeleted ? (
                      <button
                        onClick={() => handleDelete()}
                        className={`shadow-md text-white font-bold text-base  bg-red-600 rounded-[100px] w-full py-2 px-4 max-w-45`}
                        type="button"
                      >
                        {t(`Delete`)}
                      </button>
                    ) : (
                      <Link
                        to={loadingChatRoom ? "#" : `/chat/${chatRoomId}`}
                        className={`shadow-md text-center  text-white font-bold text-base bg-[#246BFD] rounded-[100px] w-full py-2 px-4 max-w-45         }`}
                        type="button"
                        onClick={
                          loadingChatRoom
                            ? () => toast.error("Tizimga kirish talab etiladi")
                            : null
                        }
                      >
                        {t(`Send a message`)}
                      </Link>
                    )}
                  </div>
                </div>{" "}
                <span
                  onClick={() => handleFavortite()}
                  className="relative flex justify-end cursor-pointer mb-1"
                >
                  {hasUserFavorite ? <JobseekerstarApply /> : <Jobseekerstar />}
                </span>
                <h3 className="text-black flex justify-between gap-4 items-start text-xl font-semibold leading-[normal]">
                  {jobs?.jobTitle}
                  <div className="flex min-w-[80px] gap-4 items-center">
                    <p className="text-[#504d4d] text-[12px] font-medium">
                      {formatDate(jobs?.createdAt)}
                    </p>

                    {/* <JobsFavoriteStar /> */}
                  </div>
                </h3>
              </div>
              <div className="flex justify-between mt-3 gap-3">
                <div className="flex items-center gap-2">
                  <img
                    className="w-8 h-7 rounded-full"
                    src={jobs?.hr_avatar}
                    alt="avatar"
                  />
                  <h4 className="text-black text-xs font-semibold">
                    {jobs?.hr_name}
                  </h4>
                </div>
                <p className="m-0  flex gap-2 items-center text-[#757575] text-xs font-semibold">
                  <HRLocation />

                  {t(`${jobs?.location}`)}
                </p>
              </div>
            </>
          )}
        </div>
        {hasCandidates ? (
          <Link
            to={`/quickjobs/candidates/${id}`}
            className="border border-solid px-3 py-3"
          >
            <div className="flex justify-between items-center py-2">
              <h3 className="text-black flex items-center gap-1 text-[13px] font-normal leading-[normal]">
                <Candidats />
                {jobs?.applicants?.length ? (
                  <span className="ms-7 text-[13px] text-[#06f64e]  ">
                    {jobs?.applicants?.length}
                  </span>
                ) : (
                  <span className="ms-7 text-[13px] text-[#F63106]  ">
                    {jobs?.applicants?.length}
                  </span>
                )}
                {t(`Applicants`)}
              </h3>
              {jobs?.applicants?.length ? (
                <p className="m-0 text-[#06f64e] text-[13px] not-italic font-medium leading-[161.023%]">
                  {t(`There are candidates`)}
                </p>
              ) : (
                <p className="m-0 text-[#F63106] text-[13px] not-italic font-medium leading-[161.023%]">
                  {t(`No Candidates`)}
                </p>
              )}
            </div>
          </Link>
        ) : (
          <p className="border border-solid px-3 py-3 ">
            <div className="flex justify-between items-center py-2">
              <h3 className="text-black flex items-center gap-1 text-[13px] font-normal leading-[normal]">
                <Candidats />
                {jobs?.applicants?.length ? (
                  <span className="ms-7 text-[13px] text-[#06f64e]  ">
                    {jobs?.applicants?.length}
                  </span>
                ) : (
                  <span className="ms-7 text-[13px] text-[#F63106]  ">
                    {jobs?.applicants?.length}
                  </span>
                )}
                {t(`Applicants`)}
              </h3>
              {jobs?.applicants?.length ? (
                <p className="m-0 text-[#06f64e] text-[13px] not-italic font-medium leading-[161.023%]">
                  {t(`There are candidates`)}
                </p>
              ) : (
                <p className="m-0 text-[#F63106] text-[13px] not-italic font-medium leading-[161.023%]">
                  {t(`No Candidates`)}
                </p>
              )}
            </div>
          </p>
        )}
        <div className="border border-solid px-3 py-3">
          <div className="mt-6 py-2">
            <h3 className="text-black text-2xl font-bold leading-[normal]">
              {t(`Job Description`)}
            </h3>

            {loading ? (
              <p className="mt-4 animate-pulse text-base font-medium text-[#424242]">
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </p>
            ) : (
              <p className="mt-4 text-base font-medium text-[#424242]">
                {jobs?.description}
              </p>
            )}
          </div>
        </div>
        <div className="border border-solid px-3 py-3">
          <div className="mt-6 py-2">
            <h3 className="text-black text-2xl font-bold leading-[normal]">
              {t(`Salary Description`)}
            </h3>
            <ul className="">
              <li>
                <p className="mt-4 text-base font-medium text-[#212121]">
                  {t(`${jobs?.salaryRange}`)}
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="border border-solid px-3 py-3">
          <div className="mt-6 py-2">
            <h3 className="text-black text-2xl font-bold leading-[normal]">
              {t(`Minimum Qualifications`)}
            </h3>
            <ul className="">
              {jobs?.benefits?.map((item) => (
                <li>
                  <p className="mt-4 flex items-center gap-3 text-base font-medium text-[#212121]">
                    <svg
                      width="20"
                      height="20"
                      className="min-w-[30px]"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.77325 18.2613C9.86553 18.3096 9.96872 18.3341 10.0719 18.3333C10.1751 18.3324 10.2775 18.3071 10.3706 18.2581L13.3438 16.6687C14.187 16.2192 14.8472 15.7167 15.3623 15.1316C16.4823 13.8568 17.0939 12.2298 17.083 10.5522L17.0478 5.01828C17.0444 4.38089 16.6258 3.81213 16.0066 3.60539L10.4754 1.7496C10.1424 1.63683 9.77744 1.63928 9.45025 1.75532L3.94004 3.6773C3.32425 3.89222 2.91317 4.46505 2.91652 5.10326L2.95176 10.6331C2.96267 12.3132 3.59523 13.9328 4.73369 15.1945C5.25383 15.7714 5.91996 16.2666 6.77233 16.7087L9.77325 18.2613ZM8.98614 11.7574C9.11031 11.8767 9.27139 11.9355 9.43246 11.9339C9.59354 11.9331 9.75378 11.8726 9.87627 11.7517L13.1255 8.54837C13.3696 8.30731 13.3671 7.91997 13.1205 7.68217C12.873 7.44438 12.4745 7.44601 12.2304 7.68708L9.42324 10.454L8.27388 9.3492C8.02639 9.1114 7.62873 9.11385 7.38375 9.35492C7.13962 9.59599 7.14214 9.98332 7.38963 10.2211L8.98614 11.7574Z"
                        fill="url(#paint0_linear_360_2558)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_360_2558"
                          x1="17.0832"
                          y1="18.3333"
                          x2="-0.144619"
                          y2="14.0875"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#246BFD" />
                          <stop offset="1" stop-color="#5089FF" />
                        </linearGradient>
                      </defs>
                    </svg>
                    {t(`${item}`)}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="border border-solid px-3 py-3">
          <div className="mt-6 py-2">
            <h3 className="text-black text-2xl font-bold leading-[normal]">
              {t(`About Job Description`)}
            </h3>
            <ul className="mt-4 flex flex-col gap-4">
              <li className="flex justify-between items-center">
                <p className="font-bold text-base flex flex-col">
                  {t(`Job category`)}

                  <span className="text-[#246BFD] font-medium mt-2">
                    {t(`${jobs?.industry}`)}
                  </span>
                </p>
                <p className="font-bold text-base flex flex-col">
                  {t(`Employment type`)}
                  <span className="text-[#246BFD] font-medium mt-2">
                    {t(`${jobs?.jobType}`)}
                  </span>
                </p>
              </li>
              <li className="flex justify-between items-center">
                <p className="font-bold text-base flex flex-col">
                  {t(`Educational`)}

                  <span className="text-[#246BFD] font-medium mt-2">
                    {t(`${jobs?.educationLevel}`)}
                  </span>
                </p>
                <p className="font-bold text-base flex flex-col">
                  {t(`Experience`)}

                  <span className="text-[#246BFD] font-medium mt-2">
                    {t(`${jobs?.experience}`)}
                  </span>
                </p>
              </li>
              <li className="flex justify-between items-center">
                <p className="font-bold text-base flex flex-col">
                  {t(`Vacancy`)}
                  <span className="text-[#246BFD] font-medium mt-2">
                    {t(`${jobs?.numberOfVacancies}`)}
                  </span>
                </p>
                <p className="font-bold text-base flex flex-col">
                  {t(`Work type`)}

                  <span className="text-[#246BFD] font-medium mt-2">
                    {t(`${jobs?.workingtype}`)}
                  </span>
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className="relative flex md:hidden justify-between">
          <div className="fixed bottom-5 w-full">
            {hasUserDeleted ? (
              <Link to={`/announ/standart/${id}`}>
                <button
                  className={`shadow-md text-[#f0f5f0] font-bold text-base left-5 bg-[#3776ffc9] rounded-[100px] py-2 px-4 w-full max-w-35`}
                >
                  {t(`Edit`)}
                </button>
              </Link>
            ) : (
              <ApplyJobButton jobId={jobs} />
            )}
          </div>
          {hasUserDeleted ? (
            <button
              onClick={() => handleDelete()}
              className={`shadow-md fixed bottom-5 right-5 text-white font-bold text-base  bg-red-600 rounded-[100px] w-full p-2 max-w-45`}
              type="button"
            >
              {t(`Delete`)}
            </button>
          ) : (
            <Link
              to={loadingChatRoom ? "#" : `/chat/${chatRoomId}`}
              className={`shadow-md text-center fixed bottom-5 right-5 text-white font-bold text-base bg-[#246BFD] rounded-[100px] w-full p-2 max-w-45`}
              type="button"
              onClick={
                loadingChatRoom
                  ? () => toast.error("Tizimga kirish talab etiladi")
                  : null
              }
            >
              {t(`Send a message`)}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
