import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppliedBack } from "../../../components/icon";
import { useDispatch, useSelector } from "react-redux";
import {
  updateJobSeekerProfile,
  fetchCurrentUser,
} from "../../../store/usersApi/usersThunks";
import toast from "react-hot-toast";
import { ButtonSpinner } from "../../../components/buttonSpinner/ButtonSpinner";
import { uzbekistanRegions } from "../../../constants/Region";
import { useTranslation } from "react-i18next";
import { educationStrList } from "../../../constants/EducationList";
import { experienceStrList } from "../../../constants/ExperienceList";
import { workTypeStrList } from "../../../constants/WorkType";
import { WorkTypeJobseeker } from "../../../constants/EditJobseekerList";

const EditProfile = () => {
  const dispatch = useDispatch();
  const { currentUser, status } = useSelector((state) => state.users);
  const [selectedSalary, setSelectedSalary] = useState("Kelishiladi");
  const [salaryModalOpen, setSalaryModalOpen] = useState(false);

  const [formData, setFormData] = useState({
    jobTitle: "",
    fullName: "",
    gender: "",
    birthday: "",
    location: "",
    expectedSalary: "",
    skills: [],
    professions: [],
    educationalBackground: "",
    workingExperience: "",
    employmentType: "",
  });

  const user = currentUser?.data;

  useEffect(() => {
    if (user) {
      setFormData({
        jobTitle: user.jobSeeker?.jobTitle || "",
        fullName: user?.fullName || "",
        gender: user?.gender || "",
        birthday: user?.birthday || "",
        location: user?.location || "",
        expectedSalary: user.jobSeeker?.expectedSalary || "",
        skills: user.jobSeeker?.skills || [],
        professions: user.jobSeeker?.professions || [],
        educationalBackground: user.jobSeeker?.educationalBackground || "",
        workingExperience: user.jobSeeker?.workingExperience || "",
        employmentType: user.jobSeeker?.employmentType || "",
      });
    }
  }, [user]);

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked
          ? [...prevData[name], value]
          : prevData[name].filter((item) => item !== value),
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const profileData = { jobSeeker: formData };
    dispatch(updateJobSeekerProfile(profileData))
      .then((response) => {
        if (response?.payload?.result === "success") {
          toast.success("Ma'lumotlar yangilandi!");
        }
      })
      .catch((error) => {
        console.error("Error updating profile: ", error);
      });
  };

  const handleSalaryChange = (e) => {
    console.log(e.target.textContent);
    setFormData({
      ...formData,
      expectedSalary: e.target.textContent,
    });
  };

  const salaryRangesUz = [
    "any_salary",
    "salary_negotiable",
    "less_than_1_million",
    "1_to_3_million",
    "3_to_5_million",
    "5_to_7_million",
    "7_to_10_million",
    "10_to_15_million",
    "15_to_25_million",
    "more_than_25_million",
  ];

  const profs = [
    "Ingliz tili o'qituvchisi",
    "Muhandis",
    "UI dasturchi",
    "Yordamchi",
    "Menejer",
    "Rus tili o'qituvchisi",
    "Dasturlash muhandis",
  ];

  const { t } = useTranslation();

  return (
    <div className="mb-10">
      <Link
        to={"/profile"}
        className="flex gap-4 items-center font-bold text-xl"
      >
        <AppliedBack />
        {t("Fill Your Profile")}
      </Link>
      <div className="mx-auto bg-white  mt-10 shadow-md rounded-md p-5">
        <form onSubmit={handleSubmit}>
          <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5">
            <div>
              <label className="block text-gray-700">{t("Job title")}</label>
              <input
                type="text"
                name="jobTitle"
                value={formData.jobTitle}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label className="block text-gray-700">{t("Full name")}</label>
              <input
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label className="block text-gray-700">{t("Gender")}</label>
              <select
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              >
                <option disabled value="">
                  Jinsni tanlash
                </option>
                <option value="Male">{t("Male")}</option>
                <option value="Female">{t("Female")}</option>
              </select>
            </div>
            <div>
              <label className="block text-gray-700">
                {t("Date of Birth")}
              </label>
              <input
                type="date"
                name="birthday"
                value={formData.birthday}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label className="block text-gray-700">
                {t("Choose your location")}
              </label>
              <select
                name="location"
                value={formData.location}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              >
                {uzbekistanRegions.map((region) => (
                  <option key={region} value={region}>
                    {t(`${region}`)}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-gray-700">
                {t("Salary Description")}
              </label>
              <button
                type="button"
                onClick={() => setSalaryModalOpen(true)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500  text-gray-700 rounded-md"
              >
                {t(`${formData.expectedSalary}`)}
              </button>
            </div>
            
            <div>
              <label className="block text-gray-700">{t("Education")}</label>
              <select
                name="educationalBackground"
                value={formData.educationalBackground}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              >
                <option value="">O'qish joyni tanlash</option>
                {educationStrList.map((level) => (
                  <option key={level} value={level}>
                    {t(`${level}`)}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-gray-700">{t("Experience")}</label>
              <select
                name="workingExperience"
                value={formData.workingExperience}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              >
                <option disabled value="">
                  Ish tajriba tanlash
                </option>
                {experienceStrList.map((level) => (
                  <option key={level} value={level}>
                    {t(`${level}`)}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-gray-700">
                {t("Employment Type")}
              </label>
              <select
                name="employmentType"
                value={formData.employmentType}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              >
                <option disabled value="">
                  Tanlash
                </option>
                {WorkTypeJobseeker.map((level) => (
                  <option key={level} value={level}>
                    {t(`${level}`)}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-gray-700">{t("Professions")}</label>
              <div className="mt-1">
                {profs.map((profession) => (
                  <label
                    key={profession}
                    className="inline-flex items-center mr-4"
                  >
                    <input
                      type="checkbox"
                      name="professions"
                      value={profession}
                      checked={formData.professions.includes(profession)}
                      onChange={handleChange}
                      className="form-checkbox"
                    />
                    <span className="ml-2">{profession}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
          <div className="text-center mt-5">
            <button
              disabled={status === "loading" ? true : false}
              type="submit"
              className="shadow text-white font-medium bg-blue-600 h-10 w-full mx-auto flex justify-center text-center hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300  rounded-lg text-sm px-5 py-2.5 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              {status === "loading" ? <ButtonSpinner /> : t("Edit")}
            </button>
          </div>
        </form>
      </div>

      {salaryModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="bg-white w-[90%] p-4 rounded-lg z-10">
            <div className="space-y-2">
              {salaryRangesUz.map((option) => (
                <button
                  key={option}
                  type="button"
                  onClick={(e) => {
                    handleSalaryChange(e);
                    setSelectedSalary(option);
                    setSalaryModalOpen(false);
                  }}
                  className="block w-full text-left pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  {t(`${option}`)}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditProfile;
