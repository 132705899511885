// store/jobsApi/jobsThunks.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

export const fetchAllJobs = createAsyncThunk(
  "jobsApi/fetchAllJobs",
  async (filters) => {
    const response = await axios.get("/jobs", {
      params: filters, // Spread the filters object to include all filter parameters
    });
    return {
      jobs: response.data.data, // Adjust based on actual response structure
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
      totalDocuments: response.data.pagination.totalDocuments,
    };
  }
);

export const fetchJobsMe= createAsyncThunk(
  "quickJobsApi/fetchJobsMe",
  async (filters) => {
    const response = await axios.get("/jobs/myJobs", {
      params: {
        page: 1,
        limit: 10,
      }, // Spread the filters object to include all filter parameters
    });
    return {
      myJobs: response.data.data, // Adjust based on actual response structure
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
      totalDocuments: response.data.pagination.totalDocuments,
    };
  }
);



export const fetchAllJobsId = createAsyncThunk(
  "jobsApi/fetchAllJobsId",
  async (id) => {
    const response = await axios.get(`/jobs/${id}`);
    return {
      jobs: response.data.data, // Adjust based on actual response structure
    };
  }
);

export const createJob = createAsyncThunk(
  "jobsApi/createJob",
  async (newJob) => {
    const response = await axios.post("/jobs", newJob);
    return response.data.data; // Adjust based on actual response structure
  }
);

export const searchJobs = createAsyncThunk(
  "jobsApi/searchJobs",
  async (filters) => {
    const response = await axios.get("/jobs/search", {
      params: filters, // Spread the filters object to include all filter parameters
    });
    // console.log("filters:=>> ", filters);
    return {
      searchJob: response.data.data, // Adjust based on actual response structure
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
      totalDocuments: response.data.pagination.totalDocuments,
    };
  }
);

export const updateJobById = createAsyncThunk(
  "jobsApi/updateJobById",
  async ({ id, updatedJob }) => {
    const response = await axios.patch(`/jobs/${id}`, updatedJob);
    return response.data.data; // Adjust based on actual response structure
  }
);

export const deleteJobById = createAsyncThunk(
  "jobsApi/deleteJobById",
  async (id) => {
    await axios.delete(`/jobs/${id}`);
    return id; // Return the deleted job ID
  }
);

// Add this thunk to apply for a job
export const applyForJob = createAsyncThunk(
  "jobsApi/applyForJob",
  async (jobID) => {
    const response = await axios.post(`/jobs/${jobID}/apply`);
    return response.data; // Adjust based on actual response structure
  }
);

export const fetchEmployerPosts = createAsyncThunk(
  "quickJobsApi/fetchEmployerPosts",
  async ({ page = 1, limit = 10 }) => {
    const response = await axios.get("/jobs/myJobs", {
      params: { page, limit },
    });
    return {
      employerPosts: response.data.data, // Adjust based on actual response structure
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
      totalDocuments: response.data.pagination.totalDocuments,
    };
  }
);

export const favoriteJobs = createAsyncThunk(
  "usersApi/favoriteJobs",
  async (id) => {
    const response = await axios.post(`/users/favoriteJob/${id}`);
    return response.data;
  }
);

export const removeFavoriteJobs = createAsyncThunk(
  "usersApi/removeFavoriteJobs",
  async (id) => {
    const response = await axios.delete(`/users/favoriteJob/${id}`);
    return response.data;
  }
);


