import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  updateEmployerProfile,
  fetchCurrentUser,
} from "../../../store/usersApi/usersThunks";
import { ButtonSpinner } from "./../../../components/buttonSpinner/ButtonSpinner";
import { AppliedBack, HRArrow } from "../../../components/icon";
import toast from "react-hot-toast";
import { uzbekistanRegions } from "./../../../constants/Region";
import { fieldsEmployer } from "../../../constants/FieldsEmployer";
import { useTranslation } from "react-i18next";

const EditEmployerProfile = () => {
  const dispatch = useDispatch();
  // const [phoneNumber, setPhoneNumber] = useState("+998");

  const { currentUser, status } = useSelector((state) => state.users);
  const [formData, setFormData] = useState({
    fullName: "",
    companyName: "",
    aboutcompany: "",
    industry: "",
    location: "",
  });

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, [dispatch]);

  useEffect(() => {
    const user = currentUser?.data;
    setFormData({
      fullName: user?.fullName || "",
      companyName: user?.employer?.companyName || "",
      aboutcompany: user?.employer?.aboutCompany || "",
      industry: user?.employer?.industry || "",
      contactNumber: user?.employer?.contactNumber || "",
      location: user?.location || "",
    });
  }, [currentUser]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) =>
      type === "checkbox"
        ? {
            ...prevFormData,
            [name]: checked
              ? [...(prevFormData[name] || []), value]
              : (prevFormData[name] || []).filter((item) => item !== value),
          }
        : { ...prevFormData, [name]: value }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { fullName, companyName, aboutcompany, location, industry } =
      formData;
    const profileData = {
      fullName,
      companyName,
      aboutcompany,
      industry,
      location,
    };

    dispatch(updateEmployerProfile(profileData))
      .then((response) => {
        response?.payload?.result === "success"
          ? toast.success("Ma'lumotlar yangilandi") &&
            dispatch(fetchCurrentUser())
          : toast.error("Failed to update profile.");
      })
      .catch(() => toast.error("Error updating profile."));
  };

  const employer = currentUser?.data;
  const { t } = useTranslation();

  return (
    <div className="mb-10">
      <Link
        to={"/profile"}
        className="flex gap-4 items-center font-bold text-xl"
      >
        <AppliedBack />
        {t("Fill Your Profile")}
      </Link>
      <div className="mx-auto bg-white mt-10 shadow-md rounded-md p-5">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid gap-5 xl:grid-cols-3 md:grid-cols-2 grid-cols-1">
            <div>
              <label className="block text-gray-700"> {t("Name")}</label>
              <input
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label className="block text-gray-700">{t("Company name")}</label>
              <input
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
           
            <div>
              <label className="block text-gray-700">
                {t("Choose your industry")}
              </label>
              <select
                name="industry"
                value={formData.industry}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              >
                <option disabled value="">
                  {t("Choose your industry")}
                </option>
                {fieldsEmployer.map((field) => (
                  <option key={field} value={field}>
                    {field}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-gray-700">
                {t("Choose your location")}
              </label>
              <select
                name="location"
                value={formData.location}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              >
                <option disabled value="">
                  Manzil tanlash
                </option>
                {uzbekistanRegions.map((region) => (
                  <option key={region} value={region}>
                    {t(`${region}`)}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-gray-700">
                {t("About your company")}
              </label>
              <textarea
                name="aboutcompany"
                value={formData.aboutcompany}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>
          <div>
            <div className="flex justify-center mb-2">
              <p className="text-gray-700">{t("Contact_Company")}</p>
            </div>{" "}
            <h3 className="font-semibold">{t("My_Company")}</h3>
            {employer?.company ? (
              <Link
                className="flex  items-center justify-between mt-4"
                to={`/profile/company/`}
                key={employer?.company._id}
              >
                <div className="flex gap-4">
                  <img
                    className="w-10 h-10 rounded-full"
                    alt={employer?.company.name}
                    src={employer?.company?.logo[0]}
                  />
                  <div>
                    <p className="text-black text-xl font-semibold">
                      {employer?.company.name}
                    </p>
                    <p className="text-[#878787] text-base h-5 font-semibold">
                      {employer?.company.size} ishchi
                    </p>
                  </div>
                </div>
                <HRArrow />
              </Link>
            ) : (
              <div>
                <hr className="my-2" />
                <p className="text-gray-700">{t("Find_Verified_Company")}</p>
                <Link
                  to={"/profile/apply/company"}
                  className="bg-[#2A5771] block m-auto w-16 mt-2 text-white text-xs px-4 py-1 rounded-3xl"
                >
                  {t("Dishes Name")}
                </Link>
                <hr className="my-2" />
              </div>
            )}
          </div>
          <div className="text-center">
            <button
              disabled={status === "loading"}
              type="submit"
              className="shadow text-white font-medium bg-blue-600 h-10 w-full mx-auto flex justify-center text-center hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 rounded-lg text-sm px-5 py-2.5 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              {status === "loading" ? <ButtonSpinner /> : t("Edit")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditEmployerProfile;
