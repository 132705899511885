import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

// Fetch summary
export const fetchSummary = createAsyncThunk(
  "summary/fetchSummary",
  async () => {
    const response = await axios.get("/users/resume/summary");
    console.log("fetchSummary", response.data);
    return response.data; // Assuming the response contains { data: { summary: [...] } }
  }
);

// Add summary
export const addSummary = createAsyncThunk(
  "summary/addSummary",
  async (summary) => {
    console.log("addSummary", summary);
    const response = await axios.post("/users/resume/summary", summary);
    return response.data; // Assuming the response contains the added summary
  }
);

// Update summary by id
export const updateSummary = createAsyncThunk(
  "summary/updateSummary",
  async ({ id, summary }) => {
    const response = await axios.put(`/users/resume/summary/${id}`, {
      summary: summary.summary,
    });
    return response.data; // Assuming the response contains the updated summary
  }
);

// Delete summary by id
export const deleteSummary = createAsyncThunk(
  "summary/deleteSummary",
  async (id) => {
    const response = await axios.delete(`/users/resume/summary/${id}`);
    return response.data; // Assuming the response contains the updated summary list
  }
);
