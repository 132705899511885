import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { persistor, store } from "./store/store";
import { BrowserRouter } from "react-router-dom";
import Routers from "./Routers";
import Loading from "./components/Loading";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Toaster } from "react-hot-toast";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <I18nextProvider  i18n={i18n}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Loading />
          <Toaster />
          <Routers tab='home' />
        </BrowserRouter>
      </PersistGate>
    </I18nextProvider>
  </Provider>
);
