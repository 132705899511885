import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

// Fetch projects
export const fetchProjects = createAsyncThunk(
  "projects/fetchProjects",
  async () => {
    const response = await axios.get("/users/resume/projects");
    console.log("fetchProjects", response.data);
    return response.data; // Assuming the response contains { data: { projects: [...] } }
  }
);

// Add project
export const addProject = createAsyncThunk(
  "projects/addProject",
  async (project) => {
    console.log("addProject", project);
    const response = await axios.post("/users/resume/projects", project);
    return response.data; // Assuming the response contains the added project
  }
);

// Update project by id
export const updateProject = createAsyncThunk(
  "projects/updateProject",
  async ({ id, project }) => {
    const response = await axios.put(`/users/resume/projects/${id}`, {
      title: project.title,
      description: project.description,
      role: project.role,
      technologies: project.technologies,
      startDate: project.startDate,
      endDate: project.endDate,
      current: project.current,
      link: project.link,
    });
    return response.data; // Assuming the response contains the updated project
  }
);

// Delete project by id
export const deleteProject = createAsyncThunk(
  "projects/deleteProject",
  async (id) => {
    const response = await axios.delete(`/users/resume/projects/${id}`);
    return response.data; // Assuming the response contains the updated projects list
  }
);
