/* eslint-disable react/style-prop-object */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import EmployerCard from "../../../components/cards/EmployerCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchExperiencedJobSeekers } from "../../../store/jobSeekersApi/jobSeekersThunks";
import { useTranslation } from "react-i18next";

const Experienced = () => {
  const dispatch = useDispatch();
  const { experiencedJobSeekers, currentPage, status } = useSelector(
    (state) => state.jobSeekers
  );

  useEffect(() => {
    dispatch(fetchExperiencedJobSeekers({ page: currentPage, pageSize: 10 }));
  }, [dispatch, currentPage]);
  const { t } = useTranslation();

  return (
    <div className=" overflow-hidden mx-auto  hero-banner mb-10">
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-black text-lg font-bold ">{t("Experienced Talents")}</h1>
        <Link className="text-blue-600 text-base font-bold " to={"/jobseekers/expreienced"}>
          {t("All")}
        </Link>
      </div>

      <div>
        <EmployerCard data={experiencedJobSeekers} loading={status} />
      </div>
    </div>
  );
};

export default Experienced;
