// store/jobsApi/quickJobsSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAllQuickJobs,
  createQuickJob,
  searchQuickJobs,
  updateQuickJobById,
  deleteQuickJobById,
  applyForQuickJob,
  fetchEmployerPosts,
  fetchAllQuickJobsId,
  fetchQuickJobsMe,
  favoriteQuickJobs,
  fetchCandidates,
  removeFavoriteQuickJobs,
} from "./quickJobsThunks"; // Adjust the path according to your structure

const initialState = {
  quickJobs: [],
  quickJobsMe: [],
  employerPosts: [],
  searchQuickJobs: [],
  candidates: [],
  singleQuickJob: null,
  currentPage: 1,
  totalPages: 1,
  totalDocuments: 0,
  searchTotalDocuments: 0,
  employerTotalDocuments: 0,
  employerTotalPages: 1,
  status: "idle",
  searchStatus: "idle",
  applicationStatus: "idle",
  employerPostsStatus: "idle",
  error: null,
  filters: {
    page: 1,
    limit: 10,
    recommended: false,
    jobTitle: "",
  },
};

const quickJobsSlice = createSlice({
  name: "quickJobsApi",
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuickJobsMe.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchQuickJobsMe.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.quickJobsMe = action.payload.quickJobsMe;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
        state.totalDocuments = action.payload.totalDocuments;
      })
      .addCase(fetchQuickJobsMe.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAllQuickJobs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllQuickJobs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.quickJobs = action.payload.quickJobs;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
        state.totalDocuments = action.payload.totalDocuments;
      })
      .addCase(fetchAllQuickJobs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAllQuickJobsId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllQuickJobsId.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.quickJobsId = action.payload.quickJobsId;
      })
      .addCase(fetchAllQuickJobsId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createQuickJob.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createQuickJob.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.quickJobs.push(action.payload); // Add the new job to the list
      })
      .addCase(createQuickJob.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(searchQuickJobs.pending, (state) => {
        state.searchStatus = "loading";
      })
      .addCase(searchQuickJobs.fulfilled, (state, action) => {
        state.searchStatus = "succeeded";
        state.searchQuickJobs = action.payload.searchQuickJobs;
        state.searchTotalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
        state.searchTotalDocuments = action.payload.totalDocuments;
      })
      .addCase(searchQuickJobs.rejected, (state, action) => {
        state.searchStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(updateQuickJobById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateQuickJobById.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.quickJobs.findIndex(
          (quickJob) => quickJob._id === action.payload._id
        );
        if (index !== -1) {
          state.quickJobs[index] = action.payload; // Update the job in the list
        }
      })
      .addCase(updateQuickJobById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteQuickJobById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteQuickJobById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.quickJobs = state.quickJobs.filter(
          (quickJob) => quickJob._id !== action.payload
        ); // Remove the deleted job from the list
      })
      .addCase(deleteQuickJobById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(applyForQuickJob.pending, (state) => {
        state.applicationStatus = "loading";
      })
      .addCase(applyForQuickJob.fulfilled, (state) => {
        state.applicationStatus = "succeeded";
      })
      .addCase(applyForQuickJob.rejected, (state, action) => {
        state.applicationStatus = "failed";
        state.error = action.error;
      })
      .addCase(fetchEmployerPosts.pending, (state) => {
        state.employerPostsStatus = "loading";
      })
      .addCase(fetchEmployerPosts.fulfilled, (state, action) => {
        state.employerPostsStatus = "succeeded";
        state.employerPosts = action.payload.employerPosts;
        state.employerTotalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
        state.employerTotalDocuments = action.payload.totalDocuments;
      })
      .addCase(fetchEmployerPosts.rejected, (state, action) => {
        state.employerPostsStatus = "failed";
        state.error = action.error.message;
      })

      .addCase(favoriteQuickJobs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(favoriteQuickJobs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(favoriteQuickJobs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.quickJobs = action.payload; // Update currentUser or handle as needed
      })

      .addCase(removeFavoriteQuickJobs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(removeFavoriteQuickJobs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeFavoriteQuickJobs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.quickJobs = action.payload; // Update currentUser or handle as needed
      })

      .addCase(fetchCandidates.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCandidates.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.candidates = action.payload.candidates;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(fetchCandidates.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
  },
});

export const { setCurrentPage, setFilters } = quickJobsSlice.actions;
export default quickJobsSlice.reducer;
