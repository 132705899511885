import { createSlice } from "@reduxjs/toolkit";
import {
  fetchJobSeekers,
  fetchRecommendedJobSeekers,
  fetchExperiencedJobSeekers,
  fetchJobSeekersSavedJob,
  deleteJobSeekersSavedJob,
  fetchAllJobSeekers,
  fetchJobSeekersFavorite,
} from "./jobSeekersThunks"; // Adjust the path according to your structure

const initialState = {
  jobSeekers: [],
  jobSeeker: [],
  recommendedJobSeekers: [],
  experiencedJobSeekers: [],
  savedJobSeekers: [],
  favoriteJobSeekers: [],
  currentPage: 1,
  totalPages: 1,
  status: "idle",
  error: null,
  singleUser: null,
  currentUser: null,
  filters: {
    sortByAlphabet: false,
    skills: "",
    location: "",
    worktype: "",
    jobtitle: "",
    employmentType: "",
    experience: "",
    education: "",
    expectedSalary: "",
  },
};

const jobSeekersSlice = createSlice({
  name: "usersApi",
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllJobSeekers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllJobSeekers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.jobSeeker = action.payload.jobSeeker;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(fetchAllJobSeekers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchJobSeekers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchJobSeekers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.jobSeekers = action.payload.jobSeekers;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(fetchJobSeekers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchRecommendedJobSeekers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRecommendedJobSeekers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.recommendedJobSeekers = action.payload.recommendedJobSeekers;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(fetchRecommendedJobSeekers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchExperiencedJobSeekers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchExperiencedJobSeekers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.experiencedJobSeekers = action.payload.experiencedJobSeekers;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(fetchExperiencedJobSeekers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchJobSeekersSavedJob.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchJobSeekersSavedJob.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.savedJobSeekers = action.payload.savedJobSeekers;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(fetchJobSeekersSavedJob.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteJobSeekersSavedJob.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteJobSeekersSavedJob.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.savedJobSeekers = state.savedJobSeekers.filter(
          (jobSeeker) => jobSeeker.id !== action.payload
        );
      })
      .addCase(deleteJobSeekersSavedJob.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(fetchJobSeekersFavorite.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchJobSeekersFavorite.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.favoriteJobSeekers = action.payload.favoriteJobSeekers;
      })
      .addCase(fetchJobSeekersFavorite.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setCurrentPage, setFilters } = jobSeekersSlice.actions;
export default jobSeekersSlice.reducer;
