// store/openApiThunks.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure
import { resetSearchJob, resetSearchQuickJobs } from "./openApiSlice";

export const fetchJobs = createAsyncThunk(
  "openApi/fetchJobs",
  async ({ page, pageSize, jobTitle, dispatch }) => {
    const response = await axios.get("/jobs", {
      params: {
        page,
        pageSize,
        jobTitle,
      },
    });
    if (response?.data.data.length === 0) {
      dispatch(resetSearchJob());
    }
    return {
      jobs: response.data.data, // Adjust based on actual response structure
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
      totalCount: response.data.totalCount,
    };
  }
);

export const fetchBanner = createAsyncThunk("openApi/fetchBanner", async () => {
  const response = await axios.get("/banner", {});
  return {
    banner: response?.data?.data,
  };
});

export const fetchQuickJobs = createAsyncThunk(
  "openApi/fetchQuickJobs",
  async ({ page, pageSize, jobTitle, dispatch }) => {
    const response = await axios.get("/quickJobs", {
      params: {
        page,
        pageSize,
        jobTitle,
      },
    });
    if (response?.data.data.length === 0) {
      dispatch(resetSearchQuickJobs());
    }
    return {
      quickJobs: response.data.data, // Adjust based on actual response structure
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
      allResult: response.data.pagination.totalDocuments,
      message: response.data.msg,
    };
  }
);

export const fetchRecentQuickJobs = createAsyncThunk(
  "openApi/fetchRecentQuickJobs",
  async ({ page, pageSize, jobTitle, dispatch }) => {
    const response = await axios.get("/quickJobs?recentJob=true", {
      params: {
        page,
        pageSize,
        jobTitle,
      },
    });
    if (response?.data.data.length === 0) {
      dispatch(resetSearchQuickJobs());
    }
    return {
      recentJob: response.data.data, // Adjust based on actual response structure
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
      message: response.data.msg,
    };
  }
);