
import { MdDashboard, MdOutlineMessage } from 'react-icons/md';
import { IoMdAddCircleOutline } from "react-icons/io";
import { CgProfile } from "react-icons/cg";



const links = [
  { id: 1, text: 'Bosh sahifa', path: '/', icon: <MdDashboard />
  },
  { id: 2, text: "E'lon joylash", path: 'announ', icon: <IoMdAddCircleOutline /> },
  { id: 3, text: 'Xabarlar', path: 'chat', icon: <MdOutlineMessage /> },
  { id: 4, text: 'Profil', path: 'profile', icon: <CgProfile /> },
];

export default links;
