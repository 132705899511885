import { createSlice } from "@reduxjs/toolkit";
import { fetchCVFiles, uploadCVFile, deleteCVFile } from "./cvFilesThunks"; // Adjust the path according to your structure

const initialState = {
  cvFiles: [],
  status: "idle",
  error: null,
};

const cvFilesSlice = createSlice({
  name: "cvFiles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCVFiles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCVFiles.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.cvFiles = action.payload.data.cvFiles || []; // Ensure cvFiles is an array
      })
      .addCase(fetchCVFiles.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(uploadCVFile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(uploadCVFile.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload.data && action.payload.data.cvFiles) {
          state.cvFiles.push(action.payload.data.cvFiles); // Ensure cvFiles is an array
        } else {
          state.error = "Failed to upload CV file";
        }
      })
      .addCase(uploadCVFile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteCVFile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCVFile.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.cvFiles = state.cvFiles.filter(
          (cvFile) => cvFile.id !== action.payload.data.id
        ); // Ensure cvFiles is an array
      })
      .addCase(deleteCVFile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default cvFilesSlice.reducer;
