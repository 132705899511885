import React, { useEffect, useRef, useState } from "react";
import logo from "../../assets/img/logo-banner.png";
import Select from "react-select";
import { Link } from "react-router-dom";
import playMarket from "../../assets/img/play-market.jpg";
import appStore from "../../assets/img/app-store.jpg";
import quickJobsImg from "../../assets/img/quickjobs.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchQuickJobs } from "../../store/openApi/openApiThunk";
import { customStyles, options } from "../../constants/SelectStyles";

const ResultSearch = () => {
  const dispatch = useDispatch();
  const { quickJobs, currentPage, totalPages, allResult } = useSelector(
    (state) => state.openApi
  );
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(fetchQuickJobs({ page: currentPage, pageSize: 10 }));
  }, [dispatch, currentPage]);

  const maskPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
    const maskedPart = "****";
    return phoneNumber.slice(0, -4) + maskedPart;
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.trim() === "") {
      dispatch(fetchQuickJobs({ page: 1, pageSize: 10 }));
    } else {
      dispatch(
        fetchQuickJobs({
          page: 1,
          pageSize: 10,
          jobTitle: searchTerm.trim(),
        })
      );
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getUTCDate();
    const year = date.getUTCFullYear();

    const months = [
      "Yanvar",
      "Fevral",
      "Mart",
      "Aprel",
      "May",
      "Iyun",
      "Iyul",
      "Avgust",
      "Sentabr",
      "Oktabr",
      "Noyabr",
      "Dekabr",
    ];

    const month = months[date.getUTCMonth()];
    const formattedDay = day < 10 ? `0${day}` : day;

    return `${formattedDay} ${month} ${year}`;
  };

  const handlePageClick = (page) => {
    dispatch(
      fetchQuickJobs({ page, pageSize: 10, jobTitle: searchTerm.trim() })
    );
  };

  const renderPagination = () => {
    const pages = [];
    const totalVisiblePages = 5;
    const ellipsis = "...";

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= currentPage - 2 && i <= currentPage + 2)
      ) {
        pages.push(
          <button
            key={i}
            onClick={() => handlePageClick(i)}
            className={`px-4 py-2 text-sm font-medium bg-[#FFF] shadow-custom hover:bg-gray-50' ${
              i === currentPage
                ? "bg-gray-300 text-gray-700"
                : "bg-white text-gray-700 hover:bg-gray-50"
            }`}
          >
            {i}
          </button>
        );
      } else if (
        (i === currentPage - 3 && currentPage > totalVisiblePages) ||
        (i === currentPage + 3 && currentPage < totalPages - totalVisiblePages)
      ) {
        pages.push(
          <span
            key={i}
            className="px-4 py-2 text-sm font-medium bg-white text-gray-700"
          >
            {ellipsis}
          </span>
        );
      }
    }

    return pages;
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const drawerRef = useRef();

  const handleButtonClick = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleClickOutside = (event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      setIsDrawerOpen(false);
    }
  };

  useEffect(() => {
    if (isDrawerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.body.style.background = "";
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.body.style.background = "";
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDrawerOpen]);

  console.log(quickJobs);

  return (
    <div>
      <header
        className="py-2"
        style={{
          background:
            "linear-gradient(90deg, #2A64A8 0%, #102742 46%, #2A64A8 99.5%)",
        }}
      >
        <nav className="xl:px-5 lg:px-10 md:px-10 px-5 flex justify-between items-center gap-5  overflow-hidden mx-auto">
          <Link to={"/"} className="flex items-center gap-6">
            <img src={logo} alt="logo" />
            <p className="text-[#FFF] w-27 font-[Caveat] xl:text-[30px] lg:text-[30px] text-[20px] not-italic font-normal">
              Top Ish toping
            </p>
          </Link>
          <form
            className="flex w-full max-w-150 items-center gap-6"
            onSubmit={handleSubmit}
          >
            <input
              className="w-full px-8 max-w-[538px] rounded-[20px] placeholder:text-[#3C3C3C] placeholder:text-lg border-[1px] border-[solid] border-[#6499EA] bg-[rgba(255,_255,_255,_0.68)]"
              type="text"
              placeholder="Izlash"
              value={searchTerm}
              onInput={handleInputChange}
            />
            <button className="rounded-[7px] py-2 xl:px-10 lg:px-10 px-4 bg-[#FFF] text-[#234F6F] text-center text-[18px] not-italic font-normal leading-[normal]">
              Izlash
            </button>
          </form>
          <p className="text-[#FFF] text-center cursor-pointer text-[14px] not-italic font-medium leading-[normal]">
            Men xodim izlayabman
          </p>
        </nav>
      </header>

      <div className="flex gap-4 pr-4 overflow-hidden mx-auto">
        <div className="w-3/12 xl:block lg:block hidden bg-[#F9F9F9] p-4    min-h-full">
          <h3 className="text-[#212121] text-[20px] not-italic font-bold leading-[120%]">
            Manzil va Maosh
          </h3>
          <div className="relative py-4">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
              <svg
                className="text-gray-400"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.91406 8.59846C2.91406 4.76523 6.1173 1.66699 9.99193 1.66699C13.8775 1.66699 17.0807 4.76523 17.0807 8.59846C17.0807 10.5301 16.3782 12.3233 15.222 13.8433C13.9464 15.5199 12.3742 16.9807 10.6045 18.1273C10.1995 18.3923 9.83396 18.4123 9.38944 18.1273C7.60968 16.9807 6.03747 15.5199 4.77281 13.8433C3.61572 12.3233 2.91406 10.5301 2.91406 8.59846ZM7.65925 8.81428C7.65925 10.0984 8.70711 11.1084 9.99193 11.1084C11.2776 11.1084 12.3355 10.0984 12.3355 8.81428C12.3355 7.54015 11.2776 6.48102 9.99193 6.48102C8.70711 6.48102 7.65925 7.54015 7.65925 8.81428Z"
                  fill="#212121"
                />
              </svg>{" "}
            </div>
            <Select
              options={options}
              styles={customStyles}
              placeholder="Tanlang"
              className="pl-10"
              isSearchable={false} // Disable text input
              components={{ IndicatorSeparator: () => null }} // Remove default indicator separator
            />
          </div>
          <div class="z-10 mt-5">
            <h3 className="text-[#212121] text-[20px] not-italic font-bold leading-[120%]">
              Ta'lim darajasi{" "}
            </h3>
            <ul
              class="space-y-2 mt-5 text-sm flex flex-col gap-2"
              aria-labelledby="dropdownDefault"
            >
              <li class="flex items-center">
                <input
                  id="canon1"
                  type="checkbox"
                  value=""
                  class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                />

                <label
                  for="canon1"
                  class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                >
                  O'rta maktabdan kamroq{" "}
                </label>
              </li>
              <li class="flex items-center">
                <input
                  id="high"
                  type="checkbox"
                  value=""
                  class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                />

                <label
                  for="hign"
                  class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                >
                  O'rta maktab
                </label>
              </li>
              <li class="flex items-center">
                <input
                  id="doctor"
                  type="checkbox"
                  value=""
                  class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                />

                <label
                  for="doctor"
                  class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                >
                  Doktorlik yoki professional daraja
                </label>
              </li>
              <li class="flex items-center">
                <input
                  id="bachelor"
                  type="checkbox"
                  value=""
                  class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                />

                <label
                  for="bachelor"
                  class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                >
                  Bakalavr diplomi{" "}
                </label>
              </li>
              <li class="flex items-center">
                <input
                  id="docent"
                  type="checkbox"
                  value=""
                  class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                />

                <label
                  for="docent"
                  class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                >
                  Dotsent darajasi{" "}
                </label>
              </li>
              <li class="flex items-center">
                <input
                  id="magistr"
                  type="checkbox"
                  value=""
                  class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                />

                <label
                  for="magistr"
                  class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                >
                  Magistr diplomi{" "}
                </label>
              </li>
            </ul>
          </div>
          <div className="mt-8">
            <h3 className="text-[#212121] text-[20px] not-italic font-bold leading-[120%]">
              Ish Ko’rinishi{" "}
            </h3>
            <label className="flex items-center mt-3 cursor-pointer">
              <input
                type="radio"
                className="form-radio h-5 w-5 text-blue-600"
                name="workType"
                value="onsite"
                // checked={checked}
                // onChange={onChange}
              />
              <span className="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]">
                Ofisdan ish
              </span>
            </label>
            <label className="flex items-center mt-3 cursor-pointer">
              <input
                type="radio"
                className="form-radio h-5 w-5 text-blue-600"
                name="workType"
                value="remote"
              />
              <span className="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]">
                Masofaviy
              </span>
            </label>
          </div>
          <div class="z-10 mt-8">
            <h3 className="text-[#212121] text-[20px] not-italic font-bold leading-[120%]">
              Ta'lim darajasi{" "}
            </h3>
            <ul
              class="space-y-2 mt-5 text-sm flex flex-col gap-2"
              aria-labelledby="dropdownDefault"
            >
              <li class="flex items-center">
                <input
                  id="canon1"
                  type="checkbox"
                  value=""
                  class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                />

                <label
                  for="canon1"
                  class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                >
                  O'rta maktabdan kamroq{" "}
                </label>
              </li>
              <li class="flex items-center">
                <input
                  id="high"
                  type="checkbox"
                  value=""
                  class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                />

                <label
                  for="hign"
                  class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                >
                  O'rta maktab
                </label>
              </li>
              <li class="flex items-center">
                <input
                  id="doctor"
                  type="checkbox"
                  value=""
                  class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                />

                <label
                  for="doctor"
                  class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                >
                  Doktorlik yoki professional daraja
                </label>
              </li>
              <li class="flex items-center">
                <input
                  id="bachelor"
                  type="checkbox"
                  value=""
                  class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                />

                <label
                  for="bachelor"
                  class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                >
                  Bakalavr diplomi{" "}
                </label>
              </li>
            </ul>
          </div>
          <div class="z-10 mt-8">
            <h3 className="text-[#212121] text-[20px] not-italic font-bold leading-[120%]">
              Ish grafigi{" "}
            </h3>
            <ul
              class="space-y-2 mt-5 text-sm flex flex-col gap-2"
              aria-labelledby="dropdownDefault"
            >
              <li class="flex items-center">
                <input
                  id="canon12"
                  type="checkbox"
                  value=""
                  class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                />

                <label
                  for="canon12"
                  class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                >
                  To'liq kun
                </label>
              </li>
              <li class="flex items-center">
                <input
                  id="part"
                  type="checkbox"
                  value=""
                  class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                />

                <label
                  for="part"
                  class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                >
                  Yarim kun
                </label>
              </li>
              <li class="flex items-center">
                <input
                  id="frilans"
                  type="checkbox"
                  value=""
                  class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                />

                <label
                  for="frilans"
                  class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                >
                  Frilanser
                </label>
              </li>
              <li class="flex items-center">
                <input
                  id="docs"
                  type="checkbox"
                  value=""
                  class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                />

                <label
                  for="docs"
                  class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                >
                  Shartnoma{" "}
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-8 xl:w-8/12 lg:w-10/12 w-full pl-4">
          <button
            id="updateProductButton"
            className="Filter"
            type="button"
            onClick={handleButtonClick}
          >
            Update product
          </button>
          <h3 className="text-[#000] text-start text-[24px] font-normal">
            So’rov bo’yicha {allResult || 0} ta natija mavjud
          </h3>

          <ul className="mt-5 grid place-items-center grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-5">
            {quickJobs.map((job) => (
              <Link
                key={job?._id}
                to={`/quickjobs/${job?._id}`}
                className="rounded-2xl w-full shadow-md h-40 justify-between flex gap-2"
              >
                <div className="p-4 justify-between flex gap-2">
                  <div className="relative flex flex-col justify-between">
                    <div>
                      <div className="flex justify-between">
                        <h3 className="text-[#242132] text-[14px] text-start not-italic font-semibold leading-[normal]">
                          {job?.title?.substring(0, 20)}...
                        </h3>
                        <p className="m-0 text-[#888] text-[12px] not-italic font-medium leading-[161.023%]">
                          +998{maskPhoneNumber(job?.phoneNumber)}
                        </p>
                      </div>
                      <p className="mt-2 text-[#888] text-[12px] font-medium text-start leading-[161.023%]">
                        {job?.description.substring(0, 50)}...
                      </p>
                    </div>
                    <div className="mt-2 flex justify-between items-center">
                      <p className="text-[#504d4d] text-[12px] font-medium">
                        {formatDate(job?.createdAt)}
                      </p>
                      <p className="text-[#504d4d] text-[12px] font-medium text-end">
                        {job?.location}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="w-20 h-full">
                    <img
                      className="h-full"
                      src={quickJobsImg}
                      alt="quickjobs"
                    />
                  </div>
                </div>
              </Link>
            ))}
          </ul>

          <div className="flex mt-9 justify-center items-center py-4">
            <nav
              className="flex items-center gap-4 shadow-sm -space-x-px"
              aria-label="Pagination"
            >
              {renderPagination()}
              <button
                onClick={() => handlePageClick(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="px-4 py-2 text-sm font-medium bg-[#FFF] shadow-custom hover:bg-gray-50"
              >
                Keyingi
              </button>
            </nav>
          </div>
        </div>

        <div className=" flex-col xl:flex lg:hidden hidden items-center mt-8 w-2/12">
          <div className="sticky top-[10px] rounded-[17px] border-[1px] border-[solid] border-[#66BCFA] py-5 px-4 bg-white shadow-md">
            <h3 className="text-[#2F94CD] text-[17px] text-center not-italic font-semibold">
              Ro’yxatdan o’tganmisiz?
            </h3>
            <ul className="mt-3 flex flex-wrap gap-2">
              <Link
                to={"/profile"}
                className="text-[#5081AF] w-full text-center border border-[#5081AF] inline-block py-2 px-2 rounded-md text-[13px] font-semibold"
              >
                Profilga Kirish
              </Link>
              <Link
                to={"/"}
                className="text-[#C0B75B] w-full text-center border border-[#C0B75B] inline-block py-2 px-2 rounded-md text-[13px] font-semibold"
              >
                CV Yaratish
              </Link>
            </ul>
          </div>

          <div className="mt-5">
            <div className="flex gap-2 mt-2">
              <img
                className="rounded-lg w-30 h-10"
                src={playMarket}
                alt="Play Market"
              />
              <img
                className="rounded-lg w-30 h-10"
                src={appStore}
                alt="App Store"
              />
            </div>
          </div>
        </div>
      </div>
      {isDrawerOpen && (
        <div className="fixed inset-0 z-30 bg-black bg-opacity-50" />
      )}
      <div
        id="drawer-update-product-default"
        ref={drawerRef}
        className={`fixed top-0 left-0 z-40 w-full h-screen max-w-xs p-4 overflow-y-auto transition-transform bg-white dark:bg-gray-800 ${
          isDrawerOpen ? "translate-x-0" : "-translate-x-full"
        }`}
        tabIndex="-1"
        aria-labelledby="drawer-label"
        aria-hidden={!isDrawerOpen}
      >
        <h5
          id="drawer-label"
          className="inline-flex items-center mb-6 text-sm font-semibold text-gray-500 uppercase dark:text-gray-400"
        >
          Filter
        </h5>
        <button
          type="button"
          onClick={handleDrawerClose}
          aria-controls="drawer-update-product-default"
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Close menu</span>
        </button>
        <div className="min-h-full">
          <h3 className="text-[#212121] text-[20px] not-italic font-bold leading-[120%]">
            Manzil va Maosh
          </h3>
          <div className="relative py-4">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
              <svg
                className="text-gray-400"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.91406 8.59846C2.91406 4.76523 6.1173 1.66699 9.99193 1.66699C13.8775 1.66699 17.0807 4.76523 17.0807 8.59846C17.0807 10.5301 16.3782 12.3233 15.222 13.8433C13.9464 15.5199 12.3742 16.9807 10.6045 18.1273C10.1995 18.3923 9.83396 18.4123 9.38944 18.1273C7.60968 16.9807 6.03747 15.5199 4.77281 13.8433C3.61572 12.3233 2.91406 10.5301 2.91406 8.59846ZM7.65925 8.81428C7.65925 10.0984 8.70711 11.1084 9.99193 11.1084C11.2776 11.1084 12.3355 10.0984 12.3355 8.81428C12.3355 7.54015 11.2776 6.48102 9.99193 6.48102C8.70711 6.48102 7.65925 7.54015 7.65925 8.81428Z"
                  fill="#212121"
                />
              </svg>{" "}
            </div>
            <Select
              options={options}
              styles={customStyles}
              placeholder="Tanlang"
              className="pl-10"
              isSearchable={false} // Disable text input
              components={{ IndicatorSeparator: () => null }} // Remove default indicator separator
            />
          </div>
          <div class="z-10 mt-5">
            <h3 className="text-[#212121] text-[20px] not-italic font-bold leading-[120%]">
              Ish tajriba{" "}
            </h3>
            <ul
              class="space-y-2 mt-5 text-sm flex flex-col gap-2"
              aria-labelledby="dropdownDefault"
            >
              <li class="flex items-center">
                <input
                  id="canon"
                  type="checkbox"
                  value=""
                  class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                />

                <label
                  for="canon"
                  class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                >
                  Tajriba yo'q
                </label>
              </li>
              <li class="flex items-center">
                <input
                  id="1-5"
                  type="checkbox"
                  value=""
                  class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                />

                <label
                  for="1-5"
                  class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                >
                  1 - 5 yil
                </label>
              </li>
              <li class="flex items-center">
                <input
                  id="6-10"
                  type="checkbox"
                  value=""
                  class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                />

                <label
                  for="6-10"
                  class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                >
                  6 - 10 yil
                </label>
              </li>
              <li class="flex items-center">
                <input
                  id="more-10"
                  type="checkbox"
                  value=""
                  class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                />

                <label
                  for="more-10"
                  class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                >
                  10 yildan ko'p
                </label>
              </li>
            </ul>
          </div>
          <div className="mt-8">
            <h3 className="text-[#212121] text-[20px] not-italic font-bold leading-[120%]">
              Ish Ko’rinishi{" "}
            </h3>
            <label className="flex items-center mt-3 cursor-pointer">
              <input
                type="radio"
                className="form-radio h-5 w-5 text-blue-600"
                name="workType"
                value="onsite"
                // checked={checked}
                // onChange={onChange}
              />
              <span className="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]">
                Ofisdan ish
              </span>
            </label>
            <label className="flex items-center mt-3 cursor-pointer">
              <input
                type="radio"
                className="form-radio h-5 w-5 text-blue-600"
                name="workType"
                value="remote"
              />
              <span className="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]">
                Masofaviy
              </span>
            </label>
          </div>
          <div class="z-10 mt-8">
            <h3 className="text-[#212121] text-[20px] not-italic font-bold leading-[120%]">
              Ta'lim darajasi{" "}
            </h3>
            <ul
              class="space-y-2 mt-5 text-sm flex flex-col gap-2"
              aria-labelledby="dropdownDefault"
            >
              <li class="flex items-center">
                <input
                  id="canon1"
                  type="checkbox"
                  value=""
                  class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                />

                <label
                  for="canon1"
                  class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                >
                  O'rta maktabdan kamroq{" "}
                </label>
              </li>
              <li class="flex items-center">
                <input
                  id="high"
                  type="checkbox"
                  value=""
                  class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                />

                <label
                  for="hign"
                  class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                >
                  O'rta maktab
                </label>
              </li>
              <li class="flex items-center">
                <input
                  id="doctor"
                  type="checkbox"
                  value=""
                  class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                />

                <label
                  for="doctor"
                  class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                >
                  Doktorlik yoki professional daraja
                </label>
              </li>
              <li class="flex items-center">
                <input
                  id="bachelor"
                  type="checkbox"
                  value=""
                  class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                />

                <label
                  for="bachelor"
                  class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                >
                  Bakalavr diplomi{" "}
                </label>
              </li>
            </ul>
          </div>
          <div class="z-10 mt-8">
            <h3 className="text-[#212121] text-[20px] not-italic font-bold leading-[120%]">
              Ish grafigi{" "}
            </h3>
            <ul
              class="space-y-2 mt-5 text-sm flex flex-col gap-2"
              aria-labelledby="dropdownDefault"
            >
              <li class="flex items-center">
                <input
                  id="canon12"
                  type="checkbox"
                  value=""
                  class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                />

                <label
                  for="canon12"
                  class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                >
                  To'liq kun
                </label>
              </li>
              <li class="flex items-center">
                <input
                  id="part"
                  type="checkbox"
                  value=""
                  class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                />

                <label
                  for="part"
                  class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                >
                  Yarim kun
                </label>
              </li>
              <li class="flex items-center">
                <input
                  id="frilans"
                  type="checkbox"
                  value=""
                  class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                />

                <label
                  for="frilans"
                  class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                >
                  Frilanser
                </label>
              </li>
              <li class="flex items-center">
                <input
                  id="docs"
                  type="checkbox"
                  value=""
                  class="w-5 h-5 bg-gray-100 border-[#246BFD] rounded text-primary-600"
                />

                <label
                  for="docs"
                  class="ml-2 text-[#212121] text-[16px] not-italic font-semibold leading-[140%]"
                >
                  Shartnoma{" "}
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultSearch;
