import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addSkills } from '../../../store/skillsApi/skillsThunks';
import toast from "react-hot-toast";

const SkillsDrawer = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [skills, setSkills] = useState([]);

  const handleSkillChange = (skill) => {
    setSkills((prevSkills) =>
      prevSkills.includes(skill)
        ? prevSkills.filter((s) => s !== skill)
        : [...prevSkills, skill]
    );
  };

  const handleSubmit = () => {
    if(skills.length === 0) {
      toast.error("Maydonni to'ldiring");
    }else {
      dispatch(addSkills(skills));
    onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm z-50">
      <div className="relative bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full">
        <button className="absolute top-0 right-0 m-4 text-lg" onClick={onClose}>X</button>
        <h2 className="text-center text-xl mb-4">Qobiliyatlar</h2>
        {['JavaScript', 'React', 'Node.js', 'CSS', 'HTML'].map((skill) => (
          <label key={skill} className="block mb-2">
            <input
              type="checkbox"
              className="mr-2 leading-tight"
              checked={skills.includes(skill)}
              onChange={() => handleSkillChange(skill)}
            />
            {skill}
          </label>
        ))}
        <button
          onClick={handleSubmit}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full mt-4"
          type="button"
        >
          Saqlash
        </button>
      </div>
    </div>
  );
};

export default SkillsDrawer;
