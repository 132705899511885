import React from "react";
import {  CategoryTop } from "../../../components/icon";
import useStatistics from "../../../hooks/useStatistics";
import { useTranslation } from "react-i18next";

export const Category = () => {
  const { applicants, jobSeekers } = useStatistics();
  const { t } = useTranslation();

  return (
    <div className="flex gap-7 justify-evenly px-[5%] py-5">
      <div className="p-3 bg-[#1A4295] rounded-2xl shadow-xl">
        <div className="flex  items-center gap-8">
          {" "}
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="40" height="40" rx="8" fill="#246BFD" />
            <path
              d="M17.5 20L16.5 21V21.5H16M18.5 17L17.5 16V14.5L18 13.5L19.5 13L21.5 12L22 12.5H23L23.5 13.5V14.5V15L23 16.5L22.5 17H22H20.5H19.5H18.5Z"
              stroke="white"
            />
            <path
              d="M24 21L23.5 20.5V20L24 21ZM24 21L23.5 22M20 20V23.5M20 23.5H19.5V24L18.5 24.5L18 25M20 23.5H21M18 25V26V26.5M18 25V26.5M18 26.5L17.5 27V28M21.5 23.5V24V24.5C21.1667 24.3333 20.7 24.2 21.5 25C22.3 25.8 22.1667 25.3333 22 25H22.5V25.5L23 26V27V27.5V28"
              stroke="white"
            />
          </svg>
          <div className="flex items-center gap-1">
            <CategoryTop />
            <span className="text-[#00BB29] text-right text-[12px] not-italic font-medium leading-[normal]">
              {jobSeekers?.data?.thisPeriodPercentage}
            </span>
          </div>
        </div>
        <h3 className="text-white text-[12px] not-italic font-semibold leading-[normal] mt-3">
          {t("Total jobseekers")}
        </h3>
        <p className="text-white text-[12px] not-italic font-medium leading-[normal] mt-1">
          {jobSeekers?.data?.totalJobSeekerCount}
        </p>
      </div>
      <div className="p-3 rounded-2xl shadow-xl">
        <div className="flex items-center gap-8">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="40" height="40" rx="8" fill="#246BFD" />
            <path
              d="M18.8333 12.125H21.1667H27V11H13V12.125H18.8333Z"
              fill="white"
            />
            <path
              d="M18.8333 12.125V13.122V29H21.1667V13.056V12.125H18.8333Z"
              fill="white"
            />
            <path
              d="M18.8333 13.122V12.125H13C14.8234 12.8283 16.8747 13.0919 18.8333 13.122Z"
              fill="white"
            />
            <path
              d="M21.1667 12.125V13.056C24.442 12.8298 27 12.125 27 12.125H21.1667Z"
              fill="white"
            />
          </svg>

          <div className="flex items-center gap-1">
            <CategoryTop />

            <span className="text-[#00BB29] text-right text-[12px] not-italic font-medium leading-[normal]">
              {applicants?.data?.thisPeriodPercentage}
            </span>
          </div>
        </div>
        <h3 className="text-[#242132] text-[12px] not-italic font-semibold leading-[normal] mt-3">
         {t("Applied for jobs")}
        </h3>
        <p className="text-[#888] text-[12px] not-italic font-medium leading-[normal] mt-1">
          {applicants?.data?.totalJobsCount}
        </p>
      </div>
    </div>
  );
};
