import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BackSingle } from "../../../components/icon";
import fakeimg from "../../../assets/img/hero-bg.png";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteOfficeById,
  fetchOfficeId,
  fetchOfficeMy,
} from "../../../store/officeApi/officeApiThunk";
import io from "socket.io-client";
import { BASE_URL_DOMAIN } from "../../../api/api";
import toast from "react-hot-toast";
import { fetchCurrentUser } from "../../../store/usersApi/usersThunks";

const socket = io(BASE_URL_DOMAIN); // Change to your server URL
export const OfficeSingle = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { officeId, status } = useSelector((state) => state.office);
  const { currentUser } = useSelector((state) => state.users);

  const [chatRoomId, setChatRoomId] = useState(null);
  const [loadingChatRoom, setLoadingChatRoom] = useState(true);
  const user = currentUser?.data;
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchOfficeId(id));
    dispatch(fetchCurrentUser());
  }, [dispatch, id]);

  const handleDelete = () => {
    dispatch(deleteOfficeById(id)).then((result) => {
      if (result?.meta.requestStatus === "fulfilled") {
        toast.success("Ofis muvaffaqqiyatli o'chirildi");
        dispatch(fetchOfficeMy({ page: 1, pageSize: 10 }));

        navigate("/announ");
      }
    });
  };

  useEffect(() => {
    if (user && officeId) {
      const handleChatRoomsResponse = (response) => {
        // console.log("response: ", response);

        if (response.status === 200 && response.data.length > 0) {
          const existingRoom = response.data.find(
            (room) => room.otherUser._id === officeId.createdBy
          );
          if (existingRoom) {
            setChatRoomId(existingRoom._id);
            setLoadingChatRoom(false);
          } else {
            createNewChatRoom();
          }
        } else {
          createNewChatRoom();
        }
      };

      const handleChatRoomCreated = (response) => {
        // console.log("created chat room: ", response);
        if (response.status === 200) {
          setChatRoomId(response.chatRoomId);
          setLoadingChatRoom(false);
        }
      };

      const createNewChatRoom = () => {
        socket.emit("createChatRoom", {
          userId: user._id,
          otherUserId: officeId.createdBy,
        });
      };

      socket.emit("requestChatRooms", { userId: user._id });
      socket.on("chatRoomsResponse", handleChatRoomsResponse);
      socket.on("chatRoomCreated", handleChatRoomCreated);

      return () => {
        socket.off("chatRoomsResponse", handleChatRoomsResponse);
        socket.off("chatRoomCreated", handleChatRoomCreated);
      };
    }
  }, [officeId, user]);

  const myId = currentUser?.data._id;

  const hasUserDeleted = officeId?.createdBy?.includes(myId);

  // console.log(officeId);

  return (
    <div className="mb-20">
      <div>
        {/* <Link to={"/"}>
          <BackSingle />
        </Link> */}

        {status === "loading" ? (
          <div className="animate-pulse">
            <Link className="w-full">
              <div className="shadow-lg p-2  gap-2">
                <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                  <svg
                    className="w-10 h-10 text-gray-200 dark:text-gray-600"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 16 20"
                  >
                    <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                  </svg>
                </div>

                <div className=" relative">
                  <div>
                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4" />
                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5" />
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ) : (
          <div className="rounded-[30px] px-3 py-3">
            <div className="flex justify-between items-center mt-6 py-2">
              <div className="w-full">
                <div className="shadow-lg p-2  gap-2">
                  <div className=" relative">
                    <img
                      className="w-full h-39 object-cover"
                      src={
                        officeId?.images?.length ? officeId?.images[0] : fakeimg
                      }
                      alt="'name"
                    />{" "}
                  </div>
                  <div className=" relative">
                    <div className="flex justify-between mt-4">
                      <h3 className="text-[#323221] text-[13px] not-italic font-bold leading-[normal]">
                        {officeId?.title}
                      </h3>
                      <p className="text-[#323221] text-[12px] not-italic font-semibold leading-[normal]">
                        {officeId?.officeType}
                      </p>
                    </div>
                    <div className="flex justify-between mt-4">
                      <h3 className="flex  items-center gap-1 text-[#666656] text-[13px] not-italic font-bold leading-[normal]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#000000"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <circle cx="12" cy="10" r="3" />
                          <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                        </svg>{" "}
                        {officeId?.location}
                      </h3>
                      <p className="flex  items-center gap-1 text-[#275ccf] text-[14px] not-italic font-semibold leading-[normal]">
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M17 8H5m12 0a1 1 0 0 1 1 1v2.6M17 8l-4-4M5 8a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.6M5 8l4-4 4 4m6 4h-4a2 2 0 1 0 0 4h4a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1Z"
                          />
                        </svg>
                        {officeId?.price}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {status === "loading" ? (
          <div className="animate-pulse mt-10">
            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
        ) : (
          <div className="shadow-md rounded-[30px] px-3 py-3">
            <div className="mt-6 py-2">
              <h3 className="text-black text-2xl font-bold leading-[normal]">
                Ta'rifi:
              </h3>
              <p className="mt-4 text-base font-medium text-[#424242]">
                {officeId?.description}{" "}
              </p>
            </div>
          </div>
        )}

        <div className="flex justify-between">
          {hasUserDeleted ? (
            <Link to={`/announ/office/${id}`}>
              <button
                className={`shadow-md fixed bottom-22 left-5 text-[#f0f5f0] font-bold text-base  bg-[#3776ffea] rounded-[100px] py-2 px-4 w-full max-w-35`}
              >
                Tahrirlash
              </button>
            </Link>
          ) : (
            <a
              href={`tell:${officeId?.phoneNumber}`}
              className="rounded-[30px] fixed bottom-22 left-5 bg-[#D8E5FF] py-3 px-5"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 10L20 4M20 4V8M20 4L16 4M16.062 14.2507V14.2507C16.6052 13.5264 17.5886 13.2943 18.3983 13.6992L19.6337 14.3168C20.4404 14.7202 20.8773 15.6135 20.7004 16.4979L20.3216 18.3922C20.1346 19.3271 19.3109 20.0146 18.3636 19.9075C11.1005 19.0859 4.91414 12.8995 4.09253 5.63644C3.98537 4.68912 4.67292 3.86542 5.60777 3.67845L7.50207 3.29959C8.38646 3.12271 9.27982 3.55963 9.68316 4.36632L10.3008 5.60168C10.7057 6.41142 10.4736 7.39483 9.7493 7.93802V7.93802C9.27761 8.2918 8.99002 8.86004 9.21796 9.40381C10.0014 11.2728 12.7272 13.9986 14.5962 14.782C15.14 15.01 15.7082 14.7224 16.062 14.2507Z"
                  stroke="#246BFD"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </a>
          )}

          {hasUserDeleted ? (
            <button
              onClick={() => handleDelete()}
              className={`shadow-md fixed bottom-22 right-5 text-white font-bold text-base  bg-red-600 rounded-[100px] w-full p-2 max-w-45`}
              type="button"
            >
              O'chirish
            </button>
          ) : (
            <Link
              to={loadingChatRoom ? "#" : `/chat/${chatRoomId}`}
              className={`shadow-md text-center fixed bottom-22 right-5 text-white font-bold text-base bg-[#246BFD] rounded-[100px] w-full p-2 max-w-45`}
              type="button"
              onClick={loadingChatRoom ? (e) => e.preventDefault() : null}
            >
              {"Xabar yuborish"}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
