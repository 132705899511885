import axios from "axios";
import { clearTokens, setTokens } from "../store/auth/authThunks";

// Define your base URLs
// export const BASE_URL_DOMAIN = 'https://topish.online';
export const BASE_URL_DOMAIN = 'https://topish.xyz';
// export const BASE_URL_DOMAIN = 'http://localhost:8080';
const API_BASE_URL = `${BASE_URL_DOMAIN}/api/v1`;

// Create axios instance
const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: false,
});

export const BASE_URL = API_BASE_URL;
export const BASE_URL_ADMIN = `${API_BASE_URL}/admin`;

// Token refresh endpoint
const refreshTokenEndpoint = "/auth/renewAccessToken";

// Variables to manage token refresh state
let isRefreshing = false;
let failedQueue = [];

// Process the queue of failed requests
const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

// Function to refresh the token
const refreshToken = async () => {
  const storedRefreshToken = localStorage.getItem("refreshToken");
  if (!storedRefreshToken) {
    console.log("No refresh token found in local storage");
    return null;
  }

  try {
    console.log("Refreshing token");

    const response = await api.post(refreshTokenEndpoint, {
      refreshToken: storedRefreshToken,
    });

    const { status, data } = response;
    if (status !== 208) { // Special status code for successful token refresh
      console.log(`Failed to refresh token: status code is ${status}`);
      return null;
    }

    const { accessToken, refreshToken: newRefreshToken } = data?.data || {};
    if (!accessToken || !newRefreshToken) {
      console.log("Failed to refresh token: missing token");
      return null;
    }

    setTokens({ accessToken, refreshToken: newRefreshToken });

    processQueue(null, accessToken);

    return accessToken;
  } catch (error) {
    console.log("Failed to refresh token", error.message);
    processQueue(error, null);

    if (error.response && error.response.status === 470) { // Custom status code for invalid refresh token
      const tokenValid = await checkToken(storedRefreshToken);
      if (!tokenValid) {
        console.error("Failed to refresh token: Access forbidden (470). Tokens have been removed from local storage.");
        clearTokens();
      }
    } else {
      console.error(error.message);
    }
    return null;
  } finally {
    isRefreshing = false;
  }
};

// Function to check the token validity
const checkToken = async (refreshToken) => {
  try {
    const response = await api.post(refreshTokenEndpoint, { refreshToken });
    return response.status === 208; // Special status code for valid token check
  } catch (error) {
    console.error("Error checking token:", error.message);
    return false;
  }
};

// Function to retry the original request
const retryOriginalRequest = async (originalRequest) => {
  const newAccessToken = await refreshToken();
  if (newAccessToken) {
    originalRequest.headers["Authorization"] = `${newAccessToken}`;
    return api(originalRequest);
  } else {
    return Promise.reject(new Error("Failed to refresh token"));
  }
};

// Add request interceptor to include tokens
api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers["Authorization"] = `${accessToken}`;
    }
    config.withCredentials = false;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor to handle token refresh
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (!originalRequest._retryCount) {
      originalRequest._retryCount = 0;
    }

    if (error.response && error.response.status === 451 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalRequest.headers["Authorization"] = `${token}`;
            return api(originalRequest);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;
      return retryOriginalRequest(originalRequest);
    } else if (error.response && error.response.status === 451) { // Custom status code for invalid refresh token
      originalRequest._retryCount += 1;
      if (originalRequest._retryCount < 3) {
        return new Promise(function (resolve) {
          setTimeout(() => resolve(api(originalRequest)), 1000);
        });
      } else {
        clearTokens();
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

// Initialize auth on website load
const initializeAuth = async () => {
  const storedRefreshToken = localStorage.getItem("refreshToken");
  if (storedRefreshToken) {
    await refreshToken();
  }
};

initializeAuth();

export default api;
