import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ArrowLeftIcon, HRArrow } from "../../../../components/icon";
import { fetchCompanies } from "../../../../store/companyApi/companyThunk";
import logo from '../../../../assets/img/logo1.jpg'
export const ApplyCompany = () => {
  const dispatch = useDispatch();
  const { totalPages } = useSelector((state) => state.company);
  const [currentPage, setCurrentPage] = useState(1);
  const [company, setCompany] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      fetchCompanies()
    ).then((action) => {
      if (currentPage === 1) {
        setCompany(action.payload?.companies);
      } else {
        setCompany((prevJobs) => [...prevJobs, ...action.payload?.companies]);
      }
      setIsLoading(false);
    });
  }, [dispatch, currentPage]);

  const handleShowMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <nav className="overflow-hidden mx-auto pt-25">
      <Link
        to={"/profile"}
        className="flex gap-4 items-center font-bold text-xl"
      >
        <ArrowLeftIcon />
        Kompaniyalar
      </Link>

      <div className="mt-10">
        <ul className="flex flex-col gap-6">
          {company?.length
            ? company?.map((item) => (
                <Link
                  className="flex  items-center justify-between"
                  to={`/profile/company/${item._id}`}
                  key={item._id}
                >
                  <div className="flex gap-4">
                    <img
                      className="w-10 h-10 rounded-full"
                      alt={item.name}
                      src={item?.logo[0] || logo}
                    />
                    <div>
                      <p className="text-black text-xl font-semibold">
                        {item.name} 
                      </p>
                      <p className="text-[#878787] text-base h-5 font-semibold">
                        {item.size} ishchi
                      </p>
                    </div>
                  </div>
                  <HRArrow />
                </Link>
              ))
            : ""}
        </ul>
        {/* <EmployerJobCard data={jobs} status={status} /> */}
        {currentPage < totalPages && company.length > 0  && (
          <button
            onClick={handleShowMore}
            className="mt-10 block m-auto p-2 bg-blue-500 text-white rounded"
            disabled={isLoading}
          >
            Ko'proq ko'rish
          </button>
        )}
      </div>
    </nav>
  );
};
