import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

export const fetchAllJobSeekers = createAsyncThunk(
  "usersApi/fetchJobSeeker",
  async (filters) => {
    const response = await axios.get("/users/getAllJobSeekers", {
      params: filters,
    });
    return {
      jobSeekers: response.data.data, // Adjust based on actual response structure
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
    };
  }
);

export const fetchJobSeekers = createAsyncThunk(
  "usersApi/fetchJobSeekers",
  async (filters) => {
    const response = await axios.get("/users/searchJobseekersParams", {
      params: filters,
    });
    return {
      jobSeekers: response.data.data, // Adjust based on actual response structure
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
    };
  }
);

export const fetchRecommendedJobSeekers = createAsyncThunk(
  "usersApi/fetchRecommendedJobSeekers",
  async (filters) => {
    const response = await axios.get("/users/getRecommendedJobSeekers", {
      params: filters,
    });
    return {
      recommendedJobSeekers: response.data.data,
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
    };
  }
);

export const fetchExperiencedJobSeekers = createAsyncThunk(
  "usersApi/fetchExperiencedJobSeekers",
  async (filters) => {
    const response = await axios.get("/users/getExperiencedJobseekers", {
      params: filters,
    });
    return {
      experiencedJobSeekers: response.data.data,
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
    };
  }
);

export const fetchJobSeekersSavedJob = createAsyncThunk(
  "usersApi/fetchJobSeekersSavedJob",
  async (filters) => {
    const response = await axios.get("/users/getJobseekersSavedjob", {
      params: filters,
    });
    console.log("getJobseekersSavedjob response", response.data);
    return {
      savedJobSeekers: response.data.data,
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
    };
  }
);

export const fetchJobSeekersFavorite = createAsyncThunk(
  "usersApi/fetchJobSeekersFavorite",
  async (filters) => {
    const response = await axios.get("/users/favoriteJob", {
      params: filters,
    });
    return {
      favoriteJobSeekers: response.data.data,
    };
  }
);

export const deleteJobSeekersSavedJob = createAsyncThunk(
  "usersApi/deleteJobSeekersSavedJob",
  async (id) => {
    const response = await axios.delete(
      `/users/deleteJobseekersSavedjob/${id}`
    );
    console.log("deleteJobseekersSavedjob response", response.data);
    return id;
  }
);
