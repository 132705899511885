/* eslint-disable react/style-prop-object */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import fakeimg from "../../../assets/img/hero-bg.png";
import "swiper/css";
import "swiper/css/pagination";
import { FreeMode, Pagination } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { fetchOffice } from "../../../store/officeApi/officeApiThunk";

const Office = () => {
  const dispatch = useDispatch();
  const { office, currentPage, status } = useSelector((state) => state.office);

  useEffect(() => {
    dispatch(fetchOffice({ page: currentPage, pageSize: 10 }));
  }, [dispatch, currentPage]);

  const skeletonDate = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  return (
    <div className="overflow-hidden mx-auto hero-banner">
      {office?.length ? (
        <>
          {" "}
          <div className="flex items-center justify-between mb-4">
            <h1 className="text-black text-lg font-bold">Ofislar</h1>
            <Link className="text-blue-600 text-base font-bold" to={"/office"}>
              Barchasi
            </Link>
          </div>
          <div className="quickjobs">
            <Swiper
              slidesPerView={1.1} // Initial value for smallest screens
              spaceBetween={20} // Minimum space between slides
              slideToClickedSlide={true}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                1024: {
                  slidesPerView: 3.2,
                  spaceBetween: 20,
                },
                992: {
                  slidesPerView: 3.2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2.2,
                  spaceBetween: 20,
                },
                650: {
                  slidesPerView: 2.2,
                  spaceBetween: 20,
                },
                550: {
                  slidesPerView: 1.2, // Show 1.2 slides on smaller screens
                  spaceBetween: 20,
                },
                400: {
                  slidesPerView: 1.2, // Show 1.2 slides on smaller screens
                  spaceBetween: 20,
                },
              }}
              modules={[FreeMode, Pagination]}
              className="mySwiper"
            >
              {status === "loading"
                ? skeletonDate?.map((item) => (
                    <SwiperSlide className="animate-pulse">
                      <Link className="w-full">
                        <div className="shadow-lg p-2 gap-2">
                          <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                            <svg
                              className="w-10 h-10 text-gray-200 dark:text-gray-600"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 16 20"
                            >
                              <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                              <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                            </svg>
                          </div>

                          <div className="relative">
                            <div>
                              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4" />
                              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5" />
                              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5" />
                              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
                            </div>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  ))
                : office?.map((item) => (
                    <SwiperSlide key={item?._id}>
                      <Link className="" to={`/office/${item?._id}`}>
                        <div className="shadow-lg rounded-md   p-4 xl:w-80 lg:w-75 w-75 gap-2">
                          <div className="relative rounded-sm">
                            <img
                              className="w-full h-39 object-cover"
                              src={
                                item?.images.length ? item?.images[0] : fakeimg
                              }
                              alt="'name"
                            />{" "}
                          </div>
                          <div className="relative">
                            <div className="flex justify-between mt-4">
                              <h3 className="text-[#323221] text-[13px] not-italic font-bold leading-[normal]">
                                {item?.title}
                              </h3>
                              <p className="text-[#323221] text-[12px] not-italic font-semibold leading-[normal]">
                                {item?.officeType}
                              </p>
                            </div>
                            <div className="flex justify-between mt-4">
                              <h3 className="flex items-center gap-1 text-[#666656] text-[13px] not-italic font-bold leading-[normal]">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="#000000"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <circle cx="12" cy="10" r="3" />
                                  <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                                </svg>{" "}
                                {item?.location}
                              </h3>
                              <p className="text-[#275ccf] text-[14px] not-italic font-semibold leading-[normal]">
                                {item?.price}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  ))}
            </Swiper>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Office;
