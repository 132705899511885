import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addContact } from "../../../store/contactsApi/contactsThunks";
import toast from "react-hot-toast";

const ContactDrawer = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const handleSubmit = () => {
    if (email?.trim() === "" || phone?.trim() === "") {
      toast.error("Email va telefon raqam kiritilmadi!");
    } else {
      dispatch(addContact({ email, phone }));
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm z-50">
      <div className="absolute bg-black opacity-50 w-full h-full"></div>
      <div className="absolute bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 z-10">
        <button
          className="absolute top-0 right-0 m-4 text-lg"
          onClick={onClose}
        >
          X
        </button>
        <h2 className="text-center text-xl mb-4">Aloqa ma'lumotlari</h2>
        <input
          className={`shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
            isFocused ? "bg-white" : "bg-gray-100"
          }`}
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          required
        />
        <input
          className={`shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
            isFocused ? "bg-white" : "bg-gray-100"
          }`}
          type="number"
          placeholder="Telefon raqam"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          onFocus={() => setIsFocused(true)}
          required
          onBlur={() => setIsFocused(false)}
        />
        <button
          onClick={handleSubmit}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
          type="button"
        >
          Saqlash
        </button>
      </div>
    </div>
  );
};

export default ContactDrawer;
