import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCurrentUser,
  updateRoleUser,
} from "../../store/usersApi/usersThunks";
import {
  ArrowProfile,
  CompanyProfile,
  ProfileAbout,
  ProfileApplied,
  ProfileAppliedJob,
  ProfileAvatar,
  ProfileImgIcon,
  ProfileLang,
  ProfileLogout,
  ProfileName,
  ProfileResume,
  ProfileTelegram,
  ProtectedProfile,
  TourProfile,
} from "../../components/icon";
import { uploadUserAvatar } from "../../store/avatarApi/avatarThunks";
import { logout } from "../../store/auth/authSlice";
import { signOut } from "../../store/auth/authThunks";
import toast from "react-hot-toast";
import {
  leaveChannel,
  removeTelegramId,
} from "../../store/telegramApi/telegramThunks";
import io from "socket.io-client";
import { BASE_URL_DOMAIN } from "../../api/api";
import { useTranslation } from "react-i18next";

export const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [channels, setChannels] = useState(null);
  const { currentUser, status, changeRole } = useSelector(
    (state) => state.users
  );

  const role =
    currentUser?.data?.role?.charAt(0).toLowerCase() +
      currentUser?.data?.role?.slice(1) || null;

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, [changeRole]);

  const user = currentUser?.data;
  useEffect(() => {
    setChannels(user?.telegramChannelIds || []);
  }, [user]);

  useEffect(() => {
    const socket = io(BASE_URL_DOMAIN, { transports: ["websocket"] });

    socket.on("telegramChannelAdded", (chatDetails) => {
      setChannels((prevChannels) => [...prevChannels, chatDetails]);
    });

    socket.on("telegramChannelRemoved", (chatId) => {
      setChannels((prevChannels) =>
        prevChannels.filter((channel) => channel.id !== chatId.toString())
      );
    });

    socket.on("telegramIdAdded", () => {
      dispatch(fetchCurrentUser());
    });

    socket.on("telegramIdRemoved", () => {
      dispatch(fetchCurrentUser());
    });

    return () => {
      socket.disconnect();
    };
  }, [dispatch]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      dispatch(uploadUserAvatar(file)).then((response) => {
        if (response?.meta.requestStatus === "fulfilled") {
          toast.success("Profil surati yangilandi");
          // dispatch(fetchCurrentUser());
        }
      });
    }
  };

  const handleLogout = () => {
    dispatch(signOut("fcmToken")).then(() => {
      dispatch(logout());
      navigate("/login");
    });
  };

  const [activeButton, setActiveButton] = useState(user?.role);

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName.target.id);
    const changeRole = buttonName.target.id;
    console.log(buttonName.target.id);
    dispatch(updateRoleUser(changeRole)).then((res) => {
      if (res.payload?.result) {
        toast.success("Role o'zgartirildi");
        dispatch(fetchCurrentUser());
      }
    });
  };

  const { t, i18n } = useTranslation();

  const languages = ["O'zbekcha", "English", "Русский"];

  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectLanguage = (language) => {
    setSelectedLanguage(language);
    setIsOpen(false);
    onLanguage(
      language === "O'zbekcha"
        ? "uz"
        : language === "English"
        ? "en"
        : language === "Русский"
        ? "ru"
        : ""
    );
  };

  localStorage.setItem("_i18n", i18n.resolvedLanguage);
  const currentLanguage = i18n.resolvedLanguage;

  const onLanguage = (lng) => {
    console.log(lng);
    i18n.changeLanguage(lng);
  };
  return (
    <div 
    className="rounded-md overflow-hidden"
      style={{
        background: "linear-gradient(142deg, #8EC9FF 0%, #FFECCF 59.18%)",
      }}
    >
      <div className="flex px-4 items-center justify-center pt-30 mb-5">
        <button
          id="JobSeeker"
          className={`flex-1 text-center py-1 rounded-l-lg ${
            activeButton === "JobSeeker"
              ? "bg-[#25498D] text-white font-medium text-sm"
              : "bg-[#ECEFF5] text-[#33396D] font-medium text-sm"
          }`}
          onClick={(e) => handleButtonClick(e)}
        >
          {t("Find_A_Job")}
        </button>
        <button
          id="Employer"
          className={`flex-1 text-center py-1 rounded-r-lg ${
            activeButton === "Employer"
              ? "bg-[#25498D] text-white font-medium text-sm"
              : "bg-[#ECEFF5] text-[#33396D] font-medium text-sm"
          }`}
          onClick={(e) => handleButtonClick(e)}
        >
          {t("Find_Employees")}
        </button>
      </div>

      <div className="pt-25">
        <div className="bg-white rounded-tl-[50px] relative rounded-tr-[50px] px-6 py-22">
          {status === "loading" ? (
            <label
              htmlFor="officeImages"
              className="w-40 animate-pulse bg-slate-500 h-40 rounded-full absolute left-1/2 transform -translate-x-1/2 top-[-12%]"
            >
              <ProfileImgIcon />
            </label>
          ) : (
            <label
              htmlFor="officeImages"
              className="w-40 h-40 rounded-full absolute left-1/2 transform -translate-x-1/2 top-[-12%]"
            >
              <ProfileAvatar />
              <img
                className="rounded-full w-40 h-40 object-cover"
                src={user?.avatar}
                alt="avatar"
              />
              <input
                onChange={handleImageChange}
                className="hidden"
                type="file"
                id="officeImages"
              />
            </label>
          )}

          {role === "jobSeeker" && (
            <>
              <div>
                <h3 className="text-[#616161] font-semibold text-base">
                  {t("profile")}
                </h3>
                <ul className="mt-7">
                  <Link
                    to={"/profile/update"}
                    className="flex items-center justify-between"
                  >
                    <div className="flex justify-between gap-5">
                      <ProfileName />
                      {status === "loading" ? (
                        <h3 className="text-[#212121] flex items-center text-base font-semibold">
                          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                        </h3>
                      ) : (
                        <h3 className="text-[#212121] text-base font-semibold">
                          {user?.fullName}
                        </h3>
                      )}
                    </div>
                    <div className="flex items-center gap-5">
                      <span className="text-[#212121] text-base font-semibold">
                        {t("Edit")}
                      </span>
                      <ArrowProfile />
                    </div>
                  </Link>
                  <Link
                    to={"/profile/resume"}
                    className="flex items-center justify-between mt-6"
                  >
                    <div className="flex justify-between gap-5">
                      <ProfileResume />
                      <h3 className="text-[#212121] text-base font-semibold">
                        {t("My Resume")}
                      </h3>
                    </div>
                    <ArrowProfile />
                  </Link>
                  <Link
                    to={"/profile/applied"}
                    className="flex items-center justify-between mt-6"
                  >
                    <div className="flex justify-between gap-5">
                      <ProfileAppliedJob />
                      <h3 className="text-[#212121] text-base font-semibold">
                        {t("Applied Jobs")}
                      </h3>
                    </div>
                    <ArrowProfile />
                  </Link>
                  <Link
                    to={"/profile/gallery"}
                    className="flex items-center justify-between mt-6"
                  >
                    <div className="flex justify-between gap-5">
                      <svg
                        class="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m3 16 5-7 6 6.5m6.5 2.5L16 13l-4.286 6M14 10h.01M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z"
                        />
                      </svg>

                      <h3 className="text-[#212121] text-base font-semibold">
                        {t("Gallery")}
                      </h3>
                    </div>
                    <ArrowProfile />
                  </Link>
                  <Link
                    to={"/profile/favorite/jobseeker"}
                    className="flex items-center justify-between mt-6"
                  >
                    <div className="flex justify-between gap-5">
                      <ProfileApplied />
                      <h3 className="text-[#212121] text-base font-semibold">
                        {t("Saved Vacancies")}
                      </h3>
                    </div>
                    <ArrowProfile />
                  </Link>
                </ul>
              </div>
              <div className="mt-6">
                <h3 className="text-[#616161] font-semibold text-base">
                  {t("General")}
                </h3>
                <ul className="mt-7">
                  <li
                    onClick={() => setIsOpen(!isOpen)}
                    className="relative cursor-pointer flex items-center justify-between"
                  >
                    <div className="flex justify-between gap-5">
                      <ProfileLang />
                      <h3 className="text-[#212121] text-base font-semibold">
                        {t("Language")}
                      </h3>
                    </div>
                    <div className="flex items-center gap-5 cursor-pointer">
                      <span className="text-[#212121] text-base font-semibold">
                      {currentLanguage === "en"
                          ? "English"
                          : currentLanguage === "uz"
                          ? "O'zbek"
                          : currentLanguage === "ru"
                          ? "Русский"
                          : ""}                      </span>
                      <ArrowProfile />
                    </div>

                    <div
                      className={`absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-10 transition-transform duration-200 ${
                        isOpen
                          ? "transform scale-100 opacity-100"
                          : "transform scale-95 opacity-0 pointer-events-none"
                      }`}
                    >
                      <ul>
                        {languages.map((language) => (
                          <li
                            key={language}
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => handleSelectLanguage(language)}
                          >
                            {language}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                  <Link
                    to={"/profile/protected"}
                    className="flex items-center justify-between mt-6"
                  >
                    <div className="flex items-center justify-between gap-5">
                      <ProtectedProfile />
                      <h3 className="text-[#212121] text-base font-semibold">
                        {t("Privacy Security")}
                      </h3>
                    </div>
                    <div className="flex items-center gap-5">
                      <ArrowProfile />
                    </div>
                  </Link>
                  {/* <Link className="flex items-center justify-between mt-6">
                    <div className="flex justify-between gap-5">
                      <HelpCenter />
                      <h3 className="text-[#212121] text-base font-semibold">
                        Yordam markazi
                      </h3>
                    </div>
                    <div className="flex items-center gap-5">
                      <ArrowProfile />
                    </div>
                  </Link> */}
                  <Link
                    to={"/profile/tournament"}
                    className="flex items-center justify-between mt-6"
                  >
                    <div className="flex justify-between gap-5">
                      <TourProfile />

                      <h3 className="text-[#212121] text-base font-semibold">
                        {t("Events_And_Tournaments")}
                      </h3>
                    </div>
                    <div className="flex items-center gap-5">
                      <ArrowProfile />
                    </div>
                  </Link>
                </ul>
              </div>
              <div className="mt-6">
                <h3 className="text-[#616161] font-semibold text-base">
                  {t("About us")}
                </h3>
                <ul className="mt-7">
                  <Link
                    to={"/profile/about"}
                    className="flex items-center justify-between mt-6"
                  >
                    <div className="flex justify-between gap-5">
                      <ProfileAbout />
                      <h3 className="text-[#212121] text-base font-semibold">
                        {t("About us")}
                      </h3>
                    </div>
                    <ArrowProfile />
                  </Link>
                </ul>
              </div>

              <div className="flex items-center justify-between mt-6">
                <div
                  onClick={handleLogout}
                  className="flex justify-between gap-5"
                >
                  <ProfileLogout />
                  <button className="text-[#212121] text-base font-semibold">
                    {t("Logout Text")}
                  </button>
                </div>
              </div>
            </>
          )}
          {role === "employer" && (
            <>
              <div>
                <h3 className="text-[#616161] font-semibold text-base">
                  {t("profile")}
                </h3>
                <ul className="mt-7">
                  <Link
                    to={"/profile/update/employer"}
                    className="flex items-center justify-between"
                  >
                    <div className="flex justify-between gap-5">
                      <ProfileName />
                      {status === "loading" ? (
                        <h3 className="text-[#212121] flex items-center text-base font-semibold">
                          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                        </h3>
                      ) : (
                        <h3 className="text-[#212121] text-base font-semibold">
                          {user?.fullName}
                        </h3>
                      )}
                    </div>
                    <div className="flex items-center gap-5">
                      <span className="text-[#212121] text-base font-semibold">
                        {t("Edit")}
                      </span>
                      <ArrowProfile />
                    </div>
                  </Link>

                  <Link
                    to={"/profile/favorite/employer"}
                    className="flex items-center justify-between mt-6"
                  >
                    <div className="flex justify-between gap-5">
                      <ProfileApplied />
                      <h3 className="text-[#212121] text-base font-semibold">
                        {t("My favorite")}
                      </h3>
                    </div>
                    <ArrowProfile />
                  </Link>
                  <Link
                    to={"/profile/telegram"}
                    className="flex items-center justify-between mt-6"
                  >
                    <div className="flex justify-between gap-5">
                      <ProfileTelegram />
                      <h3 className="text-[#212121] text-base font-semibold">
                        Telegram
                      </h3>
                    </div>
                    <ArrowProfile />
                  </Link>
                  {user?.company ? (
                    <Link
                      to={"/profile/company"}
                      className="flex items-center justify-between mt-6"
                    >
                      <div className="flex justify-between gap-5">
                        <CompanyProfile />

                        <h3 className="text-[#3E9AAE] text-base font-semibold">
                          {t("Company_Profile")}
                        </h3>
                      </div>
                      <ArrowProfile />
                    </Link>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
              <div className="mt-6">
                <h3 className="text-[#616161] font-semibold text-base">
                  {t("General")}
                </h3>
                <ul className="mt-7">
                  <li
                    onClick={() => setIsOpen(!isOpen)}
                    className="relative cursor-pointer flex items-center justify-between"
                  >
                    <div className="flex justify-between gap-5">
                      <ProfileLang />
                      <h3 className="text-[#212121] text-base font-semibold">
                        {t("Language")}
                      </h3>
                    </div>
                    <div className="flex items-center gap-5 cursor-pointer">
                      <span className="text-[#212121] text-base font-semibold">
                        {currentLanguage === "en"
                          ? "English"
                          : currentLanguage === "uz"
                          ? "O'zbek"
                          : currentLanguage === "ru"
                          ? "Русский"
                          : ""}
                      </span>
                      <ArrowProfile />
                    </div>

                    <div
                      className={`absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-10 transition-transform duration-200 ${
                        isOpen
                          ? "transform scale-100 opacity-100"
                          : "transform scale-95 opacity-0 pointer-events-none"
                      }`}
                    >
                      <ul>
                        {languages.map((language) => (
                          <li
                            key={language}
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => handleSelectLanguage(language)}
                          >
                            {language}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                  <Link
                    to={"/profile/protected"}
                    className="flex items-center justify-between mt-6"
                  >
                    <div className="flex items-center justify-between gap-5">
                      <ProtectedProfile />

                      <h3 className="text-[#212121] text-base font-semibold">
                        {t("Privacy Security")}
                      </h3>
                    </div>
                    <div className="flex items-center gap-5">
                      <ArrowProfile />
                    </div>
                  </Link>
                  {/* <Link className="flex items-center justify-between mt-6">
                    <div className="flex justify-between gap-5">
                      <HelpCenter />
                      <h3 className="text-[#212121] text-base font-semibold">
                        Yordam markazi
                      </h3>
                    </div>
                    <div className="flex items-center gap-5">
                      <ArrowProfile />
                    </div>
                  </Link> */}
                  <Link
                    to={"/profile/tournament"}
                    className="flex items-center justify-between mt-6"
                  >
                    <div className="flex justify-between gap-5">
                      <TourProfile />

                      <h3 className="text-[#212121] text-base font-semibold">
                        {t("Events_And_Tournaments")}
                      </h3>
                    </div>
                    <div className="flex items-center gap-5">
                      <ArrowProfile />
                    </div>
                  </Link>
                </ul>
              </div>
              <div className="mt-6">
                <h3 className="text-[#616161] font-semibold text-base">
                  {t("About us")}
                </h3>
                <ul className="mt-7">
                  <Link
                    to={"/profile/about"}
                    className="flex items-center justify-between mt-6"
                  >
                    <div className="flex justify-between gap-5">
                      <ProfileAbout />
                      <h3 className="text-[#212121] text-base font-semibold">
                        {t("About us")}
                      </h3>
                    </div>
                    <ArrowProfile />
                  </Link>
                </ul>
              </div>

              <div className="flex items-center justify-between mt-6">
                <div
                  onClick={handleLogout}
                  className="flex justify-between gap-5"
                >
                  <ProfileLogout />
                  <button className="text-[#212121] text-base font-semibold">
                    {t("Logout Text")}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
