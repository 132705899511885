import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addEducation } from '../../../store/educationApi/educationThunks';
import toast from "react-hot-toast";

const EducationDrawer = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [school, setSchool] = useState('');
  const [degree, setDegree] = useState('');
  const [fieldOfStudy, setFieldOfStudy] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [graduated, setGraduated] = useState(false);
  const [description, setDescription] = useState('');

  const handleSubmit = () => {
   if(
      school?.trim() === "" || 
      degree?.trim() === "" || fieldOfStudy?.trim() === "" || startDate?.trim() === ""  || graduated?.trim() === "" ){
        toast.error("Maydonni to'ldiring");
      }else {
        dispatch(addEducation({ school, degree, fieldOfStudy, startDate, endDate, graduated, description }));
        onClose();
      }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed  inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm z-50">
      <div className="relative w-full bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 ">
        <button className="absolute top-0 right-0 m-4 text-lg" onClick={onClose}>X</button>
        <h2 className="text-center text-xl mb-4">Ta'lim</h2>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="text"
          placeholder="O'quv dargoh nomi"
          value={school}
          onChange={(e) => setSchool(e.target.value)}
        />
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="text"
          placeholder="Ta'lim daraja"
          value={degree}
          onChange={(e) => setDegree(e.target.value)}
        />
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="text"
          placeholder="Kurs nomi"
          value={fieldOfStudy}
          onChange={(e) => setFieldOfStudy(e.target.value)}
        />
        <div className="flex mb-4">
          <div className="w-1/2 mr-2">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="date"
              placeholder="Start Date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="w-1/2 ml-2">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="date"
              placeholder="End Date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              disabled={graduated}
            />
          </div>
        </div>
        <div className="flex items-center mb-4">
          <input
            className="mr-2 leading-tight"
            type="checkbox"
            checked={graduated}
            onChange={(e) => setGraduated(e.target.checked)}
          />
          <span>Bitirgan</span>
        </div>
        <textarea
          className="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Ta'rif (ixtiyoriy)"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <button
          onClick={handleSubmit}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
          type="button"
        >
          Saqlash
        </button>
      </div>
    </div>
  );
};

export default EducationDrawer;
