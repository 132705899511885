import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import RoleModal from "../../components/modals/RoleModal";
import roleimg from "../../assets/img/jobrole.png";
import toast from "react-hot-toast";
import register_bg from "../../assets/img/auth-bg.webp";
import { useTranslation } from "react-i18next";

export const Role = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedDesc, setSelectedDesc] = useState("");

  const handleSelectClick = () => {
    setIsModalOpen(true);
  };

  const handleRoleSelect = (role, title, desc) => {
    setSelectedRole(role);
    setSelectedTitle(title);
    setSelectedDesc(desc);
    setIsModalOpen(false);
    Cookies.set("userRole", role, { expires: 1 }); // Set cookie to expire in 1 day
  };

  const handleContinueClick = () => {
    if (!selectedRole) {
      toast.error("Role tanlang!");
    } else {
      // Redirect to /sign-up
      navigate("/signup");
    }
  };

  const { t } = useTranslation();

  return (
    <>
      {/* <div className="max-w-screen-xl mx-auto py-10 overflow-hidden flex flex-wrap items-center justify-center  p-4">
        <div>
         

          <div className="flex flex-col items-center justify-center ">
            <div className="w-full max-w-sm p-4 bg-white rounded-lg">
              <div className="flex flex-col items-center">
                <img
                  src={roleimg}
                  alt="Illustration"
                  className="w-40 h-32 mb-4"
                />
                <p className="text-center mb-25 font-semibold">
                  {t("Purpose_Using_Platform")}
                </p>
                <button
                  onClick={handleSelectClick}
                  className="w-full px-4 py-2 font-semibold border border-gray-300 rounded-md"
                >
                  {selectedRole ? (
                    <div className="">
                      <button className="w-full flex gap-5 items-center text-left p-0  rounded-md">
                        <div>
                          {" "}
                          <h3 className="text-lg font-semibold">
                            {selectedTitle}
                          </h3>
                          <p className="text-sm text-gray-600">
                            {selectedDesc}
                          </p>
                        </div>
                      </button>
                    </div>
                  ) : (
                    <p className="font-semibold  text-black">
                      {t("Select")}...
                    </p>
                  )}
                </button>
                <button
                  onClick={handleContinueClick}
                  className="w-90 fixed bottom-10 mt-4 px-4 py-4 text-white bg-blue-600 rounded-full"
                >
                  {t("Continue")}
                </button>
              </div>
            </div>

           
          </div>
        </div>
      </div> */}
      <div
        style={{ backgroundImage: `url(${register_bg})` }}
        className="overflow-x-hidden h-screen bg-no-repeat bg-cover bg-[center_center] object-cover"
      >
        <div className="absolute z-99 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-full max-w-[500px]">
          <h3 className="text-[#000] text-[32px] not-italic font-normal leading-[91.5%] text-center">
            Topish AI
          </h3>
          <div className="mt-12 overflow-hidden bg-[#fff] p-7 my-[0] rounded-2xl">
          
            <div className="w-full mt-6">
              <div className="relative  items-center">
                <p className="text-center w-full max-w-[295px] block mx-auto mb-8 font-semibold">
                  {t("Purpose_Using_Platform")}
                </p>
                <button
                  onClick={handleSelectClick}
                  className="w-full px-4 py-2 font-semibold border border-gray-300 rounded-md"
                >
                  {selectedRole ? (
                    <div className="">
                      <button className="w-full flex gap-5 items-center text-left p-0  rounded-md">
                        <div>
                          {" "}
                          <h3 className="text-lg font-semibold">
                            {selectedTitle}
                          </h3>
                          <p className="text-sm text-gray-600">
                            {selectedDesc}
                          </p>
                        </div>
                      </button>
                    </div>
                  ) : (
                    <p className="font-semibold  text-black">
                      {t("Select")}...
                    </p>
                  )}
                </button>
              </div>

              <button
                type="submit"
                onClick={handleContinueClick}
                className="rounded-[12px] my-5 bg-[#4F7BBC] text-[#FFF] text-5 w-full py-2"
              >
                {t("Continue")}
              </button>
            </div>

         
          </div>
        </div>
        {isModalOpen && (
          <RoleModal
            onClose={() => setIsModalOpen(false)}
            onSelect={handleRoleSelect}
            t={t}
          />
        )}
        <div className="absolute bg-[rgba(223,_223,_255,_0.90)] w-full h-full top-0"></div>
      </div>
    </>
  );
};
