import { createSlice } from "@reduxjs/toolkit";
import {
  fetchCertificates,
  addCertificate,
  updateCertificate,
  deleteCertificate,
} from "./certificatesThunks"; // Adjust the path according to your structure

const initialState = {
  certificates: [],
  status: "idle",
  error: null,
};

const certificatesSlice = createSlice({
  name: "certificates",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCertificates.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCertificates.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.certificates = action.payload.data; // Adjust based on actual response structure
      })
      .addCase(fetchCertificates.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addCertificate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addCertificate.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload.data && action.payload.data.certificates) {
          state.certificates.push(action.payload.data.certificates); // Adjust based on actual response structure
        } else {
          state.error = "Failed to add certificate";
        }
      })
      .addCase(addCertificate.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateCertificate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCertificate.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.certificates.findIndex(
          (certificate) => certificate.id === action.payload.data.id
        );
        if (index !== -1) {
          state.certificates[index] = action.payload.data.certificates; // Adjust based on actual response structure
        }
      })
      .addCase(updateCertificate.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteCertificate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCertificate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.certificates = state.certificates.filter(
          (certificate) => certificate.id !== action.payload.data.id
        ); // Adjust based on actual response structure
      })
      .addCase(deleteCertificate.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default certificatesSlice.reducer;
