import React from "react";
import { CategoryCompany, CategoryTop } from "../../../components/icon";
import useStatistics from "../../../hooks/useStatistics";
import CategoryCarousel from "../../../components/CategoryCarousel";
import { useTranslation } from "react-i18next";
export const Category = () => {
  const { jobs, company } = useStatistics();
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex gap-7 justify-evenly px-[5%] py-5">
        <div className="p-3 bg-white rounded-2xl shadow-xl">
          <div className="flex items-center gap-8">
            {" "}
            <CategoryCompany />
            <div className="flex items-center gap-1">
              <CategoryTop />
              <span className="text-[#00BB29] text-right text-[12px] not-italic font-medium leading-[normal]">
                {company?.thisPeriodPercentage}
              </span>
            </div>
          </div>
          <h3 className="text-[#242132] text-[12px] not-italic font-semibold leading-[normal] mt-3">
            {t("registered companies")}
          </h3>
          <p className="text-[#888] text-[12px] not-italic font-medium leading-[normal] mt-1">
            {company?.totalCompaniesCount}
          </p>
        </div>
        <div className="p-3 bg-white rounded-2xl shadow-xl">
          <div className="flex items-center gap-8">
            {" "}
            {/* <CategoryJobs /> */}
            <div className="w-10 h-10 rounded-md bg-[#246BFD] flex justify-center items-center">
              <svg
                width="7"
                height="27"
                viewBox="0 0 7 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.34279 26C0.578084 16.3765 0.526768 10.9084 2.34279 1H4.34279C6.17071 10.8377 6.14027 16.3029 4.34279 26H2.34279Z"
                  stroke="white"
                />
              </svg>
            </div>
            <div className="flex items-center gap-1">
              <CategoryTop />

              <span className="text-[#00BB29] text-right text-[12px] not-italic font-medium leading-[normal]">
                {jobs?.data?.thisPeriodPercentage}
              </span>
            </div>
          </div>
          <h3 className="text-[#242132] text-[12px] not-italic font-semibold leading-[normal] mt-3">
           {t("total vacancies")}
          </h3>
          <p className="text-[#888] text-[12px] not-italic font-medium leading-[normal] mt-1">
            {jobs?.data?.totalJobsCount}
          </p>
        </div>
      </div>

      {/* <CategoryCarousel/> */}

    </div>
  );
};
