import Wrapper from "../assets/wrappers/Navbar";
import { FaAlignLeft, FaUserCircle, FaCaretDown } from "react-icons/fa";
import Logo from "../assets/img/new-logo.svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../store/usersApi/usersSlice";

const Navbar = () => {
  const [showLogout, setShowLogout] = useState(false);
  const { user } = useSelector((store) => store.users);
  const dispatch = useDispatch();

  const toggle = () => {
    dispatch(toggleSidebar());
  };

  return (
    <Wrapper>
      <div className="nav-center relative z-10 bg-white">
        <button type="button" className="toggle-btn" onClick={toggle}>
          <FaAlignLeft />
        </button>
        <div className="flex gap-10 items-center justify-center w-full">
          <h3 className="logo-text">Topish AI</h3>

          <form class="w-full max-w-70">
            <div class="flex">
           
              <div class="relative w-full">
                <input
                  type="text"
                  id="search-dropdown"
                  class="block w-full outline-none rounded-3xl z-20 text-sm text-gray-900 border border-gray-300"
                  required
                />
                <button
                  type="submit"
                  class="absolute top-0 end-0 p-2.5 text-sm font-medium h-full"
                >
                  <svg
                    class="w-4 h-4 text-[#C5C7CD]"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                  <span class="sr-only">Search</span>
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="">
          <button
            type="button"
            className="flex"
            onClick={() => setShowLogout(!showLogout)}
          >
            <img alt="logo" src={Logo} />
            <div className=""></div>
          </button>
        </div>
      </div>
    </Wrapper>
  );
};
export default Navbar;
