import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

// telegramThunks.js

export const getTelegramChannels = createAsyncThunk(
  "telegram/telegramChannels",
  async () => {
    const response = await axios.get("/telegram");

    return response.data;
  }
);
export const removeTelegramId = createAsyncThunk(
  "telegram/removeTelegramId",
  async () => {
    const response = await axios.post("/telegram/remove-telegram-id");
    console.log("response", response);
    return response.data;
  }
);
export const leaveChannel = createAsyncThunk(
  "telegram/leaveChannel",
  async (chatId) => {
    const response = await axios.post("/telegram/leave-channel", { chatId });
    console.log("response", response);
    return response.data;
  }
);

export const updateChannel = createAsyncThunk(
  'telegram/updateChannel',
  async ({ id, channel }) => {
    const response = await axios.patch(`/telegram/${id}`, channel, {
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
      },
    });
    return response.data;
  }
);

export const addOrUpdateTelegramData = createAsyncThunk(
  'telegram/addOrUpdateTelegramData',
  async (data) => {
    const response = await axios.post(`/telegram/add-update-data`, data);
    return response.data;
  }
);

export const deleteTelegramData = createAsyncThunk(
  'telegram/deleteTelegramData',
  async () => {
    const response = await axios.post(`/telegram/delete-telegram-data`);
    return response.data;
  }
);

export const uploadTelegramImages = createAsyncThunk(
  'telegram/uploadTelegramImages',
  async (formData) => {
    const response = await axios.post(`/telegram/upload-post-images`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  }
);
export const deleteTelegramImages = createAsyncThunk(
  'telegram/deleteTelegramImages',
  async (data) => {
    const response = await axios.post(`/telegram/delete-post-images`, data);
    return response.data;
  }
);

export const deleteSingleTelegramImage = createAsyncThunk(
  'telegram/deleteSingleTelegramImage',
  async (data) => {
    console.log('data: ', data);
    const response = await axios.post(`/telegram/delete-single-telegram-image`, { imageUrl: data });
    return response.data;
  }
);

export const changeSelectedImage = createAsyncThunk(
  'telegram/changeSelectedImage',
  async (data) => {
    console.log("changeSelectedImage", data);
    const response = await axios.post(`/telegram/change-selected-image`, { selectedImage: data });
    console.log("response", response);
    return response.data;
  }
);
export const changeSelectedPost = createAsyncThunk(
  'telegram/changeSelectedPost',
  async (data) => {
    console.log("changeSelectedPost", data);
    const response = await axios.post(`/telegram/change-selected-post`, { selectedPost: data });
    return response.data;
  }
);


