import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadCVFile } from '../../../store/cvFilesApi/cvFilesThunks';
import toast from "react-hot-toast";

const ResumeDrawer = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [cvFile, setCvFile] = useState(null);

  const handleSubmit = () => {
    if (cvFile) {
      dispatch(uploadCVFile(cvFile));
      onClose();
    }else {
      toast.error("CV faylini yuklang");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm z-50">
      <div className="relative bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full">
        <button className="absolute top-0 right-0 m-4 text-lg" onClick={onClose}>X</button>
        <h2 className="text-center text-xl mb-4">CV/Rezyumeni yuklash</h2>
        <div className="flex justify-center items-center mb-4">
          <label className="cursor-pointer flex flex-col items-center">
            <span className="text-4xl">📁</span>
            <input
              type="file"
              className="hidden"
              onChange={(e) => setCvFile(e.target.files[0])}
            />
            <span className="mt-2 text-sm text-gray-600">
              {cvFile ? cvFile.name : 'Yuklash'}
            </span>
          </label>
        </div>
        <button
          onClick={handleSubmit}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full mt-4"
          type="button"
        >
          Saqlash
        </button>
      </div>
    </div>
  );
};

export default ResumeDrawer;
