import apiSlice from "./api";

export const quickJobsApiSlice = apiSlice.injectEndpoints({
  reducerPath: "quickJobsApi",

  tagTypes: ["QuickJob", "QuickJobsMe"],
  endpoints: (builder) => ({
    
    fetchAllQuickJobs: builder.query({
      query: (filters) => ({
        url: "/quickjobs",
        params: {
          page: 1,
          limit: 10,
          ...filters,
        },
      }),
      providesTags: [{ type: "QuickJob", id: "LIST" }],
    }),

    fetchRecentQuickJobs: builder.query({
      query: (filters) => ({
        url: "/quickJobs?recentJob=trues",
        params: {
          page: 1,
          limit: 10,
          ...filters,
        },
      }),
      providesTags: [{ type: "QuickJob", id: "LIST" }],
    }),

    fetchQuickJobsMe: builder.query({
      query: (filters) => ({
        url: "/quickjobs/myJobs",
        params: {
          page: 1,
          limit: 10,
          ...filters,
        },
      }),
      providesTags: [{ type: "QuickJobsMe", id: "LIST" }],
    }),

    fetchAllQuickJobsId: builder.query({
      query: (id) => `/quickjobs/${id}`,
      providesTags: (result, error, id) => [{ type: "QuickJob", id }],
    }),

    createQuickJob: builder.mutation({
      query: (newJob) => ({
        url: "/quickjobs",
        method: "POST",
        body: newJob,
      }),
      invalidatesTags: [
        { type: "QuickJob", id: "LIST" },
        { type: "QuickJobsMe", id: "LIST" },
      ],
    }),

    searchQuickJobs: builder.query({
      query: (filters) => ({
        url: "/quickjobs/search",
        params: filters,
      }),
      providesTags: [{ type: "QuickJob", id: "SEARCH_LIST" }],
    }),

    updateQuickJobById: builder.mutation({
      query: ({ id, updatedJob }) => ({
        url: `/quickjobs/${id}`,
        method: "PATCH",
        body: updatedJob,
      }),
      invalidatesTags: [
        { type: "QuickJob", id: "LIST" },
        { type: "QuickJobsMe", id: "LIST" },
      ],
    }),

    deleteQuickJobById: builder.mutation({
      query: (id) => ({
        url: `/quickjobs/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [
        { type: "QuickJob", id },
        { type: "QuickJobsMe", id: "LIST" },
      ],
    }),

    applyForQuickJob: builder.mutation({
      query: (jobID) => ({
        url: `/quickjobs/${jobID}/apply`,
        method: "POST",
      }),
      invalidatesTags: (result, error, jobID) => [{ type: "QuickJob", jobID }],
    }),

    fetchEmployerPosts: builder.query({
      query: ({ page = 1, limit = 10 }) => ({
        url: "/quickjobs/myJobs",
        params: { page, limit },
      }),
      providesTags: [{ type: "QuickJob", id: "EMPLOYER_POSTS" }],
    }),

    favoriteQuickJobs: builder.mutation({
      query: (id) => ({
        url: `/users/favoriteQuickJob/${id}`,
        method: "POST",
      }),
      invalidatesTags: (result, error, id) => [{ type: "QuickJob", id }],
    }),

    removeFavoriteQuickJobs: builder.mutation({
      query: (id) => ({
        url: `/users/favoriteJob/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: "QuickJob", id }],
    }),

    fetchCandidates: builder.query({
      query: (filters) => ({
        url: `/quickjobs/myJobs/${filters.id}/applicants`,
        params: filters,
      }),
      providesTags: [{ type: "QuickJob", id: "CANDIDATES" }],
    }),
  }),
});

export const {
  useFetchAllQuickJobsQuery,
  useFetchQuickJobsMeQuery,
  useFetchAllQuickJobsIdQuery,
  useCreateQuickJobMutation,
  useSearchQuickJobsQuery,
  useUpdateQuickJobByIdMutation,
  useDeleteQuickJobByIdMutation,
  useApplyForQuickJobMutation,
  useFetchEmployerPostsQuery,
  useFavoriteQuickJobsMutation,
  useRemoveFavoriteQuickJobsMutation,
  useFetchCandidatesQuery,
  useFetchRecentQuickJobsQuery,
} = quickJobsApiSlice;
