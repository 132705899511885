import { Navigate, Outlet } from "react-router-dom";
import { logoutRole } from "../store/auth/authSlice";
import { signOutRole } from "../store/auth/authThunks";
import { useEffect } from "react";
import { fetchCurrentUser } from "../store/usersApi/usersThunks";
import { useDispatch } from "react-redux";

const useAuth = () => {
  const dispatch = useDispatch();
  let token = null;
  try {
    const storedUserData = localStorage.getItem("userData");
    token = storedUserData ? JSON.parse(storedUserData) : null;
  } catch (error) {
    console.error("Error parsing token:", error);
    token = null;
  }
  useEffect(() => {
    if (token) {
      dispatch(fetchCurrentUser());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!token) {
      dispatch(signOutRole("12345")).then(() => {
        dispatch(logoutRole());
      });
    }
  }, [dispatch, token]);

  return !!token;
};
const Authentication = () => {
  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <Navigate to="/login" />;
};

export default Authentication;
