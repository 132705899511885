import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

// Fetch education
export const fetchEducation = createAsyncThunk(
  "education/fetchEducation",
  async () => {
    const response = await axios.get("/users/resume/education");
    console.log("fetchEducation", response.data);
    return response.data; // Assuming the response contains { data: { education: [...] } }
  }
);

// Add education
export const addEducation = createAsyncThunk(
  "education/addEducation",
  async (education) => {
    console.log("addEducation", education);
    const response = await axios.post("/users/resume/education", education);
    return response.data; // Assuming the response contains the added education
  }
);

// Update education by id
export const updateEducation = createAsyncThunk(
  "education/updateEducation",
  async ({ id, education }) => {
    const response = await axios.put(`/users/resume/education/${id}`, {
      school: education.school,
      degree: education.degree,
      fieldOfStudy: education.fieldOfStudy,
      startDate: education.startDate,
      endDate: education.endDate,
      graduated: education.graduated,
      description: education.description,
      location: education.location,
    });
    return response.data; // Assuming the response contains the updated education
  }
);

// Delete education by id
export const deleteEducation = createAsyncThunk(
  "education/deleteEducation",
  async (id) => {
    const response = await axios.delete(`/users/resume/education/${id}`);
    return response.data; // Assuming the response contains the updated education list
  }
);
