import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

// Fetch all gallery posts
export const fetchGallery = createAsyncThunk(
  "gallery/fetchGallery",
  async () => {
    const response = await axios.get("/gallery");
    console.log("fetchGallery", response.data);
    return response.data; // Assuming the response contains { data: { gallery: [...] } }
  }
);
export const privacy = createAsyncThunk(
  "gallery/privacy",
  async () => {
    const response = await axios.get("/privatePolicy");
    return response; // Assuming the response contains { data: { gallery: [...] } }
  }
);

// Fetch all gallery posts for logged-in job seeker
export const fetchAllGalleries = createAsyncThunk(
  "gallery/fetchAllGalleries",
  async () => {
    const response = await axios.get("/gallery/allgalleries");
    console.log("fetchAllGalleries", response.data);
    return response.data; // Assuming the response contains { data: { gallery: [...] } }
  }
);

// Fetch a specific gallery post by ID
export const fetchGalleryById = createAsyncThunk(
  "gallery/fetchGalleryById",
  async (id) => {
    const response = await axios.get(`/gallery/${id}`);
    console.log("fetchGalleryById", response.data);
    return response.data; // Assuming the response contains the gallery post
  }
);

// Fetch gallery posts created by a specific job seeker
export const fetchJobSeekerGallery = createAsyncThunk(
  "gallery/fetchJobSeekerGallery",
  async (id) => {
    const response = await axios.get(`/gallery/getjobseeker/${id}`);
    console.log("fetchJobSeekerGallery", response.data);
    return response.data; // Assuming the response contains { data: { gallery: [...] } }
  }
);

// Add or update a gallery post
export const addOrUpdateGallery = createAsyncThunk(
  "gallery/addOrUpdateGallery",
  async (galleryData) => {
    const formData = new FormData();
    galleryData.gallery.forEach((file) => formData.append("gallery", file));

    const response = await axios.post("/gallery", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data; // Assuming the response contains the added/updated gallery post
  }
);

// Delete a gallery post by ID
export const deleteGallery = createAsyncThunk(
  "gallery/deleteGallery",
  async ({imageUrl}) => {
    const response = await axios.delete(`/gallery/`, {
      data: { imageUrl },
    });
    return response.data; // Assuming the response contains the updated gallery list
  }
);
