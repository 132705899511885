import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

// Fetch languages
export const fetchLanguages = createAsyncThunk(
  "languages/fetchLanguages",
  async () => {
    const response = await axios.get("/users/resume/languages");
    console.log("fetchLanguages", response.data);
    return response.data; // Assuming the response contains { data: { languages: [...] } }
  }
);

// Add language
export const addLanguage = createAsyncThunk(
  "languages/addLanguage",
  async (language) => {
    console.log("addLanguage", language);
    const response = await axios.post("/users/resume/languages", language);
    return response.data; // Assuming the response contains the added language
  }
);

// Update language by id
export const updateLanguage = createAsyncThunk(
  "languages/updateLanguage",
  async ({ id, language }) => {
    const response = await axios.put(`/users/resume/languages/${id}`, {
      language: language.language,
      proficiency: language.proficiency,
    });
    return response.data; // Assuming the response contains the updated language
  }
);

// Delete language by id
export const deleteLanguage = createAsyncThunk(
  "languages/deleteLanguage",
  async (id) => {
    const response = await axios.delete(`/users/resume/languages/${id}`);
    return response.data; // Assuming the response contains the updated languages list
  }
);
