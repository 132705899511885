import { createSlice } from "@reduxjs/toolkit";
import {
  fetchProjects,
  addProject,
  updateProject,
  deleteProject,
} from "./projectsThunks"; // Adjust the path according to your structure

const initialState = {
  projects: [],
  status: "idle",
  error: null,
};

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.projects = action.payload.data; // Adjust based on actual response structure
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addProject.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addProject.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload.data && action.payload.data.projects) {
          state.projects.push(action.payload.data.projects); // Adjust based on actual response structure
        } else {
          state.error = "Failed to add project";
        }
      })
      .addCase(addProject.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateProject.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.projects.findIndex(
          (proj) => proj.id === action.payload.data.id
        );
        if (index !== -1) {
          state.projects[index] = action.payload.data.projects; // Adjust based on actual response structure
        }
      })
      .addCase(updateProject.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteProject.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.projects = state.projects.filter(
          (proj) => proj.id !== action.payload.data.id
        ); // Adjust based on actual response structure
      })
      .addCase(deleteProject.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default projectsSlice.reducer;
