import React, { useEffect, useState } from "react";
import { ArrowLeftIcon, HeaderFilter } from "../../../components/icon";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobSeekers } from "../../../store/jobSeekersApi/jobSeekersThunks";
import EmployerJobCard from "../../../components/cards/EmployerJobCard";
import { useTranslation } from "react-i18next";

const EmployerSearch = () => {
  const dispatch = useDispatch();
  const { totalPages, status } = useSelector((state) => state.jobSeekers);
  const [currentPage, setCurrentPage] = useState(1);
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchJobSeekers({ page: currentPage, pageSize: 10, jobTitle: searchQuery })).then(
      (action) => {
        if (currentPage === 1) {
          setJobs(action.payload?.jobSeekers);
        } else {
          setJobs((prevJobs) => [...prevJobs, ...action.payload?.jobSeekers]);
        }
        setIsLoading(false);
      }
    );
  }, [dispatch, currentPage, searchQuery]);

  const handleShowMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setCurrentPage(1);
  };
  const { t } = useTranslation();


  return (
    <nav className="py-5 overflow-hidden mx-auto">
      <div className="flex fixed bg-white w-full top-0 py-4 px-2 items-center gap-0">
        <Link
          to={"/"}
          className="border border-[#F5F5F5] w-10 h-8 flex items-center justify-center rounded-full"
        >
          <ArrowLeftIcon />
        </Link>
        <form className="w-full flex justify-between gap-3" autoComplete="off" onSubmit={handleSearchSubmit}>
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            Search
          </label>
          <div className="relative w-full px-4">
            <input
              type="text"
              name="jobTitle"
              id="default-search"
              value={searchQuery}
              onChange={handleSearchChange}
              className="w-full p-4 ps-10 text-sm text-gray-900 border border-[#F5F5F5] rounded-lg bg-white focus:ring-white focus:border-[#F5F5F5]"
              placeholder={`${t("Search for talents")}`}
            />
            <span className="text-white absolute end-2.5 bottom-2.5 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              <HeaderFilter />
            </span>
          </div>
        </form>
      </div>

      <div className="flex items-center justify-between mb-0">
        <h1 className="text-black text-lg fixed top-20 py-3 bg-white w-full font-semibold ">
          {jobs?.length || 0} {t("found")}
        </h1>
      </div>
      <div className="mt-30">
        <EmployerJobCard data={jobs} status={status} />
        {currentPage < totalPages && (
          <button
            onClick={handleShowMore}
            className="mt-10 block m-auto p-2 bg-blue-500 text-white rounded"
            disabled={isLoading}
          >
            {isLoading ? "Yuklanmoqda..." : "Ko'proq ko'rish"}
          </button>
        )}
      </div>
    </nav>
  );
};

export default EmployerSearch;
