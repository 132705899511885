import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AppliedBack } from "../../../components/icon";
import ContactDrawer from "./ContactDrawer";
import SummaryDrawer from "./SummaryDrawer";
import WorkExperienceDrawer from "./WorkExperienceDrawer";
import EducationDrawer from "./EducationDrawer";
import CertificateDrawer from "./CertificateDrawer";
import SkillsDrawer from "./SkillsDrawer";
import ResumeDrawer from "./ResumeDrawer";
import LanguageDrawer from "./LanguageDrawer";
import { useTranslation } from "react-i18next";

export const ProfileResume = () => {
  const [isContactOpen, setContactOpen] = useState(false);
  const [isSummaryOpen, setSummaryOpen] = useState(false);
  const [isWorkExperienceOpen, setWorkExperienceOpen] = useState(false);
  const [isEducationOpen, setEducationOpen] = useState(false);
  const [isCertificateOpen, setCertificateOpen] = useState(false);
  const [isLanguageOpen, setLanguageOpen] = useState(false);
  const [isSkillsOpen, setSkillsOpen] = useState(false);
  const [isResumeOpen, setResumeOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="mb-10">
      <Link
        to={"/profile"}
        className="flex gap-4 items-center font-bold text-xl"
      >
        <AppliedBack />
        {t("My Resume")}
      </Link>

      <div className="container mx-auto p-4">
        <div className="grid grid-cols-2 gap-4">
          <button onClick={() => setContactOpen(true)} className="border p-2">
            {t("Contact Information")}
          </button>
          <button onClick={() => setSummaryOpen(true)} className="border p-2">
            {t("Summary")}
          </button>
          <button
            onClick={() => setWorkExperienceOpen(true)}
            className="border p-2"
          >
            {t("Work Experience")}
          </button>
          <button onClick={() => setEducationOpen(true)} className="border p-2">
            {t("Education")}
          </button>
          <button
            onClick={() => setCertificateOpen(true)}
            className="border p-2"
          >
            {t("Certification and Licenses")}
          </button>
          <button onClick={() => setLanguageOpen(true)} className="border p-2">
            {t("Languages")}
          </button>
          <button onClick={() => setSkillsOpen(true)} className="border p-2">
            {t("Skills")}
          </button>
          <button onClick={() => setResumeOpen(true)} className="border p-2">
            {t("CV")}
          </button>
        </div>

        <ContactDrawer
          isOpen={isContactOpen}
          onClose={() => setContactOpen(false)}
        />
        <SummaryDrawer
          isOpen={isSummaryOpen}
          onClose={() => setSummaryOpen(false)}
        />
        <WorkExperienceDrawer
          isOpen={isWorkExperienceOpen}
          onClose={() => setWorkExperienceOpen(false)}
        />
        <EducationDrawer
          isOpen={isEducationOpen}
          onClose={() => setEducationOpen(false)}
        />
        <CertificateDrawer
          isOpen={isCertificateOpen}
          onClose={() => setCertificateOpen(false)}
        />
        <LanguageDrawer
          isOpen={isLanguageOpen}
          onClose={() => setLanguageOpen(false)}
        />
        <SkillsDrawer
          isOpen={isSkillsOpen}
          onClose={() => setSkillsOpen(false)}
        />
        <ResumeDrawer
          isOpen={isResumeOpen}
          onClose={() => setResumeOpen(false)}
        />
      </div>
    </div>
  );
};
