import { createSlice } from "@reduxjs/toolkit";
import {
  fetchSummary,
  addSummary,
  updateSummary,
  deleteSummary,
} from "./summaryThunks"; // Adjust the path according to your structure

const initialState = {
  summary: "",
  status: "idle",
  error: null,
};

const summarySlice = createSlice({
  name: "summary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSummary.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSummary.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.summary = action.payload.data; // Adjust based on actual response structure
      })
      .addCase(fetchSummary.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addSummary.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addSummary.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.summary = action.payload.data.summary; // Adjust based on actual response structure
      })
      .addCase(addSummary.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateSummary.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateSummary.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.summary = action.payload.data.summary; // Adjust based on actual response structure
      })
      .addCase(updateSummary.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteSummary.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteSummary.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.summary = ""; // Assuming the response removes the summary
      })
      .addCase(deleteSummary.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default summarySlice.reducer;
