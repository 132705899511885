import React from "react";
import { Link } from "react-router-dom";
import { AppliedBack } from "../../components/icon";
import logo from '../../assets/img/logo1.jpg'

export const About = () => {
  return (
    <div>
      <div className="min-h-screen flex flex-col mb-10">
      <div className="flex  mb-5">
        <Link
          to={"/profile"}
          className="flex gap-4 items-center font-bold text-xl"
        >
          <AppliedBack />
          Biz haqimizda
        </Link>
      </div>
        <div className="bg-white shadow-lg rounded-lg p-6 w-full">
          <div className="flex flex-col p-5 border border-solid rounded-xl items-center mb-6">
            <img
              src={logo}
              alt="Topish LLC"
              className="w-20 h-20 mb-4 rounded-xl"
            />
            <h1 className="text-lg font-semibold text-[#246BFD]">Topish LLC</h1>
            <hr className="h-2 text-gray-500 block w-full my-2"/>
            <p className="text-gray-500">Toshkent, O'zbekiston</p>
          </div>
          <section>
            <h2 className="text-lg font-semibold mb-2">Haqida</h2>
            <p className="text-gray-700">
              Topish, 2024-yil Toshkent shahrida tashkil etilgan. Biz har qanday
              turdagi kompaniyalarga HR xizmatlarini taklif etamiz. Biz
              zamonaviy HR boshqaruvni talqin qilgan kesimli mobil ilovalarni
              ishlab chiqishni o'z ichiga olganmiz. Biz faqat ilovalar
              yaratuvchilar emas, balki HR va biznes xizmatlari sohasida
              yangiliklarni rejalashtiruvchi, yechimlarni yaratuchi
              kompaniyamiz. Bizning maqsadlar: Ish izlouchilarni ish bilan
              ta`minlash, Inovatsion HR texnologiyalari bilan hayotiy
              muammolarni hal etish, konsalting kompaniyalari uchun xizmatlar
              platformasini taklif etish!
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};
