import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

// Fetch professions
export const fetchProfessions = createAsyncThunk(
  "professions/fetchProfessions",
  async () => {
    const response = await axios.get("/users/resume/professions");
    console.log("fetchProfessions", response.data);
    return response.data; // Assuming the response contains { data: { professions: [...] } }
  }
);

// Add professions
export const addProfessions = createAsyncThunk(
  "professions/addProfessions",
  async (professions) => {
    console.log("addProfessions", professions);
    const response = await axios.post("/users/resume/professions", {
      professions,
    });
    return response.data; // Assuming the response contains the added professions
  }
);

// Update professions by id
export const updateProfessions = createAsyncThunk(
  "professions/updateProfessions",
  async ({ id, professions }) => {
    const response = await axios.put(`/users/resume/professions/${id}`, {
      professions,
    });
    return response.data; // Assuming the response contains the updated professions
  }
);

// Delete professions by id
export const deleteProfessions = createAsyncThunk(
  "professions/deleteProfessions",
  async (id) => {
    const response = await axios.delete(`/users/resume/professions/${id}`);
    return response.data; // Assuming the response contains the updated professions list
  }
);
