// Routers.js
import { Route, Routes } from "react-router-dom";
import RouteScroll from "./utils/RouteScroll";
import { Role } from "./pages/auth/Role";
import Signup from "./pages/auth/Signup";
import Search from "./pages/search/Search";
import EmployerSearch from "./pages/employer/search/Search";
import SignIn from "./pages/auth/Login";
import Chat from "./pages/chat/Chat";
import { useEffect, useState } from "react";
import LazyLoading from "./components/LazyLoad";
import { AdvancedSearch } from "./pages/advancedSearch/AdvancedSearch";
import ResultSearch from "./pages/resultSearch/ResultSearch";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentUser } from "./store/usersApi/usersThunks";
import Profile from "./pages/profile/Profile";
import { FavoriteJobseeker } from "./pages/profile/favorite/FavoriteJobseeker";
import { LayoutPage } from "./layouts/LayoutPage";
import { Home } from "./pages/home";
import { useNavigate } from "react-router-dom";
import { EmployerHome } from "./pages/employer";
import { Announ } from "./pages/employer/announ";
import QuickJobForm from "./pages/employer/announ/quickAnnoun/QuickJobs";
import { StandartForm } from "./pages/employer/announ/standart/Standart";
import OfficeForm from "./pages/employer/announ/office/Office";
import AllOffice from "./pages/employer/allOffice/AllOffice";
import { OfficeSingle } from "./pages/employer/office/Single";
import AllRecomended from "./pages/employer/recomended/allRecomended/AllRecomended";
import AllExperience from "./pages/employer/experienced/AllExpreience/AllExperience";
import { JobSeekerSingle } from "./pages/employer/jobseekers/JobSeekerSingle";
import { JobsSingle } from "./components/fetchingComponents/jobs/JobsSingle";
import { QuickJobsSingle } from "./components/fetchingComponents/quickJobs/QuickJobsSingle";
import { UserInformation } from "./pages/userInformation/UserInformation";
import { AllJobs } from "./pages/allJobs/AllJobs";
import { AllJobsQuick } from "./pages/allQuickJobs/AllQuickJobs";
import Authentication from "./protected/Protected";
import { AllRecentJobs } from "./pages/allRecentJobs/AllRecentJobs";
import TelegramManager from "./pages/profile/telegram/Telegram";
import { Company } from "./pages/profile/company/Company";
import { EditCompany } from "./pages/profile/company/editCompany";
import { ApplyCompany } from "./pages/profile/company/applyCompany/ApplyCompany";
import EditProfileEmployer from "./pages/profile/editProfile/EditEmployer";

import { ApplyCompanySingle } from "./pages/profile/company/applyCompany/ApplyCompanySingle";
import { AddHr } from "./pages/profile/company/addHR/AddHr";
import { AddHrSingle } from "./pages/profile/company/addHR/AddHrSingle";
import { QuickJobsCandidats } from "./pages/home/candidates/quickJobsCandidats";
import { AppliedJobs } from "./pages/profile/appliedJobs/AppliedJob";
import EditProfile from "./pages/profile/editProfile/EditJobseeker";
import { FavoriteResume } from "./pages/profile/favorite/FavoriteEmployer";
import { ProfileGallery } from "./pages/profile/gallery/Gallery";
import { ProfileProtected } from "./pages/profile/protected/Protected";
import { ChangePhone } from "./pages/profile/protected/ChangePhone";
import Tournament from "./pages/tournament/Tournament";
import { TournamentSingle } from "./pages/tournament/TournamentSingle";
import { About } from "./pages/about/About";
import JobSeekers from "./pages/employer/jobseekers/JobSeekers";
import { ChatLists } from "./pages/chat/ChatLists";
import { ProfileResume } from './pages/profile/resume/Resume';

function Routers() {
  const navigate = useNavigate();
  const { changeRole, currentUser } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, [dispatch, changeRole]);
  const [loading, setLoading] = useState(true);

  const role =
    currentUser?.data.role?.charAt(0).toLowerCase() +
      currentUser?.data.role?.slice(1) || null;

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return <LazyLoading />;
  }
  if (role === "admin") {
    navigate("/admin", { replace: true });
    return null;
  }

  return (
    <div>
      <RouteScroll />
      <Routes>
        <Route path="/" element={<LayoutPage />}>
          {role === "jobSeeker" && (
            <>
              <Route index element={<Home />} />
            </>
          )}
          {role === "service" && (
            <>
              <Route index element={<Home />} />
            </>
          )}
          {role == null && (
            <>
              <Route index element={<Home />} />
            </>
          )}
          {role === "employer" && (
            <>
              <Route index element={<EmployerHome />} />
            </>
          )}
          <Route path="/announ" element={<Announ />} />
          <Route path="/announ/quick" element={<QuickJobForm />} />
          <Route path="/announ/quick/:id" element={<QuickJobForm />} />
          <Route path="/announ/standart" element={<StandartForm />} />
          <Route path="/announ/standart/:id" element={<StandartForm />} />
          <Route path="/announ/office" element={<OfficeForm />} />
          <Route path="/announ/office/:id" element={<OfficeForm />} />
          <Route path="/office" element={<AllOffice />} />
          <Route path="/office/:id" element={<OfficeSingle />} />
          <Route path="/jobseekers/recomended" element={<AllRecomended />} />
          <Route path="/jobseekers/expreienced" element={<AllExperience />} />
          <Route path="/jobseeker/:id" element={<JobSeekerSingle />} />
          <Route path="/jobs/:id" element={<JobsSingle />} />
          <Route path="/quickjobs/:id" element={<QuickJobsSingle />} />
          <Route path="/user/information/:id" element={<UserInformation />} />
          <Route path="/alljobs" element={<AllJobs />} />
          <Route path="/allquickjobs" element={<AllJobsQuick />} />
          <Route path="/allrecentjobs" element={<AllRecentJobs />} />
          <Route element={<Authentication />}>
            <Route path="/profile" element={<Profile />} />
          </Route>
          <Route path="/profile/telegram" element={<TelegramManager />} />
          <Route path="/profile/company" element={<Company />} />
          <Route path="/profile/company/edit" element={<EditCompany />} />
          <Route path="/profile/apply/company" element={<ApplyCompany />} />
          <Route path="/profile/company/:id" element={<ApplyCompanySingle />} />
          <Route path="/profile/hrs" element={<AddHr />} />
          <Route path="/profile/hrs/:id" element={<AddHrSingle />} />
          <Route
            path="/quickjobs/candidates/:id"
            element={<QuickJobsCandidats />}
          />
          <Route path="/profile/applied" element={<AppliedJobs />} />
          <Route path="/profile/update" element={<EditProfile />} />

          <Route
            path="/profile/update/employer"
            element={<EditProfileEmployer />}
          />
          <Route path="/profile/resume" element={<ProfileResume />} />
          <Route
            path="/profile/favorite/employer"
            element={<FavoriteResume />}
          />
          <Route path="/profile/gallery" element={<ProfileGallery />} />
          <Route
            path="/profile/favorite/jobseeker"
            element={<FavoriteJobseeker />}
          />
          <Route path="/profile/protected" element={<ProfileProtected />} />
          <Route path="/profile/protected/phone" element={<ChangePhone />} />
          <Route path="/profile/tournament" element={<Tournament />} />
          <Route
            path="/profile/tournament/:id"
            element={<TournamentSingle />}
          />
          <Route path="/profile/about" element={<About />} />
          <Route path="/jobSeekers" element={<JobSeekers />} />
          <Route element={<Authentication />}>
            <Route path="/chat" element={<ChatLists />} />
          </Route>
        </Route>
        <Route path="/chat/:chatRoomId" element={<Chat />} />
        <Route path="/login" element={<SignIn />} />
        <Route path="/signup-role" element={<Role />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/advanced/search" element={<AdvancedSearch />} />
        <Route path="/result/search" element={<ResultSearch />} />
        <Route path="/search" element={<Search />} />
        <Route path="/search/:id" element={<Search />} />
        <Route path="/search/employer" element={<EmployerSearch />} />
        {/* <Route path='*' element={<Error />} /> */}
      </Routes>
  
    </div>
  );
}

export default Routers;
