import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AppliedBack, QuickJobsIcon } from "../../../components/icon";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllQuickJobsId,
  fetchCandidates,
} from "../../../store/quickJobsApi/quickJobsThunks";
import EmployerJobCard from "../../../components/cards/EmployerJobCard";
import quickJobsImg from "../../../assets/img/quickjobs.png";

export const QuickJobsCandidats = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { quickJobsId, status } = useSelector((state) => state.quickJobs);

  const { totalPages } = useSelector((state) => state.jobSeekers);
  const [currentPage, setCurrentPage] = useState(1);
  const [candidates, setCandidates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchAllQuickJobsId(id));
  }, [dispatch, id]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchCandidates({ page: currentPage, pageSize: 10, id: id }))
      .then((action) => {
        const jobSeekers = action.payload?.candidates || [];
        setCandidates((prevJobs) => [...prevJobs, ...jobSeekers]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching job seekers:", error);
        setIsLoading(false);
      });
  }, [dispatch, currentPage, id]);

  const handleShowMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const maskPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
    const maskedPart = "****";
    return phoneNumber.slice(0, -4) + maskedPart;
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = date.getUTCDate();
    const year = date.getUTCFullYear();

    // Array of month names
    const months = [
      "Yanvar",
      "Fevral",
      "Mart",
      "Aprel",
      "May",
      "Iyun",
      "Iyul",
      "Avgust",
      "Sentabr",
      "Oktabr",
      "Noyabr",
      "Dekabr",
    ];

    const month = months[date.getUTCMonth()];

    // Add leading zero to day if necessary
    const formattedDay = day < 10 ? `0${day}` : day;

    return `${formattedDay} ${month} ${year}`;
  }

  console.log(candidates);

  return (
    <div className="mb-20 mt-7 pt-25">
      <div className="flex justify-between mb-5">
        <Link
          to={`/quickjobs/${id}`}
          className="flex gap-4 items-center font-bold text-xl"
        >
          <AppliedBack />
        </Link>
      </div>

      <div>
        {status === "loading" ? (
          <div>
            <Link className="rounded-2xl shadow-lg p-4 justify-between flex gap-2">
              <div className="w-60 relative animate-pulse">
                <div className="flex justify-between">
                  <div className="h-2 bg-gray-200 w-20 rounded-full dark:bg-gray-700 mb-2.5"></div>
                  <div className="h-2 bg-gray-200 w-20 rounded-full dark:bg-gray-700 mb-2.5"></div>
                </div>
                <p className="mt-2 text-[#888] text-[12px]  font-medium text-start leading-[161.023%]">
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-2"></div>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-2"></div>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                </p>
                <p className="mt-2 absolute right-0 bottom-0 text-[#504d4d] text-[12px]  font-medium text-end">
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div>
                </p>
              </div>
              <div>
                <QuickJobsIcon />
              </div>
            </Link>
          </div>
        ) : (
          <div>
            <Link
              to={`/quickjobs/${quickJobsId?._id}`}
              className="rounded-2xl w-full shadow-md h-40 justify-between flex gap-2"
            >
              <div className="p-4 justify-between flex gap-2">
                <div className=" relative flex flex-col justify-between">
                  <div>
                    <div className="flex justify-between">
                      <h3 className="text-[#242132] text-[14px] text-start not-italic font-semibold leading-[normal]">
                        {quickJobsId?.title?.substring(0, 20)}...
                      </h3>
                      <p className="m-0 text-[#888] text-[12px] not-italic font-medium leading-[161.023%]">
                        +998{maskPhoneNumber(quickJobsId?.phoneNumber)}
                      </p>
                    </div>
                    <p className="mt-2 text-[#888] text-[12px] font-medium text-start leading-[161.023%]">
                      {quickJobsId?.description?.substring(0, 50)}...
                    </p>
                  </div>
                  <div className="mt-2 flex justify-between items-center">
                    <p className="text-[#504d4d] text-[12px] font-medium">
                      {formatDate(quickJobsId?.createdAt)}
                    </p>
                    <p className="text-[#504d4d] text-[12px] font-medium text-end">
                      {quickJobsId?.location}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="w-20 h-full">
                  <img className="h-full" src={quickJobsImg} alt="quickjobs" />
                </div>
              </div>
            </Link>
          </div>
        )}
      </div>

      <EmployerJobCard data={candidates} loading={isLoading} Open={true} />
      {candidates?.length ? (
        currentPage < totalPages && (
          <button
            onClick={handleShowMore}
            className="mt-10 block m-auto p-2 bg-blue-500 text-white rounded"
            disabled={isLoading}
          >
            {isLoading ? "Yuklanmoqda..." : "Ko'proq ko'rish"}
          </button>
        )
      ) : (
        <p className="flex justify-center mt-5 text-base font-semibold">
          Kandidatlar topilmadi
        </p>
      )}
    </div>
  );
};
