import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppliedBack } from "../../components/icon";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchQuickJobs,
  fetchRecentQuickJobs,
} from "../../store/openApi/openApiThunk";
import quickJobsImg from "../../assets/img/quickjobs.png";
import { useTranslation } from "react-i18next";

export const AllRecentJobs = () => {
  const dispatch = useDispatch();
  const { totalPages } = useSelector((state) => state.openApi);
  const [currentPage, setCurrentPage] = useState(1);
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const maskPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
    const maskedPart = "****";
    return phoneNumber.slice(0, -4) + maskedPart;
  };
  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchRecentQuickJobs({ page: currentPage, pageSize: 10 })).then(
      (action) => {
        const fetchedJobs = action?.payload?.recentJob;
        if (Array.isArray(fetchedJobs)) {
          setJobs((prevJobs) => [...prevJobs, ...action.payload.recentJob]);
        }
        setIsLoading(false);
      }
    );
  }, [dispatch, currentPage]);

  const handleShowMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = date.getUTCDate();
    const year = date.getUTCFullYear();

    // Array of month names
    const months = [
      "Yanvar",
      "Fevral",
      "Mart",
      "Aprel",
      "May",
      "Iyun",
      "Iyul",
      "Avgust",
      "Sentabr",
      "Oktabr",
      "Noyabr",
      "Dekabr",
    ];

    const month = months[date.getUTCMonth()];

    // Add leading zero to day if necessary
    const formattedDay = day < 10 ? `0${day}` : day;

    return `${formattedDay} ${month} ${year}`;
  }

  const { t } = useTranslation();

  return (
    <div className="mb-10">
      <Link to={"/"} className="flex gap-4 items-center font-bold text-xl">
        <AppliedBack />
       {t("Recent Jobs")}
      </Link>

      <ul className="mt-10 grid place-items-center grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
        {jobs.map((job) => (
          <Link
          key={job?._id}
            to={`/quickjobs/${job?._id}`}
            className="rounded-2xl bg-white w-full shadow-md h-40 justify-between flex gap-2"
          >
            <div className="p-4 justify-between flex gap-2">
              <div className=" relative flex flex-col justify-between">
                <div>
                  <div className="flex justify-between">
                    <h3 className="text-[#242132] text-[14px] text-start not-italic font-semibold leading-[normal]">
                      {job?.title?.substring(0, 20)}...
                    </h3>
                    <p className="m-0 text-[#888] text-[12px] not-italic font-medium leading-[161.023%]">
                      +998{maskPhoneNumber(job?.phoneNumber)}
                    </p>
                  </div>
                  <p className="mt-2 text-[#888] text-[12px] font-medium text-start leading-[161.023%]">
                    {job?.description.substring(0, 50)}...
                  </p>
                </div>
                <div className="mt-2 flex justify-between items-center">
                  <p className="text-[#504d4d] text-[12px] font-medium">
                    {formatDate(job?.createdAt)}
                  </p>
                  <p className="text-[#504d4d] text-[12px] font-medium text-end">
                    {job?.location}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="w-20 h-full">
                <img className="h-full" src={quickJobsImg} alt="quickjobs" />
              </div>
            </div>
          </Link>
        ))}
      </ul>

      {currentPage < totalPages && (
        <button
          onClick={handleShowMore}
          className="mt-10 block mx-auto p-2 bg-blue-500 text-white rounded"
          disabled={isLoading}
        >
          {isLoading ? "Yuklanmoqda..." : "Ko'proq ko'rish"}
        </button>
      )}
    </div>
  );
};
