export const options = [
  { value: "navaiy", label: "Navoiy" },
  { value: "tashkent", label: "Tashkent" },
  { value: "samarkand", label: "Samarkand" },
  // Add more options as needed
];

export const customStyles = {
  control: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    borderColor: "#D1D5DB",
    borderRadius: "0.5rem",
    padding: "0.25rem",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#D1D5DB",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0",
    paddingLeft: "1.75rem", // Adjust padding to align text properly
  }),
  placeholder: (provided) => ({
    ...provided,
    marginLeft: "0",
  }),
  singleValue: (provided) => ({
    ...provided,
    marginLeft: "0",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "0",
  }),
  input: (provided) => ({
    ...provided,
    marginLeft: "0",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};