import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchTournamentJoin } from "../../store/tournamentApi/tournamentThunk";
import { fetchCurrentUser } from "../../store/usersApi/usersThunks";
import toast from "react-hot-toast";

export const TournamentCard = ({ tournament, fetchTournament }) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.users);
  const currentId = currentUser?.data._id;

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, [dispatch]);

  // Handle the button click event
  const handleButtonClick = (e, id) => {
    e.preventDefault(); // Prevent navigation
    dispatch(fetchTournamentJoin(id)).then((res) => {
      if (res?.meta.requestStatus === "fulfilled") {
        dispatch(fetchTournament());
        toast.success("Kod olindi");
      }
    }); // Dispatch the thunk function
  };
  return (
    <div>
      {tournament?.map((item) => {
        const currentParticipant = item.participants?.find(
          (participant) => participant.userId === currentId
        );

        return (
          <div
            key={item.tournament_id}
            className="my-3 bg-[#F4F7F8] rounded-xl overflow-hidden shadow-lg"
          >
            <Link
              to={`/profile/tournament/${item._id}`}
              className="flex justify-between items-center gap-3"
            >
              <div className="">
                <img
                  className="rounded-l-lg w-47.5 h-47.5 object-cover object-center"
                  src={item.image}
                  alt="Tournament"
                />
              </div>
              <div className="flex-1">
                <div className="p-2">
                  <div className="flex justify-between items-center">
                    <div></div>
                    <p className="text-[#D3E4EE] text-xs font-semibold leading-none tracking-wider">
                      {item.type}
                    </p>
                  </div>
                  <div className="mt-0.5">
                    <p className="text-base font-bold">
                      {item.tournament_name}
                    </p>
                  </div>
                  <div className="mt-1.5">
                    <p className="text-[#2D7FAE] text-xs font-semibold leading-none tracking-wider">
                      {item.date_range}
                    </p>
                  </div>
                  <div className="mt-1">
                    <p className="text-[#2D7FAE] text-xs font-semibold leading-none tracking-wider">
                      {item.location}
                    </p>
                  </div>
                  <div className="mt-1.5">
                    <p className="text-[#599ABF] text-xs font-semibold leading-none ">
                      Bosh sovrin jamg'armasi:
                    </p>
                  </div>
                  <div className="mt-1.5">
                    <p className="text-[#D7C878] text-xl font-semibold">
                      {item.prize_pool}
                    </p>
                  </div>
                  <div className="mt-2">
                    {currentParticipant && item?.status === "open" ? (
                      <button className="bg-[#D3E4EE] text-[#438CB7] text-sm h-7 w-36 rounded-3xl">
                        {currentParticipant.specialCode}
                      </button>
                    ) : item?.status === "open" ? (
                      <button
                        onClick={(e) => handleButtonClick(e, item._id)}
                        className="bg-[#438CB7] text-white text-sm h-7 w-36 rounded-3xl"
                      >
                        Kod olish
                      </button>
                    ) : item.status === "closed" ? (
                      <button className="bg-gradient-to-r from-red-400 via-red-500 to-red-600 text-white text-sm h-7 w-36 rounded-3xl">
                        Yopilgan
                      </button>
                    ) : item.status === "expired" ? (
                      <button className=" bg-gradient-to-r from-gray-500 via-gray-500 to-gray-600 text-white text-sm h-7 w-36 rounded-3xl">
                        Muddati tugagan
                      </button>
                    ) : item.status === "coming" ? (
                      <button className="bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 text-white text-sm h-7 w-36 rounded-3xl">
                        Tez kunda
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
};
