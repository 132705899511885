import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { searchEmployersByCompany } from "../../../../store/employersApi/employersThunks";
import { ArrowLeftIcon, HRArrow } from "../../../../components/icon";
import { useTranslation } from "react-i18next";

export const AddHr = () => {
  const dispatch = useDispatch();
  const { totalPages } = useSelector((state) => state.employers);
  const [currentPage, setCurrentPage] = useState(1);
  const [hrs, setHrs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      searchEmployersByCompany({
        page: currentPage,
        pageSize: 10,
        fullName: searchQuery,
      })
    ).then((action) => {
      if (currentPage === 1) {
        setHrs(action.payload?.employers);
      } else {
        setHrs((prevJobs) => [...prevJobs, ...action.payload?.employers]);
      }
      setIsLoading(false);
    });
  }, [dispatch, currentPage, searchQuery]);

  const handleShowMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const { t } = useTranslation();


  return (
    <nav className="overflow-hidden mx-auto">
      <Link
        to={"/profile/company"}
        className="flex gap-4 items-center font-bold text-xl"
      >
        <ArrowLeftIcon />
        {t("HRs")}
      </Link>
      <div className="mt-10">
        <ul className="flex flex-col gap-6">
          {hrs?.length
            ? hrs?.map((item) => (
                <Link
                  className="flex  items-center justify-between"
                  to={`/profile/hrs/${item._id}`}
                  key={item._id}
                >
                  <div className="flex gap-4">
                    <img
                      className="w-10 h-10 rounded-full"
                      alt={item.fullName}
                      src={item.avatar}
                    />
                    <div>
                      <p className="text-black text-xl font-semibold">
                        {item.fullName}
                      </p>
                      <p className="text-[#878787] text-base h-5 font-semibold">
                        {item.employer.companyName}
                      </p>
                    </div>
                  </div>
                  <HRArrow />
                </Link>
              ))
            : ""}
        </ul>
        {/* <EmployerJobCard data={jobs} status={status} /> */}
        {currentPage < totalPages && hrs.length > 0 && (
          <button
            onClick={handleShowMore}
            className="mt-10 block m-auto p-2 bg-blue-500 text-white rounded"
            disabled={isLoading}
          >
            Ko'proq ko'rish
          </button>
        )}
      </div>
    </nav>
  );
};
