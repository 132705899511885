import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  createOffice,
  fetchOfficeId,
  fetchOfficeMy,
  updateOffice,
} from "./../../../../store/officeApi/officeApiThunk";
import { ButtonSpinner } from "../../../../components/buttonSpinner/ButtonSpinner";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AppliedBack } from "../../../../components/icon";
import { uzbekistanRegions } from "../../../../constants/Region";
import InputMask from "react-input-mask";
import { useTranslation } from "react-i18next";

function OfficeForm() {
  const { status } = useSelector((state) => state.office);
  const [images, setImages] = useState([]);
  const [formData, setFormData] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { officeId } = useSelector((state) => state.office);

  useEffect(() => {
    dispatch(fetchOfficeId(id));

    if (id) {
      setImages(officeId?.images || []);
      setFormData({
        title: officeId?.title || "",
        description: officeId?.description || "",
        location: officeId?.location || "",
        price: officeId?.price || "",
        phoneNumber: `+998${officeId?.phoneNumber || ""}`,
        officeType: officeId?.officeType || "",
      });
    } else {
      setImages([]);
      setFormData({
        title: "",
        description: "",
        location: "",
        price: "",
        phoneNumber: "+998",
        officeType: "",
      });
    }
  }, [dispatch, id]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages([...images, ...files]);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhoneChange = (e) => {
    setFormData({
      ...formData,
      phoneNumber: e.target.value,
    });
  };

  const removeImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    images.forEach((image) => {
      data.append("officeImages", image);
    });
    Object.keys(formData).forEach((key) => {
      let value = formData[key];
      if (key === "phoneNumber") {
        value = formData.phoneNumber.replace(/\D/g, "").slice(3, 12);
      }
      data.append(key, value);
    });
    console.log(data);
    if (id) {
      dispatch(updateOffice({ id, updatedOffice: data }))
        .then((response) => {
          if (response?.meta?.requestStatus === "fulfilled") {
            toast.success("Muvaffaqqiyatli yangilandi!");
            dispatch(fetchOfficeMy({ page: 1, pageSize: 10 }));
            // navigate("/announ");
          }
        })
        .catch((error) => {
          console.error("Error creating office: ", error);
        });
    } else {
      dispatch(createOffice(data))
        .then((response) => {
          if (response?.meta?.requestStatus === "fulfilled") {
            toast.success("Muvaffaqqiyatli qo'shildi!");
            dispatch(fetchOfficeMy({ page: 1, pageSize: 10 }));
            navigate("/announ");
          }
        })
        .catch((error) => {
          console.error("Error creating office: ", error);
        });
    }
  };

  const { t } = useTranslation();

  return (
    <div className="mx-auto  bg-white p-8 shadow-md rounded-md mb-20">
      <Link
        to={"/announ"}
        className="flex gap-4 items-center font-bold text-xl"
      >
        <AppliedBack />
        Ofis e'lon
      </Link>
      <form onSubmit={handleSubmit} className="space-y-4 mt-10">
          <div>
            <label
              htmlFor="imgs"
              className="flex justify-center mb-4 text-sm font-bold text-gray-700"
            >
              <span className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Ofis rasmi
              </span>
            </label>
            <input
              id="imgs"
              type="file"
              multiple
              onChange={handleImageChange}
              className="hidden w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
            />
            <div className="flex space-x-2 mt-2">
              {images?.map((image, index) => (
                <div key={index} className="relative">
                  {image instanceof File ? (
                    <img
                      src={URL.createObjectURL(image)}
                      alt="New"
                      className="w-32 h-32 object-cover"
                    />
                  ) : (
                    <img
                      src={image}
                      alt="New"
                      className="w-32 h-32 object-cover"
                    />
                  )}
                  <button
                    type="button"
                    onClick={() => removeImage(index)}
                    className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
                  >
                    X
                  </button>
                </div>
              ))}
            </div>
          </div>
        <div className="grid grid-cols-3 gap-5">
          <div>
            <label className="block mb-2 text-sm font-bold text-gray-700">
              Ofis nomi
            </label>
            <input
              required
              type="text"
              name="title"
              value={formData?.title}
              onChange={handleInputChange}
              className="block w-full px-4 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none"
            />
          </div>
          
          <div>
            <label className="block mb-2 text-sm font-bold text-gray-700">
              Joriy manzil
            </label>
            <select
              required
              name="location"
              value={formData?.location}
              onChange={handleInputChange}
              className="block w-full px-4 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none"
            >
              <option disabled value="">
                Manzil tanlang
              </option>
              {uzbekistanRegions.map((region) => (
                <option key={region} value={region}>
                  {t(`${region}`)}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block mb-2 text-sm font-bold text-gray-700">
              Narx
            </label>
            <input
              required
              type="number"
              name="price"
              value={formData?.price}
              onChange={handleInputChange}
              className="block w-full px-4 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none"
            />
          </div>
          <div>
            <label className="block mb-2 text-sm font-bold text-gray-700">
              Telefon raqam
            </label>
            <InputMask
              mask="+998 (99) 999 9999"
              maskChar={null}
              value={formData?.phoneNumber}
              onChange={handlePhoneChange}
              className="block w-full px-4 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none"
              placeholder="+998 (__) ___ ____"
              required
            />
          </div>
          <div>
            <label className="block mb-2 text-sm font-bold text-gray-700">
              Ofis turi
            </label>
            <select
              required
              name="officeType"
              value={formData?.officeType}
              onChange={handleInputChange}
              className="block w-full px-4 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none"
            >
              <option disabled value="">
                Turini tanlang
              </option>
              <option value="Ofis ijarasi">Ofis ijarasi</option>
              <option value="Bino ijarasi">Bino ijarasi</option>
              <option value="Ofis savdosi">Ofis savdosi</option>
              <option value="Bino savdosi">Bino savdosi</option>
            </select>
          </div>
          <div>
            <label className="block mb-2 text-sm font-bold text-gray-700">
              Ofis haqida ma'lumot
            </label>
            <textarea
              required
              name="description"
              value={formData?.description}
              onChange={handleInputChange}
              className="block w-full px-4 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none"
            ></textarea>
          </div>
        </div>
        <button
          disabled={status === "loading"}
          type="submit"
          className="w-full py-2 px-4 bg-blue-600 text-white rounded-md"
        >
          {status === "loading" ? <ButtonSpinner /> : "Joylash"}
        </button>
      </form>
    </div>
  );
}

export default OfficeForm;
