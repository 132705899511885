import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllQuickJobsId } from "../../../../store/quickJobsApi/quickJobsThunks";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ButtonSpinner } from "../../../../components/buttonSpinner/ButtonSpinner";
import { AppliedBack } from "../../../../components/icon";
import InputMask from "react-input-mask";
import toast from "react-hot-toast";
import { uzbekistanRegions } from "../../../../constants/Region";
import {
  useCreateQuickJobMutation,
  useUpdateQuickJobByIdMutation,
} from "../../../../services";
import { fetchCurrentUser } from "../../../../store/usersApi/usersThunks";
import { useTranslation } from "react-i18next";

const QuickJobForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { quickJobsId } = useSelector((state) => state.quickJobs);
  const { currentUser } = useSelector((state) => state.users);
  const navigate = useNavigate();

  const [formData, setFormData] = useState();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handlePhoneChange = (e) => {
    setFormData({
      ...formData,
      phoneNumber: e.target.value,
    });
  };

  const [
    createQuickJobMutation,
    {
      isLoading: createLoading,
      isSuccess: createSuccess,
      isError: createError,
    },
  ] = useCreateQuickJobMutation();

  const [
    updateQuickJobByIdMutation,
    {
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      isError: updateError,
    },
  ] = useUpdateQuickJobByIdMutation();

  useEffect(() => {
    if (createSuccess || updateSuccess) {
      toast.success("Ish muvaffaqiyatli yaratildi!");
      navigate("/announ");
    }
    if (createError || updateError) {
      toast.error(`Ish yaratishda xatolik yuz berdi`);
    }
  }, [createSuccess, createError, updateSuccess, updateError]);

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const rawPhoneNumber = formData.phoneNumber.replace(/\D/g, "").slice(3, 12);

    if (rawPhoneNumber === "") {
      toast.error("Raqamni kiriting!");
      return;
    }
    const jobData = {
      ...formData,
      phoneNumber: rawPhoneNumber,
    };

    if (id) {
      try {
        await updateQuickJobByIdMutation({ id, updatedJob: jobData }).unwrap();
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await createQuickJobMutation(jobData).unwrap();
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    dispatch(fetchAllQuickJobsId(id));
    if (id) {
      setFormData({
        title: quickJobsId?.title || "",
        description: quickJobsId?.description || "",
        phoneNumber: `+998${quickJobsId?.phoneNumber || ""}`,
        location: quickJobsId?.location || "",
        callOnly: quickJobsId?.callOnly || false,
      });
    } else {
      setFormData({
        title: "",
        description: "",
        phoneNumber: "+998",
        location: "",
        callOnly: false,
      });
    }
  }, [dispatch, id]);

  const { t } = useTranslation();

  return (
    <div className="mx-auto  bg-white p-8 shadow-md rounded-md mb-20">
      <Link
        to={"/announ"}
        className="flex gap-4 items-center font-bold text-xl"
      >
        <AppliedBack />
        Tezkor e'lon
      </Link>
      <form className="mt-10" onSubmit={handleSubmit}>
       <div className="grid grid-cols-3 gap-5">
       <div className="mb-4">
          <label className="block text-sm font-bold mb-1" htmlFor="title">
            Sarlavha
          </label>
          <input
            type="text"
            id="title"
            required
            name="title"
            className="w-full border-gray-300 rounded-md"
            value={formData?.title}
            onChange={handleChange}
          />
        </div>
        
        <div className="mb-4">
          <label className="block text-sm font-bold mb-1" htmlFor="phoneNumber">
            Telefon raqam
          </label>
          <div className="">
            <InputMask
              mask="+998 (99) 999 9999"
              maskChar={null}
              value={formData?.phoneNumber}
              onChange={handlePhoneChange}
              className="border border-solid border-gray-300 focus:outline-none text-gray-900 text-sm rounded-lg block w-full p-2.5"
              placeholder="+998 (__) ___ ____"
              required
            />
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold mb-1" htmlFor="location">
            Manzil
          </label>
          <select
            required
            id="location"
            name="location"
            className="w-full border-gray-300 rounded-md"
            value={formData?.location}
            onChange={handleChange}
          >
            <option disabled value="">
              Tanlang
            </option>
            {uzbekistanRegions.map((region) => (
              <option key={region} value={region}>
                {t(`${region}`)}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold mb-1" htmlFor="description">
            Ish tarifi
          </label>
          <textarea
            required
            id="description"
            name="description"
            className="w-full border-gray-300 rounded-md"
            value={formData?.description}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label className="inline-flex items-center">
            <span>Faqat qo'ng'iroq qabul qilish</span>
            <input
              type="checkbox"
              name="callOnly"
              className="form-checkbox ml-2"
              checked={formData?.callOnly}
              onChange={handleChange}
            />
          </label>
        </div>
       </div>
        <div className="mb-4">
          <button
            disabled={updateLoading || createLoading}
            type="submit"
            className="w-full py-2 px-4 bg-blue-600 text-white rounded-md"
          >
            {updateLoading || createLoading ? <ButtonSpinner /> : "Joylash"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default QuickJobForm;
