import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

// Fetch awards
export const fetchAwards = createAsyncThunk("awards/fetchAwards", async () => {
  const response = await axios.get("/users/resume/awards");
  console.log("fetchAwards", response.data);
  return response.data; // Assuming the response contains { data: { awards: [...] } }
});

// Add award
export const addAward = createAsyncThunk("awards/addAward", async (award) => {
  console.log("addAward", award);
  const response = await axios.post("/users/resume/awards", award);
  return response.data; // Assuming the response contains the added award
});

// Update award by id
export const updateAward = createAsyncThunk(
  "awards/updateAward",
  async ({ id, award }) => {
    const response = await axios.put(`/users/resume/awards/${id}`, {
      title: award.title,
      issuer: award.issuer,
      dateAwarded: award.dateAwarded,
      description: award.description,
    });
    return response.data; // Assuming the response contains the updated award
  }
);

// Delete award by id
export const deleteAward = createAsyncThunk(
  "awards/deleteAward",
  async (id) => {
    const response = await axios.delete(`/users/resume/awards/${id}`);
    return response.data; // Assuming the response contains the updated awards list
  }
);
