import { Link } from "react-router-dom";
import FooterMobile from "../../layouts/FooterMobile";
import EmployerFooterMobile from "../../layouts/employer/Footer";
import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import { BASE_URL_DOMAIN } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentUser } from "../../store/usersApi/usersThunks";
const socket = io(BASE_URL_DOMAIN); // Change to your server URL

export const ChatLists = () => {
  const [chatRooms, setChatRooms] = useState([]);
  const [onlineUser, setOnlineUser] = useState([]);

  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.users);
  useEffect(() => {
    const user = currentUser?.data;
    console.log("user: ", user);
    if (user) {
      socket.emit("requestChatRooms", { userId: user._id });
      console.log("user 2: ", user);
      socket.on("chatRoomsResponse", (response) => {
        if (response.status === 200) {
          setChatRooms(response.data);
        }
      });
      socket.on("getOnlineUsers", (onlineUsers) => {
        setOnlineUser(onlineUsers);
      });
    }
  }, []);

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, [dispatch]);
  console.log("online: ", onlineUser);
  const hasOnlineUser = onlineUser?.map((item) => item.userId);

  return (
    <div>
      <div className="relative max-w-screen-xl mb-10 m-auto flex w-full overflow-hidden antialiased bg-gray-200">
        <div className="relative flex flex-col w-full bg-white border-r border-gray-300 shadow-xl md:block transform transition-all duration-500 ease-in-out">
          <div className="relative mt-2 mb-4 overflow-x-hidden overflow-y-auto scrolling-touch lg:max-h-sm scrollbar-w-2 scrollbar-track-gray-lighter scrollbar-thumb-rounded scrollbar-thumb-gray">
            <ul className="flex flex-col w-full px-2 select-none">
              {chatRooms?.length ? (
                chatRooms.map((chatRoom, index) => (
                  <Link
                    key={index}
                    to={`/chat/${chatRoom._id}`}
                    className="flex flex-no-wrap items-center pr-3 text-black rounded-lg cursor-pointer mt-200 py-5 hover:bg-gray-200"
                  >
                    <div className="flex justify-between w-full">
                      <div className="flex justify-between w-full">
                        <div className="relative flex items-center justify-center w-12 h-12 ml-2 mr-3 text-xl font-semibold text-white bg-blue-500 rounded-full flex-no-shrink">
                          <img
                            className="object-cover w-12 h-12 rounded-full"
                            src={chatRoom.otherUser.avatar}
                            alt={chatRoom.otherUser.fullName}
                          />
                          <div
                            className="absolute bottom-0 right-0 flex items-center justify-center bg-white rounded-full"
                            style={{ width: "0.80rem", height: "0.80rem" }}
                          >
                            {hasOnlineUser?.includes(
                              chatRoom?.otherUser._id
                            ) ? (
                              <div
                                className="bg-green-500 rounded-full"
                                style={{ width: "0.6rem", height: "0.6rem" }}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="items-center flex-1 min-w-0">
                          <div className="flex justify-between mb-1">
                            <h2 className="text-base font-semibold text-black">
                              {chatRoom.otherUser.fullName} <br />
                            </h2>
                            <div className="flex">
                              <span className="ml-1 text-xs font-medium text-gray-600">
                                {new Date(
                                  chatRoom.lastMessage?.timestamp
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })}
                              </span>
                            </div>
                          </div>
                          <div className="flex justify-between text-base leading-none truncate">
                            <span>
                              {chatRoom.lastMessage?.text || "No messages yet"}
                            </span>
                            {chatRoom.unreadMessagesCount > 0 && (
                              <span className="flex items-center justify-center w-5 h-5 text-xs text-right text-white bg-green-500 rounded-full">
                                {chatRoom.unreadMessagesCount}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
              ) : (
                <p className="flex justify-center mt-20 font-semibold ">
                  Hozircha chatlar mavjud emas
                </p>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
