import { createSlice } from "@reduxjs/toolkit";
import {
  deleteCompanyEmployer,
  fetchCompanies,
  fetchCompaniesById,
  updateCompanyIntro,
} from "./companyThunk"; // Adjust the path according to your structure

const initialState = {
  company: [],
  companies: [],
  status: "idle",
  error: null,
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(updateCompanyIntro.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCompanyIntro.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.avatar = action.payload.url;
      })
      .addCase(updateCompanyIntro.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(deleteCompanyEmployer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCompanyEmployer.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.company = state.company.filter(
          (companies) => companies._id !== action.payload
        ); // Remove the deleted job from the list
      })
      .addCase(deleteCompanyEmployer.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(fetchCompanies.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchCompanies.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.companies = action.payload.companies;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
      })

      .addCase(fetchCompaniesById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchCompaniesById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCompaniesById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.companies = action.payload.companies;
      });
  },
});

export default companySlice.reducer;
