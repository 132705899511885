/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../../../assets/img/logo1.jpg";
import { AppliedBack } from "../../../../components/icon";
import {
  fetchCompanies,
  requestCompanyEmployer,
} from "../../../../store/companyApi/companyThunk";
import { fetchCurrentUser } from "../../../../store/usersApi/usersThunks";
import toast from "react-hot-toast";

export const ApplyCompanySingle = () => {
  const { id } = useParams();
  const { companies, status } = useSelector((state) => state.company);

  const [activeTab, setActiveTab] = useState("profile");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCompanies());
  }, [dispatch, id]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const findCompany = companies?.filter((item) => item._id === id)[0];

  const handleAdded = (id) => {
    const idx = id?.target.id;
    console.log(idx);
    dispatch(requestCompanyEmployer(idx)).then((result) => {
      console.log(result);
      if (result?.meta.requestStatus === "fulfilled") {
        dispatch(fetchCurrentUser());
        toast.success("So'rov yuborildi!");
      }
      if (result?.meta.requestStatus === "rejected") {
        dispatch(fetchCurrentUser());
        toast.error("Avval yuborilgan!");
      }
    });
  };

  console.log(findCompany);

  return (
    <div className="mb-40 hero-banner pt-25">
      <div className="flex justify-between mb-5">
        <Link
          to={"/profile/apply/company"}
          className="flex gap-4 items-center font-bold text-xl"
        >
          <AppliedBack />
        </Link>
      </div>

      <div>
        <div className="flex gap-4 items-center">
          <div>
            <div className="rounded-2xl w-16 h-16 overflow-hidden border-[1px] border-[solid] border-[var(--Greyscale-200,#EEE)]">
              <img src={findCompany?.logo[0] || logo} alt="img" />
            </div>
          </div>
          <div>
            <h3 className="text-[#000] text-[20px] font-semibold tracking-[0.2px]">
              {findCompany?.name}
            </h3>
            <p className="text-[#878787] text-base font-semibold tracking-[0.2px]">
              {findCompany?.size || 0} Ishchi
            </p>
          </div>
        </div>
        <div className="flex justify-between">
          <p className="text-[#CBCBCB] text-base mt-2 font-semibold tracking-[0.2px]">
            {findCompany?.location}
          </p>

          <button className="rounded-xl text-[#FFF] text-center text-base px-3 py-1 font-normal bg-[#2A5771]">
            Kuzatish
          </button>
        </div>

        <div>
          <div className="text-base font-medium text-center mt-7 text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
            <ul className="flex justify-between">
              <li className="me-2">
                <a
                  href="#"
                  onClick={() => handleTabClick("profile")}
                  className={`inline-block p-4 border-b-2 text-base rounded-t-lg tab-link ${
                    activeTab === "profile"
                      ? "tab-link-active"
                      : "tab-link-inactive"
                  }`}
                >
                  Biz haqimizda
                </a>
              </li>
              <li className="me-2">
                <a
                  href="#"
                  onClick={() => handleTabClick("dashboard")}
                  className={`inline-block p-4 border-b-2 text-base  rounded-t-lg tab-link ${
                    activeTab === "dashboard"
                      ? "tab-link-active"
                      : "tab-link-inactive"
                  }`}
                >
                  Takliflar
                </a>
              </li>
              <li className="me-2">
                <a
                  href="#"
                  onClick={() => handleTabClick("settings")}
                  className={`inline-block p-4 border-b-2 text-base rounded-t-lg tab-link ${
                    activeTab === "settings"
                      ? "tab-link-active"
                      : "tab-link-inactive"
                  }`}
                >
                  HRlar
                </a>
              </li>
            </ul>
          </div>

          {/* Content for each tab */}
          <div className="tab-content mt-4">
            {activeTab === "profile" && (
              <div>
                <ul className="flex flex-col gap-1">
                  {findCompany?.working_time ? (
                    <li className="flex items-center gap-2">
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 0C2.7 0 0 2.7 0 6C0 9.3 2.7 12 6 12C9.3 12 12 9.3 12 6C12 2.7 9.3 0 6 0ZM3.42 8.1L3 7.32L5.4 5.94V3H6.3V6.48L3.42 8.1Z"
                          fill="black"
                        />
                      </svg>

                      <p className="m-0 text-[#000] text-base font-medium">
                        {findCompany?.working_time}
                      </p>
                    </li>
                  ) : (
                    ""
                  )}
                  {findCompany?.working_days ? (
                    <li className="flex items-center gap-2">
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.8 0H7.2C7.51826 0 7.82348 0.133082 8.04853 0.36997C8.27357 0.606858 8.4 0.928148 8.4 1.26316V2.52632H10.8C11.1183 2.52632 11.4235 2.6594 11.6485 2.89629C11.8736 3.13317 12 3.45446 12 3.78947V10.7368C12 11.0719 11.8736 11.3931 11.6485 11.63C11.4235 11.8669 11.1183 12 10.8 12H1.2C0.534 12 0 11.4316 0 10.7368V3.78947C0 3.08842 0.534 2.52632 1.2 2.52632H3.6V1.26316C3.6 0.562105 4.134 0 4.8 0ZM7.2 2.52632V1.26316H4.8V2.52632H7.2Z"
                          fill="black"
                        />
                      </svg>

                      <p className="m-0 text-[#000] text-base font-medium">
                        {findCompany?.working_days}
                      </p>
                    </li>
                  ) : (
                    ""
                  )}
                  {findCompany?.overtime ? (
                    <li className="flex items-center gap-2">
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.2743 12L10.8571 11.5829H1.08V10.44H9.71429L6.73143 7.45714L6.79429 7.58286H2.79429L3.93714 5.29714H4.57143L4 4.72571H3.93714C3.30857 4.72571 2.79429 4.21143 2.79429 3.58286V3.52L0 0.725714L0.731429 0L12 11.2743L11.2743 12ZM6.79429 3.58286C7.21714 3.58286 7.58286 3.35429 7.77714 3.01143H10.2229V8.04L11.3657 9.18286V3.01143C11.3657 2.37714 10.8571 1.86857 10.2229 1.86857H7.77714C7.58286 1.53143 7.21714 1.29714 6.79429 1.29714C6.79429 0.668571 6.28 0.154286 5.65143 0.154286H3.93714C3.50286 0.154286 3.13143 0.4 2.93714 0.754286L6.50857 4.32571C6.68571 4.12571 6.79429 3.86857 6.79429 3.58286Z"
                          fill="black"
                        />
                      </svg>

                      <p className="m-0 text-[#000] text-base font-medium">
                        {findCompany?.overtime}
                      </p>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
                <div>
                  <div className="flex flex-col justify-between mt-5">
                    <p className="text-[#000] m-0 text-[19px] font-semibold tracking-[0.2px] ">
                      Kompaniya taqdimoti{" "}
                    </p>
                    {findCompany?.description ? (
                      <div className="mt-2">
                        <p className="m-0 text-[#000] text-base font-medium">
                          {findCompany?.description}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <ul className="flex flex-col gap-1">
                  <p className="text-[#000] mb-2 text-[19px] font-semibold tracking-[0.2px] mt-5">
                    Biznes ma'lumotlar
                  </p>
                  <li className="flex items-center gap-2">
                    <p className="m-0 text-[#000] text-base font-medium">
                      Korxona nomi:
                    </p>
                    <p className="m-0 text-[#000] text-base font-medium">
                      {findCompany?.name}
                    </p>
                  </li>
                  <li className="flex items-center gap-2">
                    <p className="m-0 text-[#000] text-base font-medium">
                      Yuridik vakil:
                    </p>
                    {findCompany?.info?.legal_representative ? (
                      <p className="m-0 text-[#000] text-base font-medium">
                        {findCompany?.info?.legal_representative}
                      </p>
                    ) : (
                      ""
                    )}
                  </li>
                  <li className="flex items-center gap-2">
                    <p className="m-0 text-[#000] text-base font-medium">
                      Ro'yxatdan o'tgan kapital:
                    </p>
                    {findCompany?.info?.registration_capital ? (
                      <p className="m-0 text-[#000] text-base font-medium">
                        {findCompany?.info?.registration_capital}
                      </p>
                    ) : (
                      ""
                    )}
                  </li>
                  <li className="flex items-center gap-2">
                    <p className="m-0 text-[#000] text-base font-medium">
                      Tashkil etilgan sana:
                    </p>
                    {findCompany?.info?.date_of_establishment !== "" ? (
                      <p className="m-0 text-[#000] text-base font-medium">
                        {findCompany?.info?.date_of_establishment}
                      </p>
                    ) : (
                      ""
                    )}
                  </li>
                </ul>
              </div>
            )}
            {activeTab === "dashboard" && (
              <div>
                <p className="flex justify-center font-bold mt-10">
                  Hozircha e'lon joylamagansiz.
                </p>

                {/* <div>
                    <div className="mt-10">
                    <QuickJobCard status={status} quickJobs={[]} />

                    </div>

                    <div className="mt-10">
                    <LastJobsCard jobs={[]} status={status} />
                    </div>
                  </div> */}
              </div>
            )}
            {activeTab === "settings" && (
              <div>
                <ul className="flex flex-col gap-5">
                  {findCompany?.workers?.map((item) => (
                    <li key={item.userId}>
                      <div className="flex justify-between items-center gap-3">
                        <div className="flex items-center gap-3">
                          <img
                            className="w-10 h-10 rounded-full"
                            alt={item.fullName}
                            src={item.avatar}
                          />
                          <p className="m-0 text-[#000] text-base font-semibold">
                            {item.fullName} -{" "}
                            {item.isAdmin
                              ? "Kompaniya HR"
                              : "Kompaniya Assistent"}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className="w-full ">
          <button
            id={findCompany?._id}
            onClick={(id) => handleAdded(id)}
            className={`shadow-md   mx-auto sm:w-3/4 md:w-2/4 fixed inset-x-0 bottom-20  text-white font-bold text-base  bg-[#246BFD] rounded-[100px] p-4 `}
            type="button"
          >
            Qo'shilishga ariza berish
          </button>
        </div>
      </div>
    </div>
  );
};
