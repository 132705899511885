// CountrySelector.jsx
import React, { useEffect, useState } from "react";
import "flag-icons/css/flag-icons.min.css";
import { countries } from "../constants/Country";

const CountrySelector = ({ onChange }) => {
  const [selectedCountry, setSelectedCountry] = useState(countries[226]);

  useEffect(() => {
    onChange(selectedCountry.dialCode);
  }, []); // Empty dependency array means this effect runs once when the component mounts.

  const handleCountryChange = (event) => {
    const selected = countries.find(
      (country) => country.dialCode === event.target.value
    );
    setSelectedCountry(selected);
    onChange(selected.dialCode);
  };

  return (
    <div className="relative z-50">
      <div className="flex items-center space-x-2 rounded-2xl border-[0.5px] border-[solid] border-[#E5E5E5] p-2">
        <span
          className={`fi fi-${selectedCountry.code.toLowerCase()} text-lg`}
        ></span>
        <span className="text-[#212121] text-[16px] not-italic font-semibold leading-[140%]">{selectedCountry.dialCode}</span>
      </div>
      <select
        value={selectedCountry.dialCode}
        onChange={handleCountryChange}
        className="absolute inset-0 opacity-0 cursor-pointer"
      >
        {countries.map((country) => (
          <option key={country.code} value={country.dialCode}>
            {country.name} {country.dialCode}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CountrySelector;
