import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

// Fetch certificates
export const fetchCertificates = createAsyncThunk(
  "certificates/fetchCertificates",
  async () => {
    const response = await axios.get("/users/resume/certificates");
    console.log("fetchCertificates", response.data);
    return response.data; // Assuming the response contains { data: { certificates: [...] } }
  }
);

// Add certificate
export const addCertificate = createAsyncThunk(
  "certificates/addCertificate",
  async (certificate) => {
    console.log("addCertificate", certificate);
    const response = await axios.post(
      "/users/resume/certificates",
      certificate
    );
    return response.data; // Assuming the response contains the added certificate
  }
);

// Update certificate by id
export const updateCertificate = createAsyncThunk(
  "certificates/updateCertificate",
  async ({ id, certificate }) => {
    const response = await axios.put(`/users/resume/certificates/${id}`, {
      title: certificate.title,
      organization: certificate.organization,
      dateOfIssue: certificate.dateOfIssue,
      expirationDate: certificate.expirationDate,
      notExpire: certificate.notExpire,
      credentialId: certificate.credentialId,
      credentialUrl: certificate.credentialUrl,
    });
    return response.data; // Assuming the response contains the updated certificate
  }
);

// Delete certificate by id
export const deleteCertificate = createAsyncThunk(
  "certificates/deleteCertificate",
  async (id) => {
    const response = await axios.delete(`/users/resume/certificates/${id}`);
    return response.data; // Assuming the response contains the updated certificates list
  }
);
