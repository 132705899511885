import { createSlice } from "@reduxjs/toolkit";
import {
  fetchSkills,
  addSkills,
  updateSkills,
  deleteSkills,
} from "./skillsThunks"; // Adjust the path according to your structure

const initialState = {
  skills: [],
  status: "idle",
  error: null,
};

const skillsSlice = createSlice({
  name: "skills",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSkills.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSkills.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.skills = action.payload.data; // Adjust based on actual response structure
      })
      .addCase(fetchSkills.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addSkills.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addSkills.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload.data && action.payload.data.skills) {
          state.skills.push(...action.payload.data.skills); // Adjust based on actual response structure
        } else {
          state.error = "Failed to add skills";
        }
      })
      .addCase(addSkills.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateSkills.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateSkills.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.skills.findIndex(
          (skill) => skill.id === action.payload.data.id
        );
        if (index !== -1) {
          state.skills[index] = action.payload.data.skills; // Adjust based on actual response structure
        }
      })
      .addCase(updateSkills.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteSkills.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteSkills.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.skills = state.skills.filter(
          (skill) => skill.id !== action.payload.data.id
        ); // Adjust based on actual response structure
      })
      .addCase(deleteSkills.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default skillsSlice.reducer;
