import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addWorkExperience } from "../../../store/workExperienceApi/workExperienceThunks";
import toast from "react-hot-toast";

const WorkExperienceDrawer = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [jobTitle, setJobTitle] = useState("");
  const [company, setCompany] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [current, setCurrent] = useState(false);
  const [description, setDescription] = useState("");
  const [employmentType, setEmploymentType] = useState("");
  const [location, setLocation] = useState("");

  const handleSubmit = () => {
    if (
      jobTitle?.trim() === "" ||
      company?.trim() === "" ||
      startDate?.trim() === "" ||
      employmentType?.trim() === "" ||
      location?.trim() === ""
    ) {
      toast.error("Maydonni to'ldiring");
    } else {
      dispatch(
        addWorkExperience({
          jobTitle,
          company,
          startDate,
          endDate,
          current,
          description,
          employmentType,
          location,
        })
      );
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed  inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm z-50  ">
      <div className="absolute bg-black opacity-50 w-full h-full"></div>
      <div className="absolute bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 z-10">
        <button
          className="absolute top-0 right-0 m-4 text-lg"
          onClick={onClose}
        >
          X
        </button>
        <h2 className="text-center text-xl mb-4">Ish tajriba</h2>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="text"
          placeholder="Lavozim"
          value={jobTitle}
          onChange={(e) => setJobTitle(e.target.value)}
        />
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="text"
          placeholder="Korxona"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
        />
        <div className="flex mb-4">
          <div className="w-1/2 mr-2">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="date"
              placeholder="Start Date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="w-1/2 ml-2">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="date"
              placeholder="End Date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              disabled={current}
            />
          </div>
        </div>
        <div className="flex items-center mb-4">
          <input
            className="mr-2 leading-tight"
            type="checkbox"
            checked={current}
            onChange={(e) => setCurrent(e.target.checked)}
          />
          <span>Hozir ham ishlayman</span>
        </div>
        <textarea
          className="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Ta'rif (ixtiyoriy)"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <select
          className="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          value={employmentType}
          onChange={(e) => setEmploymentType(e.target.value)}
        >
          <option value="">Ish turi</option>
          <option value="Full-time">To'liq kun</option>
          <option value="Part-time">Yarim kun</option>
          <option value="Contract">Aytilmaydi</option>
        </select>
        <select
          className="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        >
          <option value="">Manzil</option>
          <option value="Tashkent">Tashkent</option>
          <option value="Samarkand">Samarkand</option>
        </select>
        <button
          onClick={handleSubmit}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
          type="button"
        >
          Saqlash
        </button>
      </div>
    </div>
  );
};

export default WorkExperienceDrawer;
