// store/dataSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { fetchBanner, fetchJobs, fetchQuickJobs, fetchRecentQuickJobs } from "./openApiThunk"; // Strukturangizga mos ravishda yo'lani to'g'rilang

const initialState = {
  jobs: [],
  quickJobs: [],
  company: [],
  banner: [],
  recentJob: [],
  currentPage: 1,
  totalPages: 1,
  allResult:1,
  status: "idle",
  error: null,
  message: "",
  totalCount: 0,
};

const openApiSlice = createSlice({
  name: "openApi",
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    resetSearchJob(state) {
      state.jobs = [];
    },
    resetSearchQuickJobs(state) {
      state.quickJobs = [];
    },
  },
  extraReducers: (builder) => {
    builder

      // fetchJobs reduktorni shu erga qo'shing
      .addCase(fetchJobs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.totalCount = action.payload.totalCount;
        state.jobs = action.payload.jobs; // API javobiga mos kelishi
        state.totalPages = action.payload.totalPages; // API javobiga mos kelishi
        state.currentPage = action.payload.currentPage; // API javobiga mos kelishi
      })
      .addCase(fetchJobs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(fetchRecentQuickJobs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRecentQuickJobs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.totalCount = action.payload.totalCount;
        state.recentJob = action.payload.recentJob; // API javobiga mos kelishi
        state.totalPages = action.payload.totalPages; // API javobiga mos kelishi
        state.currentPage = action.payload.currentPage; // API javobiga mos kelishi
      })
      .addCase(fetchRecentQuickJobs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(fetchBanner.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBanner.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.banner = action.payload.banner; // API javobiga mos kelishi
      })
      .addCase(fetchBanner.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // fetchQuickJobs reduktorni shu erga qo'shing
      .addCase(fetchQuickJobs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchQuickJobs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.quickJobs = action.payload.quickJobs || []; // API javobiga mos kelishi
        state.totalPages = action.payload.totalPages; // API javobiga mos kelishi
        state.currentPage = action.payload.currentPage; // API javobiga mos kelishi
        state.allResult = action.payload.allResult; // API javobiga mos kelishi
      })
      .addCase(fetchQuickJobs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setCurrentPage, resetSearchJob, resetSearchQuickJobs } =
  openApiSlice.actions;
export default openApiSlice.reducer;
