/* eslint-disable react/style-prop-object */
// components/JobsList.js
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobs } from "../../../store/openApi/openApiThunk";
import { LastJobsCard } from "../../../components/cards/LastJobsCard";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LastJobs = () => {
  const dispatch = useDispatch();
  const { jobs, currentPage, status } = useSelector((state) => state.openApi);

  useEffect(() => {
    dispatch(fetchJobs({ page: currentPage, pageSize: 10 }));
  }, [dispatch, currentPage]);
  const { t } = useTranslation();

  return (
    <div className="overflow-hidden mx-auto hero-banner mt-10">
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-black text-lg font-bold">{t("Standard_Jobs")}</h1>
        <Link className="text-blue-600 text-base font-bold" to={"/alljobs"}>
          {t("All")}
        </Link>
      </div>
      <div>
        <LastJobsCard jobs={jobs} status={status} />
      </div>
    </div>
  );
};

export default LastJobs;
